import React from 'react'
import styled from 'styled-components'
import { RowBetween } from '../Row'
import { TYPE } from '../../theme'

export const StyledPageHeader = styled.div`
  padding: 8px 1rem 10px 1rem;
  margin-bottom: 0px;
  width: 100%;
  height: 50px;
  font-size:  24px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  color: ${({ theme }) => theme.text2};
`
//max-width: 480px;


interface PageHeaderProps {
  header: string
  children?: React.ReactNode
}

export default function PageHeader({header, children}:PageHeaderProps) {
  return (
    <StyledPageHeader>
      <RowBetween>
        <TYPE.Primary fontWeight={500}>{header}</TYPE.Primary>
        {children}
      </RowBetween>
    </StyledPageHeader>
  )
}
