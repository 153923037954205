import { FACTORY_ADDRESS, ROUTER_ADDRESS, STAKE_FACTORY_ADDRESS, Token } from '@feswap/sdk'
import { Contract } from '@ethersproject/contracts'
import { abi as GOVERNANCE_ABI } from '@feswap/governance/build/FeswGovernor.json'
import { abi as SPONSOR_ABI } from '@feswap/governance/build/FeswSponsor.json'
import { abi as STAKE_REWARD_FACTORY_ABI } from '@feswap/governance/build/StakingTwinRewardsFactory.json'
import { abi as FESW_ABI } from '@feswap/governance/build/Fesw.json'
import { abi as NFT_BID_ABI } from '@feswap/governance/build/IFeswaNFT.json'
import { abi as NFT_FACTORY_ABI } from '@feswap/core/build/FeSwapFactory.json'
import { abi as NFT_ROUTER_ABI } from '@feswap/core/build/FeSwapRouter.json'
import { abi as STAKING_REWARDS_ABI } from '@feswap/governance/build/StakingTwinRewards.json'
import { abi as MERKLE_DISTRIBUTOR_ABI } from '@feswap/governance/build/MerkleDistributor.json'
import { ChainId, WETH, NFT_BID_ADDRESS, GOVERNANCE_ADDRESS, SPONSOR_ADDRESS } from '@feswap/sdk'
import { abi as IFeSwapPair } from '@feswap/core/build/IFeSwapPair.json'
import { useMemo } from 'react'
import {  MERKLE_DISTRIBUTOR_ADDRESS, FESW } from '../constants'
import {
  ARGENT_WALLET_DETECTOR_ABI,
  ARGENT_WALLET_DETECTOR_MAINNET_ADDRESS
} from '../constants/abis/argent-wallet-detector'
import ENS_PUBLIC_RESOLVER_ABI from '../constants/abis/ens-public-resolver.json'
import ENS_ABI from '../constants/abis/ens-registrar.json'
import { ERC20_BYTES32_ABI } from '../constants/abis/erc20'
import ERC20_ABI from '../constants/abis/erc20.json'
import UNISOCKS_ABI from '../constants/abis/unisocks.json'
import WETH_ABI from '../constants/abis/weth.json'
import ArkreenToken_ABI from '../constants/abis/ArkreenToken.json'
import ArkreenRECIssuance_ABI from '../constants/abis/ArkreenRECIssuance.json'
import ArkreenRECToken_ABI from '../constants/abis/ArkreenRECToken.json'
import ArkreenRetirement_ABI from '../constants/abis/ArkreenRetirement.json'
import ArkreenMiner_ABI from '../constants/abis/ArkreenMiner.json'
import ActionBuilder_ABI from '../constants/abis/ArkreenBuilder.json'
import HashKeyESG_ABI from '../constants/abis/HashKeyESGBTC.json'
import HART_BANK_ABI from '../constants/abis/ArkreenRECBank.json'


import { MULTICALL_ABI, MULTICALL_NETWORKS } from '../constants/multicall'
import { getContract } from '../utils'
import { useActiveWeb3React } from './index'
import { useSimulationMode } from '../state/user/hooks'

// returns null on errors
function useContract(address: string | undefined, ABI: any, withSignerIfPossible = true): Contract | null {
  const { library, account } = useActiveWeb3React()

  return useMemo(() => {
    if (!address || !ABI || !library) return null
    try {
      return getContract(address, ABI, library, withSignerIfPossible && account ? account : undefined)
    } catch (error) {
      console.error('Failed to get contract', error)
      return null
    }
  }, [address, ABI, library, withSignerIfPossible, account])
}

export function useTokenContract(tokenAddress?: string, withSignerIfPossible?: boolean): Contract | null {
  return useContract(tokenAddress, ERC20_ABI, withSignerIfPossible)
}

export function useWETHContract(withSignerIfPossible?: boolean): Contract | null {
  const { chainId } = useActiveWeb3React()
  return useContract(chainId ? WETH[chainId].address : undefined, WETH_ABI, withSignerIfPossible)
}

export function useArgentWalletDetectorContract(): Contract | null {
  const { chainId } = useActiveWeb3React()
  return useContract(
    chainId === ChainId.MAINNET ? ARGENT_WALLET_DETECTOR_MAINNET_ADDRESS : undefined,
    ARGENT_WALLET_DETECTOR_ABI,
    false
  )
}

export function useENSRegistrarContract(withSignerIfPossible?: boolean): Contract | null {
  const { chainId } = useActiveWeb3React()
  let address: string | undefined
  if (chainId) {
    switch (chainId) {
      case ChainId.MAINNET:
      case ChainId.GÖRLI:
      case ChainId.ROPSTEN:
      case ChainId.RINKEBY:
        address = '0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e'
        break
    }
  }
  return useContract(address, ENS_ABI, withSignerIfPossible)
}

export function useENSResolverContract(address: string | undefined, withSignerIfPossible?: boolean): Contract | null {
  return useContract(address, ENS_PUBLIC_RESOLVER_ABI, withSignerIfPossible)
}

export function useBytes32TokenContract(tokenAddress?: string, withSignerIfPossible?: boolean): Contract | null {
  return useContract(tokenAddress, ERC20_BYTES32_ABI, withSignerIfPossible)
}

export function usePairContract(pairAddress?: string, withSignerIfPossible?: boolean): Contract | null {
  return useContract(pairAddress, IFeSwapPair, withSignerIfPossible)
}

export function useMulticallContract(): Contract | null {
  const { chainId } = useActiveWeb3React()
  return useContract(chainId && MULTICALL_NETWORKS[chainId], MULTICALL_ABI, true)
}

export function useMerkleDistributorContract(): Contract | null {
  const { chainId } = useActiveWeb3React()
  return useContract(chainId ? MERKLE_DISTRIBUTOR_ADDRESS[chainId] : undefined, MERKLE_DISTRIBUTOR_ABI, true)
}

export function useGovernanceContract(): Contract | null {
  const { chainId } = useActiveWeb3React()
  return useContract(GOVERNANCE_ADDRESS[chainId??ChainId.MAINNET], GOVERNANCE_ABI, true)
}

export function useFeswContract(): Contract | null {
  const { chainId } = useActiveWeb3React()
  return useContract(chainId ? FESW[chainId].address : undefined, FESW_ABI, true)
}

export function useFeswFactoryContract(): Contract | null {
  const { chainId } = useActiveWeb3React()
  return useContract(chainId ? FACTORY_ADDRESS[chainId] : undefined, NFT_FACTORY_ABI, true)
}

export function useFeswRouterContract(): Contract | null {
  const { chainId } = useActiveWeb3React()
  return useContract(chainId ? ROUTER_ADDRESS[chainId] : undefined, NFT_ROUTER_ABI, true)
}

export function useSponsorContract(): Contract | null {
  const { chainId } = useActiveWeb3React()
  return useContract(SPONSOR_ADDRESS[chainId??ChainId.MAINNET], SPONSOR_ABI, true)
}

export function useStakingFactoryContract(): Contract | null {
  const { chainId } = useActiveWeb3React()
  return useContract(STAKE_FACTORY_ADDRESS[chainId??ChainId.MAINNET], STAKE_REWARD_FACTORY_ABI, true)
}

export function useNftBidContract(): Contract | null {
  const { chainId } = useActiveWeb3React()
  return useContract(NFT_BID_ADDRESS[chainId??ChainId.MAINNET], NFT_BID_ABI, true)
}

export function useStakingContract(stakingAddress?: string, withSignerIfPossible?: boolean): Contract | null {
  return useContract(stakingAddress, STAKING_REWARDS_ABI, withSignerIfPossible)
}

export function feswType(chainId?: ChainId): string {
  if( chainId === ChainId.MAINNET ||
      chainId === ChainId.ROPSTEN ||
      chainId === ChainId.RINKEBY ||
      chainId === ChainId.GÖRLI ||
      chainId === ChainId.KOVAN ) return 'FESW'
  return 'FESW-V2'
}

export function useSocksController(): Contract | null {
  const { chainId } = useActiveWeb3React()
  return useContract(
    chainId === ChainId.MAINNET ? '0x65770b5283117639760beA3F867b69b3697a91dd' : undefined,
    UNISOCKS_ABI,
    false
  )
}

export const defaultIssuerAddress     = "0x576Ab950B8B3B18b7B53F7edd8A47986a44AE6F4"
export const arkreenTokenAddress      = "0x54E1c534F59343C56549C76D1bDccC8717129832"
export const arkreenIssuanceAddress   = "0x95f56340889642A41b913C32d160d2863536E073"
export const arkreenRetirementAddress = "0x5C653b445BE2bdEB6f8f3CD099FC801865Cab835"
export const ARECTokenAddress         = "0xb0c9dD915f62d0A37792FD2ce497680E909D8c0F"
export const minerContractAddress     = "0xB8663EdC9929D9135E7f6D50f7d3A97862554a72"
export const arkreenRegistryAddress   = "0x047Eb5205251c5fC8A21BA8F8D46f57Df62013c8"
export const actionBuilderAddress     = "0xA05A9677a9216401CF6800d28005b227F7A3cFae"
export const HashKeyARECTokenAddress  = "0x0999afb673944a7b8e1ef8eb0a7c6ffdc0b43e31"
// export const HashKeyESGAddress     = "0xde8e59dab27eb97b2267d4230f8fe713a637e03c"      // HashKey ESG Version 0.1 
export const HashKeyESGAddress        = "0x785dCa2Ca9a51513da1fef9F70E6B6ab02896F67"      // 2023/03/15 HashKey ESG Version 0.2
export const HARTBankAddress          = "0x7ee6D2A14d6Db71339a010d44793B27895B36d50"

export const defaultIssuerAddressTest     = "0x0AF6Fad1e63De91d5C53Af1dD2e55BB1b278b131"
export const arkreenTokenAddressTest      = "0x6c28fF02d3A132FE52D022db1f25a33d91caeCA2"
export const arkreenIssuanceAddressTest   = "0x7370c2166d7720c41f0931f0bbf67e10d00b0d18"
export const arkreenRetirementAddressTest = "0xe07968e3b0d64b99ea3653dd925a850ebb9a3bb9"
export const ARECTokenAddressTest         = "0xd1348bb43dbf51a2446db6e40de5f6c178cb2d47"
export const minerContractAddressTest     = "0xC4f795514586275c799729aF5AE7113Bdb7ccc86"
export const arkreenRegistryAddressTest   = "0x61a914363ef99aabca69504cee5ccfd5523c845d"
export const actionBuilderAddressTest     = "0xA05A9677a9216401CF6800d28005b227F7A3cFae"
export const HashKeyARECTokenAddressTest  = "0x58Ac4e54a70b98960Ed5ecF9B9A2cd1AE83879Db"
// export const HashKeyESGAddressTest     = "0xde8e59dab27eb97b2267d4230f8fe713a637e03c"      // HashKey ESG Version 0.1 
export const HashKeyESGAddressTest        = "0x785dCa2Ca9a51513da1fef9F70E6B6ab02896F67"      // 2023/03/15 HashKey ESG Version 0.2
export const HARTBankAddressTest          = "0x7ee6D2A14d6Db71339a010d44793B27895B36d50"

export const defaultIssuerAddressMatic      = "0xec9254677d252df0dCaEb067dFC8b4ea5F6edAfC"
export const arkreenTokenAddressMatic       = "0x960C67B8526E6328b30Ed2c2fAeA0355BEB62A83"
export const arkreenIssuanceAddressMatic    = "0x45D0c0E2480212A60F1a9f2A820F1d7d6472CA6B"
export const arkreenRetirementAddressMatic  = "0x3d5531cF0bC2e8d0658fEc0D1a9995211Ac1f337"
export const ARECTokenAddressMatic          = "0x815bFE3aaCF765c9E0A4DdEb98Ad710a4Fb860d3"
export const minerContractAddressMatic      = "0xAc4da3681e51278f82288617c7185a7a119E5b7B"
export const arkreenRegistryAddressMatic    = "0x3E8A27dA0BF241f588141659cBb6Bd39717527F1"
export const actionBuilderAddressMatic      = "0x7073Ea8C9B0612F3C3FE604425E2af7954c4c92e"
export const HashKeyARECTokenAddressMatic   = "0x93b3bb6C51A247a27253c33F0d0C2FF1d4343214"
export const HashKeyESGAddressMatic         = "0xfe9341218c7Fcb6DA1eC131a72f914B7C724F200"
export const HARTBankAddressMatic           = "0xab65900A52f1DcB722CaB2e5342bB6b128630A28"

export const defaultIssuerAddressMaticRelease       = "0xec9254677d252df0dCaEb067dFC8b4ea5F6edAfC"
export const arkreenTokenAddressMaticRelease        = "0x21B101f5d61A66037634f7e1BeB5a733d9987D57"
export const arkreenIssuanceAddressMaticRelease     = "0x954585adF9425F66a0a2FD8e10682EB7c4F1f1fD"
export const arkreenRetirementAddressMaticRelease   = "0x1e5132495cdaBac628aB9F5c306722e33f69aa24"
export const ARECTokenAddressMaticRelease           = "0x58E4D14ccddD1E993e6368A8c5EAa290C95caFDF"
export const minerContractAddressMaticRelease       = "0xAc4da3681e51278f82288617c7185a7a119E5b7B"
export const arkreenRegistryAddressMaticRelease     = "0xb17faCaCA106fB3D216923DB6CaBFC7C0517029d"
export const actionBuilderAddressMaticRelease       = "0x7073Ea8C9B0612F3C3FE604425E2af7954c4c92e"
export const HashKeyARECTokenAddressMaticRelease    = "0x93b3bb6C51A247a27253c33F0d0C2FF1d4343214"
export const HashKeyESGAddressMaticRelease          = "0xfe9341218c7Fcb6DA1eC131a72f914B7C724F200" 
export const HARTBankAddressMaticRelease            = "0xab65900A52f1DcB722CaB2e5342bB6b128630A28"

export function useRECIssuerAddress(): string {
  const [simulationMode] = useSimulationMode()
  const { chainId } = useActiveWeb3React()  

  return useMemo(()=>{  
    return (chainId === ChainId.MATIC_TESTNET)
              ? (simulationMode ? defaultIssuerAddress : defaultIssuerAddressTest)
              : (simulationMode ? defaultIssuerAddressMatic : defaultIssuerAddressMaticRelease) // defaultIssuerAddressMaticRelease
  },[simulationMode, chainId])
}

export function useArkreenTokenAddress(): string {
  const [simulationMode] = useSimulationMode()
  const { chainId } = useActiveWeb3React()  

  return useMemo(()=>{  
    return (chainId === ChainId.MATIC_TESTNET) 
            ? (simulationMode ? arkreenTokenAddress : arkreenTokenAddressTest)
            : (simulationMode ? arkreenTokenAddressMatic : arkreenTokenAddressMaticRelease) // arkreenTokenAddressMaticRelease

  },[simulationMode, chainId])
}

export function useArkreenIssuanceAddress(): string {
  const [simulationMode] = useSimulationMode()
  const { chainId } = useActiveWeb3React()  

  return useMemo(()=>{  
    return (chainId === ChainId.MATIC_TESTNET) 
            ? (simulationMode ? arkreenIssuanceAddress : arkreenIssuanceAddressTest)
            : (simulationMode ? arkreenIssuanceAddressMatic : arkreenIssuanceAddressMaticRelease)  // arkreenIssuanceAddressMaticRelease
  },[simulationMode, chainId])
}


export function useHashKeyARECTokenAddress(): string {
  const [simulationMode] = useSimulationMode()
  const { chainId } = useActiveWeb3React()  

  return useMemo(()=>{  
    return (chainId === ChainId.MATIC_TESTNET) 
            ? (simulationMode ? HashKeyARECTokenAddress : HashKeyARECTokenAddressTest)
            : (simulationMode ? HashKeyARECTokenAddressMatic : HashKeyARECTokenAddressMaticRelease)  // HashKeyARECTokenAddressMaticRelease
  },[simulationMode, chainId])
}

export function useActionBuilderAddress(): string {
  const [simulationMode] = useSimulationMode()
  const { chainId } = useActiveWeb3React()  

  return useMemo(()=>{  
    return (chainId === ChainId.MATIC_TESTNET) 
            ? (simulationMode ? actionBuilderAddress : actionBuilderAddressTest)
            : (simulationMode ? actionBuilderAddressMatic : actionBuilderAddressMaticRelease)  // actionBuilderAddressMaticRelease
  },[simulationMode, chainId])
}

export function useHashKeyESGAddress(): string {
  const [simulationMode] = useSimulationMode()
  const { chainId } = useActiveWeb3React()  

  return useMemo(()=>{  
    return (chainId === ChainId.MATIC_TESTNET) 
            ? (simulationMode ? HashKeyESGAddress : HashKeyESGAddressTest)
            : (simulationMode ? HashKeyESGAddressMatic : HashKeyESGAddressMaticRelease)  // HashKeyESGAddressMaticRelease
  },[simulationMode, chainId])
}

export function useHARTBankAddress(): string {
  const [simulationMode] = useSimulationMode()
  const { chainId } = useActiveWeb3React()  

  return useMemo(()=>{  
    return (chainId === ChainId.MATIC_TESTNET) 
            ? (simulationMode ? HARTBankAddress : HARTBankAddressTest)
            : (simulationMode ? HARTBankAddressMatic : HARTBankAddressMaticRelease)  // HARTBankAddressMaticRelease
  },[simulationMode, chainId])
}

export function useArkreenRetirementAddress(): string {
  const [simulationMode] = useSimulationMode()
  const { chainId } = useActiveWeb3React()  

  return useMemo(()=>{  
    return (chainId === ChainId.MATIC_TESTNET) 
            ? (simulationMode ? arkreenRetirementAddress : arkreenRetirementAddressTest)
            : (simulationMode ? arkreenRetirementAddressMatic : arkreenRetirementAddressMaticRelease) // arkreenRetirementAddressMaticRelease
  },[simulationMode, chainId])
}

export function useARECTokenAddress(): string {
  const [simulationMode] = useSimulationMode()
  const { chainId } = useActiveWeb3React()  

  return useMemo(()=>{  
    return (chainId === ChainId.MATIC_TESTNET) 
            ? (simulationMode ? ARECTokenAddress : ARECTokenAddressTest)
            : (simulationMode ? ARECTokenAddressMatic : ARECTokenAddressMaticRelease)     // ARECTokenAddressMaticRelease
  },[simulationMode, chainId])
}

export function useMinerContractAddress(): string | undefined {
  const [simulationMode] = useSimulationMode()
  const { chainId } = useActiveWeb3React()  

  return useMemo(()=>{  
    return (chainId === ChainId.MATIC_TESTNET) 
            ? (simulationMode ? minerContractAddress : minerContractAddressTest)
            : (simulationMode ? minerContractAddressMatic : minerContractAddressMaticRelease)   // minerContractAddressMaticRelease

  },[simulationMode, chainId])
}

export function useArkreenRegistryAddress(): string {
  const [simulationMode] = useSimulationMode()
  const { chainId } = useActiveWeb3React()  

  return useMemo(()=>{  
    return (chainId === ChainId.MATIC_TESTNET) 
            ? (simulationMode ? arkreenRegistryAddress : arkreenRegistryAddressTest)
            : (simulationMode ? arkreenRegistryAddressMatic : arkreenRegistryAddressMaticRelease)   // arkreenRegistryAddressMaticRelease

  },[simulationMode, chainId])
}

export function useAKREToken() {
  const { chainId } = useActiveWeb3React()  
  const arkreenTokenAddress = useArkreenTokenAddress()
  return new Token( chainId ?? ChainId.MATIC_TESTNET , arkreenTokenAddress, 18, 'AKRE', 'Arkreen DAO Token')
}

export function useArkreenTokenContract(withSignerIfPossible?: boolean): Contract | null {
  const arkreenTokenAddress = useArkreenTokenAddress()
  return useContract(arkreenTokenAddress, ArkreenToken_ABI, withSignerIfPossible)
}

export function usePermitTokenContract(tokenAddress: string, withSignerIfPossible?: boolean): Contract | null {
  return useContract(tokenAddress, ArkreenToken_ABI, withSignerIfPossible)
}

export function useArkreenRECTokenContract(withSignerIfPossible?: boolean): Contract | null {
  const ARECTokenAddress = useARECTokenAddress()
  return useContract(ARECTokenAddress, ArkreenRECToken_ABI, withSignerIfPossible)
}

export function useHashKeyARECContract(withSignerIfPossible?: boolean): Contract | null {
  const HashKeyARECAddress = useHashKeyARECTokenAddress()
  return useContract(HashKeyARECAddress, ArkreenRECToken_ABI, withSignerIfPossible)
}

export function useRECIssuanceContract(withSignerIfPossible?: boolean): Contract | null {
  const arkreenIssuanceAddress = useArkreenIssuanceAddress()
  return useContract(arkreenIssuanceAddress, ArkreenRECIssuance_ABI, withSignerIfPossible)
}

export function useArkreenRetirementContract(withSignerIfPossible?: boolean): Contract | null {
  const arkreenRetirementAddress = useArkreenRetirementAddress()
  return useContract(arkreenRetirementAddress, ArkreenRetirement_ABI, withSignerIfPossible)
}

export function useArkreenMinerContract(withSignerIfPossible?: boolean): Contract | null {
  const minerContractAddress = useMinerContractAddress()
  return useContract(minerContractAddress, ArkreenMiner_ABI, withSignerIfPossible)
}

export function useActionBuilderContract(withSignerIfPossible?: boolean): Contract | null {
  const actionBuilderAddress = useActionBuilderAddress()
  return useContract(actionBuilderAddress, ActionBuilder_ABI, withSignerIfPossible)
}

export function useHashKeyESGContract(withSignerIfPossible?: boolean): Contract | null {
  const hashKeyESGAddress = useHashKeyESGAddress()
  return useContract(hashKeyESGAddress, HashKeyESG_ABI, withSignerIfPossible)
}

export function useHARTBankContract(withSignerIfPossible?: boolean): Contract | null {
  const HARTBankAddress = useHARTBankAddress()
  return useContract(HARTBankAddress, HART_BANK_ABI, withSignerIfPossible)
}


