import { createAction } from '@reduxjs/toolkit'
import { BigNumber } from 'ethers'
import { GBTCTrxInfo } from '../../pages/GreenBTC/GBTCInfo'

export interface SerializedToken {
  chainId: number
  address: string
  decimals: number
  symbol?: string
  name?: string
}

export interface SerializedPair {
  token0: SerializedToken
  token1: SerializedToken
}

export interface SerializedNFTPair extends SerializedPair{
  bidStatus:  boolean
}

export interface GreenBTCTokenInfo {
  owner:          string
  level:          number
  brickIDs:       string
  brickIDExt:     string[] | undefined
  hashTrx:        string | undefined
  timeTrx:        string | undefined
  blockHeight:    string | undefined
}

export const updateMatchesDarkMode = createAction<{ matchesDarkMode: boolean }>('user/updateMatchesDarkMode')
export const updateUserDarkMode = createAction<{ userDarkMode: boolean }>('user/updateUserDarkMode')
export const updateUserExpertMode = createAction<{ userExpertMode: boolean }>('user/updateUserExpertMode')
export const updateUserSingleHopOnly = createAction<{ userSingleHopOnly: boolean }>('user/updateUserSingleHopOnly')
export const updateUserSlippageTolerance = createAction<{ userSlippageTolerance: number }>(
  'user/updateUserSlippageTolerance'
)
export const updateUserDeadline = createAction<{ userDeadline: number }>('user/updateUserDeadline')
export const addSerializedToken = createAction<{ serializedToken: SerializedToken }>('user/addSerializedToken')
export const removeSerializedToken = createAction<{ chainId: number; address: string }>('user/removeSerializedToken')
export const addSerializedPair = createAction<{ serializedPair: SerializedPair }>('user/addSerializedPair')
export const removeSerializedPair = createAction<{ chainId: number, tokenAAddress: string, tokenBAddress: string }>(
  'user/removeSerializedPair'
)
export const addSerializedNFTPair = createAction<{ serializedNFTPair: SerializedNFTPair }>('user/addSerializedNFTPair')
export const removeSerializedNFTPair = createAction<{ chainId: number, tokenAAddress: string, tokenBAddress: string }>(
  'user/removeSerializedNFTPair'
)
export const toggleURLWarning = createAction<void>('app/toggleURLWarning')
export const setARECConfirmCounter = createAction<{ConfirmCounter: number}>('user/setARECConfirmCounter')
export const updateSimulationMode = createAction<{simulationMode: boolean}>('user/updateSimulationMode')
export const setGreenBTCTokenInfo = createAction<{chainId: number, ownerList: string[], 
                                                    brickIDsList: BigNumber[], AllGBTCInfo: GBTCTrxInfo[] }>('user/setGreenBTCTokenInfo')
export const clearGreenBTCTokenInfo = createAction<{chainId: number}>('user/clearGreenBTCTokenInfo')
export const setGreenBTCTokenInfoMVP = createAction<{chainId: number, start: number, brickIDsMVP: (BigNumber[] | undefined)[]}>('user/setGreenBTCTokenInfoMVP')


