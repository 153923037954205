import React, { useContext, useState } from 'react'
import { Fraction, JSBI } from '@feswap/sdk'
import styled, { ThemeContext } from 'styled-components'
import Modal from '../Modal'
import { darken } from 'polished'
import { RowBetween, RowFixed } from '../Row'
import { TYPE, CloseIcon } from '../../theme'
import { Text } from 'rebass'
import { ArrowRight, MinusCircle, PlusCircle, AlertCircle } from 'react-feather'
import { AutoColumn, ColumnCenter } from '../Column'
import { Link as RouterLink  } from 'react-router-dom'

import { InputPanel, Container } from '../CurrencyInputPanel'
import QuestionHelper from '../QuestionHelper'
import { SeparatorBlack } from '../SearchModal/styleds'
import AddressInputPanel, { MessageInputPanel } from '../AddressInputPanel'
import { ResizingTextArea } from '../TextInput'
import { ZERO_ADDRESS } from '../../constants'
import { ButtonEmpty } from '../Button'
import { DateTime } from 'luxon'
import { RECData, REC_STATUS } from '../../state/issuance/hooks'
//import { useCurrency } from '../../hooks/Tokens'
//import { tryParseAmount } from '../../state/swap/hooks'
import { shortenAddress, shortenCID } from '../../utils'
//import { arkreenTokenAddress } from '../../hooks/useContract'
import { useMedia } from 'react-use'
import { BigNumber } from 'ethers'

export const ARECDate = styled.input.attrs({
  type: "date"
})`
  border: solid 1px;
  border-color: ${({theme}) => theme.primaryText1};
  border-radius: 6px;
  font-size: 20px;
  padding: 0.2rem 0.8rem 0.2rem 0.8rem; 
  margin: 0.5rem 0.4rem 0.5rem 0.4rem;
  color: ${({ theme }) => theme.text1};
  background-color: ${({ theme }) => theme.bg1};
  &:disabled {
    border-color: ${({theme}) => theme.bg4};
  }  
  :hover:not([disabled]) {
      border: solid 2px;
      border-color: ${({theme}) => theme.primaryText1};
    }
  }
`


//margin: 0.5rem 0.4rem 0.5rem 0.4rem;


interface DateInputInterface {
  onChangeDate?: (date: string) => void,
  min?: string,
  max?: string,
  value?: string, 
  disabled: boolean
}

function DateInput({ onChangeDate, min, max, value, disabled=false}: DateInputInterface) {
  return (
    <ARECDate disabled={disabled} min={min} max={max} value={value}
      onChange={(event) => onChangeDate && onChangeDate(event.target.value)} />
  )
}

export const M = styled.b<{size?: string}>`
  color: ${({ theme }) => theme.primary};
  font-size: ${({ size }) => size ?? 'default'};
`
 
const LabelRow = styled.div`
  ${({ theme }) => theme.flexRowNoWrap}
  align-items: center;
  color: ${({ theme }) => theme.text1};
  font-size: 0.75rem;
  line-height: 1rem;
  padding: 0.75rem 1rem 0 1rem;
  span:hover {
    cursor: pointer;
    color: ${({ theme }) => darken(0.2, theme.text2)};
  }
`

const DateRow = styled.div`
  display: grid;
  max-width: 480px;
  width: 100%;  
  grid-template-columns: 1fr 20px 1fr;
  column-gap: 6px;
  align-items: start;
  justify-content: space-between;
`

export const StyledPageHeader = styled.div`
    padding: 8px 1rem 10px 1rem;
    margin-bottom: 0px;
    width: 100%;
    height: 50px;
    font-size:  24px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
    color: ${({ theme }) => theme.text2};
`

export const TitleRow = styled.div`
  display: grid;
  grid-template-columns: 4fr 4fr 1fr;
  column-gap: 0rem;
  align-items: start-first;
  justify-content: space-between;
`

export const HeadRouter = styled.div`
    position: relative;
    max-width: 480px;
    width: 100%;
    height:  40px;
    align-content: center;
    padding 5px 12px;
    margin-bottom: 20px;
    font-size:  24px;
    background-color: transparent;
    user-select: none;
  `

export const BodyWrapper = styled.div`
    position: relative;
    max-width: 480px;
    width: 100%;
    background-color: transparent;
    box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.01), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04),
    0px 24px 32px rgba(0, 0, 0, 0.01);
    border-radius: 16px;
    padding: 0rem;
  `

export const HeadRow = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 1.5rem;
    height: 40px;
    align-items: stretch;
    justify-content: space-between;
  `

export const HeadLink = styled(RouterLink)<{ activaText?: boolean }>`
  border-radius: 20px;
  text-decoration: none;
  align-items: center;
  box-shadow: inset 0px 0px 1px 2px ${({ theme }) => theme.primary};
  background-color: ${({ theme, activaText }) => activaText ? theme.primary : 'transparent'};
  &:hover {
    cursor: pointer;
    text-decoration: none;
    underline: none;
    color: ${({ theme }) => theme.text4};
    background-color: ${({ theme }) => theme.primary1};
    box-shadow: 2px 2px 3px 1px rgba(0,192,239,0.6);
  }
`


export const TitleOption = styled.div<{active?: boolean}>`
  font-weight: ${({ active }) => active ? 600 : 400};
  display: flex;
  justify-content: center;
  color: ${({ theme, active }) => active ? 'white' : theme.primaryText1};
  :hover {
    color: white;
    cursor: pointer;
    text-decoration: none;
  }
  > svg {
    margin-right: 8px;
  }
`

/*
<select multiple size={5} onChange = {onARECSelect} style={{ fontSize:'1.0rem', fontWeight:500, width:'100%', 
borderColor: offsetSelected ? theme.text1 :theme.primary1,
borderWidth: offsetSelected ? "1px" : "2px",
borderRadius: '0px 0px 0px 0px',
appearance: 'none',
padding: '0.2rem 0.6rem 0rem 0.6rem', 
fontFamily: 'Tahoma'}} >
*/

export const ARECSelectMulti = styled.select<{itemSelected: boolean}>`
font-size:      1.0rem;
fontWeight:     600;
border-radius:  0px 0px 0px 0px;
width:          100%; 
color:            ${({ theme }) => theme.text1};
background-color: ${({ theme }) => theme.bg1};  
border-color:     ${({itemSelected, theme}) => (itemSelected? theme.text1 :theme.primary1) };
border-width:     ${({itemSelected}) => (itemSelected? "1px" : "2px") };
appearance:       'none',
padding:          '0.2rem 0.6rem 0rem 0.6rem';
fontFamily:       'Tahoma';
`

export const ARECSelect = styled.select<{itemSelected: boolean}>`
  font-size: 1.0rem; 
  font-weight: 600; 
  border-radius: 0px 0px 0px 0px;
  width: 100%;
  color: ${({ theme }) => theme.text1};
  background-color: ${({ theme }) => theme.bg1};  
  border-color:  ${({itemSelected, theme}) => (itemSelected? theme.text1 :theme.primary1) };
  border-Width: ${({itemSelected}) => (itemSelected? "1px" : "2px") };
  padding: 0.4rem 0.6rem 0.4rem 0.6rem;
  font-family: 'Tahoma';
`
//font-family: 'Lucida Console';
//font-family: 'Inter var', sans-serif;
//Consolas

export const ARECOption = styled.option`
  :active {
    color: black;
    background-color: ${({theme}) => theme.primary2};
  }

`

export const ButtonRow = styled.div`
display: grid;
width: 100%;
height: 20px
grid-template-columns: 20px 1fr 20px;
column-gap: 6px;
align-items: start;
justify-content: space-between;
`  

export function OverviewHeadButton({path}:{path:string}) {
//  const theme = useContext(ThemeContext)
  return (  
    <HeadRouter> 
      <HeadRow>
        <HeadLink to="/Overview" draggable='false' activaText={path==='Overview'}>
          <TitleOption style={{paddingTop:'4px'}} active={path==='Overview'}>
            Overview
          </TitleOption>
        </HeadLink>          
        <HeadLink to="/Profile" draggable='false' activaText={path==='Profile'}>
          <TitleOption style={{paddingTop:'4px'}} active={path==='Profile'}>
            My Profile
          </TitleOption>
        </HeadLink>
      </HeadRow>
    </HeadRouter> 
  )
}  


export function OffsetSolidyButton({path}:{path:string}) {
  return (  
    <HeadRouter> 
      <HeadRow>
        <HeadLink to="/Offset" draggable='false' activaText={(path==='Offset')}>
          <TitleOption style={{paddingTop:'4px'}} active={(path==='Offset')}>
            Offset
          </TitleOption>
        </HeadLink>          
        <HeadLink to="/Solidify" draggable='false' activaText={(path==='Solidify')}>
          <TitleOption style={{paddingTop:'4px'}} active={(path==='Solidify')}>
            Solidify
          </TitleOption>
        </HeadLink>
      </HeadRow>
    </HeadRouter> 
  )
}  

export function RedeemHeadButton({path}:{path:string}) {
  return (  
    <HeadRouter> 
      <HeadRow>
        <HeadLink to="/Redeem" draggable='false' activaText={path==='Redeem'}>
          <TitleOption style={{paddingTop:'4px'}} active={path==='Redeem'}>
            Redeem
          </TitleOption>
        </HeadLink>          
        <HeadLink to="/Liquidize" draggable='false' activaText={path==='Liquidize'}>
          <TitleOption style={{paddingTop:'4px'}} active={path==='Liquidize'}>
            Liquidize
          </TitleOption>
        </HeadLink>
      </HeadRow>
    </HeadRouter> 
  )
}  

export function ExchangeHeadButton({path}:{path:string}) {
  return (  
    <HeadRouter> 
      <HeadRow>
        <HeadLink to="/Swap" draggable='false' activaText={path==='Swap'}>
          <TitleOption style={{paddingTop:'4px'}} active={path==='Swap'}>
            Swap
          </TitleOption>
        </HeadLink>          
        <HeadLink to="/Add" draggable='false' activaText={path==='Add'}>
          <TitleOption style={{paddingTop:'4px'}} active={path==='Add'}>
            Add Liquidity
          </TitleOption>
        </HeadLink>
      </HeadRow>
    </HeadRouter> 
  )
}  

interface ARECIssuanceDateProps {
  startDate: string
  endDate: string
  minDate?: string
  maxDate?: string
  onChangeDate: (date: string) => void
  id: string
  children:  React.ReactNode
  active:  boolean
}

interface GetCertInfoProps {
  certOwner:        string
  beneficiary:      string
  nameBeneficiary:  string
  memoCertificate:  string
  setCertOwner:       (date: string) => void
  setBeneficiary:     (date: string) => void
  setNameBeneficiary: (date: string) => void
  setMemoCertificate: (date: string) => void
}

function ButtonUpOrDown( { bShow, setbShow}: { bShow: boolean, setbShow: (bShow: boolean) => void} ) {
  return (
    <RowFixed >
      <ButtonEmpty style={{padding:'4px'}} onClick={() => {setbShow(!bShow)}} >
        {bShow  ? ( <MinusCircle size="16px" style={{ margin: '0px 8px 0px 20px'}} /> ) 
                : ( <PlusCircle size="16px" style={{ margin: '0px 8px 0px 20px'}} />
        )}
      </ButtonEmpty>
    </RowFixed>
  )
}

export function GetCertificateInfo({
  certOwner,
  beneficiary,
  nameBeneficiary,
  memoCertificate,
  setCertOwner,
  setBeneficiary,
  setNameBeneficiary,
  setMemoCertificate
  } : GetCertInfoProps) {
  const theme = useContext(ThemeContext)

  const [showOwnerName , setShowOwnerName] = useState(false)
  const [showbeneficiary, setShowbeneficiary] = useState(false)
  const [showNameBeneficiary, setShowNameBeneficiary] = useState(false)
  const [showMemoCertificate, setShowMemoCertificate] = useState(true)

  return (
    <Container>
      <RowBetween align="center" height='20px' style={{padding: "1rem 1rem 0.75rem 1rem"}}>
        <RowFixed>
          <TYPE.Body color={theme.text2} fontWeight={500} fontSize={16}>
            <strong>Retirement Badge Info:</strong>
          </TYPE.Body>
          <QuestionHelper bkgOff={true} small={'m'} info={<>This is the additional information items
                that will be included in the minted retirement badge.</>} />
        </RowFixed>
        <div />
      </RowBetween >
      <SeparatorBlack/>
      <AutoColumn gap="4px" style={{padding: "0.5rem"}}>
        <RowBetween>
          <RowFixed style={{padding: "0.3rem 0.5rem 0rem"}}>
            <TYPE.Body color={theme.text2} fontWeight={500} fontSize={14}>
              Badge Owner Name (Optional):
            </TYPE.Body>
            <QuestionHelper bkgOff={true} small={'s'} info={<>This is the name of the owner of the retirement
              badge that will be minted.</>} />
          </RowFixed>
          < ButtonUpOrDown bShow ={showOwnerName} setbShow ={setShowOwnerName} />
        </RowBetween>  
        { showOwnerName && (
          <MessageInputPanel value={certOwner} onUserInput={setCertOwner} placeholder={`Badge Owner Name`} />
        )}

        <RowBetween>
          <RowFixed style={{padding: "0.3rem 0.5rem 0rem"}}>
            <TYPE.Body color={theme.text2} fontWeight={500} fontSize={14}>
              Beneficiary Account Address: (Optional):
            </TYPE.Body>
            <QuestionHelper bkgOff={true} small={'s'} info={<>This is the account address of the retirement
              beneficiary.</>} />
          </RowFixed>
          < ButtonUpOrDown bShow ={showbeneficiary} setbShow ={setShowbeneficiary} />
        </RowBetween>
        { showbeneficiary && (
          <AddressInputPanel id="recipient" simple={true} 
                  value={(beneficiary !==ZERO_ADDRESS) ? beneficiary: ''} onChange={setBeneficiary} 
                  placeholder={'Beneficiary Account Address'} />
        )}      

        <RowBetween>
          <RowFixed style={{padding: "0.3rem 0.5rem 0rem"}}>
            <TYPE.Body color={theme.text2} fontWeight={500} fontSize={14}>
            Beneficiary Name (Optional):
            </TYPE.Body>
            <QuestionHelper bkgOff={true} small={'s'} info={<>This is the name of the 
                  retirement beneficiary. </>} />
          </RowFixed>
          < ButtonUpOrDown bShow ={showNameBeneficiary} setbShow ={setShowNameBeneficiary} />
        </RowBetween>
        { showNameBeneficiary && (
          <MessageInputPanel value={nameBeneficiary} 
                onUserInput={setNameBeneficiary} placeholder={`Retirement Beneficiary Name`} />
        )}    

        <RowBetween>
          <RowFixed style={{padding: "0.3rem 0.5rem 0rem"}}>
            <TYPE.Body color={theme.text2} fontWeight={500} fontSize={14}>
              Retirement Memo: (Optional):
            </TYPE.Body>
            <QuestionHelper bkgOff={true} small={'s'} info={<>This is the optional message that could be recorded in the
                  retirement badge. </>} />
          </RowFixed>
          < ButtonUpOrDown bShow ={showMemoCertificate} setbShow ={setShowMemoCertificate} />
        </RowBetween>
        { showMemoCertificate && (
          <ResizingTextArea value={memoCertificate} onUserInput={setMemoCertificate} 
                placeholder={`Retirement Badge Memo`} borderRadius={'6px'} small={true} fontSize="1rem" />
        )}

      </AutoColumn>
    </Container>
  )
}

export default function ARECIssuanceDate({
  startDate,
  endDate,
  minDate,
  maxDate,
  onChangeDate,
  id,
  children,
  active,
}: ARECIssuanceDateProps) {

  const below540 = useMedia('(max-width: 540px)')

  const theme = useContext(ThemeContext)
   return (
    <InputPanel id={id}>
      <Container>
        { below540 ? (
            <>
              <RowBetween style={{alignItems: 'center', padding: '0.2rem 0.2rem 0rem 0.6rem'}}>
                  <TYPE.Body color={theme.text2} fontWeight={500} fontSize={16} width={"45%"}>
                    <strong>Start AREC Date:</strong>
                  </TYPE.Body>
                  <DateInput value ={startDate} disabled={true} />
              </RowBetween>
              <RowBetween style={{alignItems: 'center', padding: '0rem 0.2rem 0.2rem 0.6rem'}}>
                  <TYPE.Body color={theme.text2} fontWeight={500} fontSize={16} width={"45%"}>
                    <strong>End AREC Date:</strong>
                  </TYPE.Body>
                  <DateInput value ={endDate} onChangeDate={onChangeDate} max={maxDate} 
                                    min={minDate} disabled={!active} />
              </RowBetween>
            </>              
          )
          : (
            <>
              <LabelRow>
                <RowBetween style={{alignItems: 'center'}}>
                  <TYPE.Body color={theme.text2} fontWeight={500} fontSize={16} width={"45%"}>
                    <strong>Start AREC Date:</strong>
                  </TYPE.Body>
                  <ArrowRight size={16} width={"10%"} />
                  <TYPE.Body color={theme.text2} fontWeight={500} fontSize={16} width={"45%"} textAlign={'right'}>
                    <strong>End REC Date:</strong>
                  </TYPE.Body>
                </RowBetween>
            </LabelRow>

            <DateRow style={{padding: '0.2rem 0.2rem 0.2rem 0.2rem', height:'60px'}}>
              <DateInput value ={startDate} disabled={true} />
              <div/>
              <DateInput value ={endDate} onChangeDate={onChangeDate} max={maxDate} 
                                  min={minDate} disabled={!active} />
            </DateRow>
          </>
        )}

        <div style={{padding: '0.2rem 0.6rem 0.2rem 0.6rem', marginBottom: '4px'}}>
          {children}
        </div>
      </Container>

    </InputPanel>
  )
}

export const ARECContainer = styled.div`
  border-radius: 6px;
  border: 1px solid ${({ theme }) => theme.bg4};
  padding: 0.3rem 0.6rem 0.3rem 0.6rem;
  background: transparent;
`

export function getPowerString(power: BigNumber): string {
  if( power.lt(BigNumber.from(1000_000_000)) ) {
    return (new Fraction(power.toString(), JSBI.BigInt(1000_000))).toFixed(3, { groupSeparator: ',' }).concat(' KWH')
  } else {
    return (new Fraction(power.toString(), JSBI.BigInt(1000_000_000))).toFixed(3, { groupSeparator: ',' }).concat(' MWH')
  }
}  

export function DetailedARECInfo({recData}:{recData: RECData}) {
  const theme = useContext(ThemeContext)
//  const arkreenToken = useCurrency(arkreenTokenAddress)

  const startDate = DateTime.fromSeconds(recData.startTime) ?? ''
  const endDate = DateTime.fromSeconds(recData.endTime) ?? ''

//  const powerAmount = arkreenToken ? tryParseAmount(recData.amountREC.toString(), arkreenToken) : undefined
//  const powerAmount = new Fraction(recData.amountREC.toString(), JSBI.BigInt(1000000))
//  const powerAmountString = (powerAmount?.toFixed(3, { groupSeparator: ',' }) ?? '0').concat(' KWH')

  const powerAmountString =  getPowerString(recData.amountREC)
  
  const recStatus = (recData?.status === REC_STATUS.Pending) ? 'Pending':
                    (recData?.status === REC_STATUS.Certified) ? 'Certified' :
                    (recData?.status === REC_STATUS.Cancelled) ? 'Cancelled' :
                    (recData?.status === REC_STATUS.Rejected) ? 'Rejected' : ' '        

  return ( <ARECContainer>
            <RowBetween align="center" height='24px'> 
              <RowFixed>
                <Text fontWeight={500} fontSize={14} color={theme.text2}> AREC Status: </Text>
                <QuestionHelper bkgOff={true} small={'s'} info={<>This is the selected AREC Status.
                    Only certified AREC can be redeemed or liquidized.</>} />
              </RowFixed>
              <Text lineHeight={"24px"} fontWeight={700} fontSize={14} 
                  color={(recData?.status === REC_STATUS.Certified) ? theme.text1: theme.primaryText1}> 
                {recStatus}
              </Text>
            </RowBetween>   

            <RowBetween align="center" height='24px'> 
              <RowFixed>
                <Text fontWeight={500} fontSize={14} color={theme.text2}> AREC Issuer: </Text>
                <QuestionHelper bkgOff={true} small={'s'} info={<>This is the name of the entity issuing AREC NFT.</>} />
              </RowFixed>
              <Text lineHeight={"24px"} fontWeight={700} fontSize={14} color={theme.text1}> 
                {shortenAddress(recData.issuer,6)}
              </Text>
            </RowBetween>   

            {(recData.status === REC_STATUS.Certified) && ( 
              <RowBetween align="center" height='24px'> 
                <RowFixed>
                  <Text fontWeight={500} fontSize={14} color={theme.text2}> AREC Serial No: </Text>
                  <QuestionHelper bkgOff={true} small={'s'} info={<>This is the unique serial number
                                        of the AREC certified by the issuer.</>} />
                </RowFixed>
                <Text lineHeight={"24px"} fontWeight={700} fontSize={14} color={theme.text1}> 
                  {recData.serialNumber}
                </Text>
              </RowBetween>
            )}

            <RowBetween align="center" height='24px'> 
              <RowFixed>
                <Text fontWeight={500} fontSize={14} color={theme.text2}> Earliest AREC Date: </Text>
                <QuestionHelper bkgOff={true} small={'s'} info={<>This is the earliest date when 
                                      the renewable energy of the selected AREC is generated.</>} />
              </RowFixed>
              <Text lineHeight={"24px"} fontWeight={700} fontSize={14} color={theme.text1}> 
                {startDate?.toFormat("yyyy-LLL-dd")}
              </Text>
            </RowBetween>

            <RowBetween align="center" height="24px">
              <RowFixed>
                <Text fontWeight={500} fontSize={14} color={theme.text2}> Latest AREC Date: </Text>
                <QuestionHelper bkgOff={true} small={'s'} info={<>This is the last date when
                                    the renewable energy of the selected AREC is generated.</>} />
              </RowFixed>
              <Text lineHeight={"24px"} fontWeight={700} fontSize={14} color={theme.text1}> 
                {endDate?.toFormat("yyyy-LLL-dd")}
              </Text>
            </RowBetween>

            <RowBetween align="center" height="24px">
              <RowFixed>
                <Text fontWeight={500} fontSize={14} color={theme.text2}> Total RE Amount: </Text>
                <QuestionHelper bkgOff={true} small={'s'} info={<>This is the amount of the total renewable energy  
                              recorded in the selected AREC NFT.</>} />
              </RowFixed>
              <Text lineHeight={"24px"} fontWeight={700} fontSize={14} color={theme.text1}> {powerAmountString} </Text>
            </RowBetween>

            <RowBetween align="center" height="24px">
              <RowFixed>
                <Text fontWeight={500} fontSize={14} color={theme.text2}> AREC Region:  </Text>
                <QuestionHelper bkgOff={true} small={'s'} info={<>This is the region of the selected AREC.</>} />
              </RowFixed>
              <Text lineHeight={"24px"} fontWeight={700} fontSize={14} color={theme.text1}> {recData.region} </Text>
            </RowBetween>

            <RowBetween align="center" height="24px">
              <RowFixed>
                <Text fontWeight={500} fontSize={14} color={theme.text2}> AREC cID: </Text>
                <QuestionHelper bkgOff={true} small={'s'} info={<>This is the cID of the renewable energy data 
                              in ipfs, with which the selected AREC RE amount can be verified.</>} />
              </RowFixed>
              <Text lineHeight={"24px"} fontWeight={700} fontSize={14} color={theme.text1}> {shortenCID(recData.cID)} </Text>
            </RowBetween>
          </ARECContainer>
    )
  }

  const Wrapper = styled.div`
    width: 100%;
  `
  const Section = styled(AutoColumn)`
    padding: 24px;
  `

  const ConfirmedIcon = styled(ColumnCenter)`
    padding: 60px 0;
  `  

export const ErrorPromptModal = ({
  isOpen,
  errString,
  onDismiss,
}: {
  isOpen: boolean
  errString: string | undefined
  onDismiss: () => void
}) => {
  const theme = useContext(ThemeContext)

  return (
    <Modal isOpen={isOpen} onDismiss={onDismiss} maxHeight={120}>
      <Wrapper>
        <Section>
          <RowBetween>
            <Text fontWeight={500} fontSize={20}> Transaction Failed </Text>
            <CloseIcon onClick={onDismiss} />
          </RowBetween>
          <ConfirmedIcon>
            <AlertCircle strokeWidth={1.0} size={'90px'} fontWeight={'900'} color={theme.red1} />
          </ConfirmedIcon>
          <AutoColumn gap="12px" justify={'center'}>
            <Text fontWeight={500} fontSize={20}>
              Transaction Failure Message:
            </Text>
            <AutoColumn gap="12px" justify={'center'}>
              <Text fontWeight={600} fontSize={14} color="" textAlign="center">
                {errString}
              </Text>
            </AutoColumn>

            <Text fontSize={12} color="#565A69" textAlign="center">
              Please check your operation, and connect with <b>Arkreen</b>
            </Text>
          </AutoColumn>
        </Section>
      </Wrapper>
    </Modal>
  )
}



