import { ApolloClient, InMemoryCache } from '@apollo/client'
import gql from 'graphql-tag'
import { ChainId } from '@feswap/sdk'

export const greenBTCClient = new ApolloClient({
  uri: 'https://api.thegraph.com/subgraphs/name/ldru/green-btc',
  cache: new InMemoryCache()
//  shouldBatch: true
})

export const greenBTCClientClub = new ApolloClient({
  uri: 'https://api.thegraph.com/subgraphs/name/ldru/green-btc-club',
  cache: new InMemoryCache()
//  shouldBatch: true
})

export const GET_GREEN_BTC_NFT_INFO = gql`
  query greenBTCInfo($id: String!) {
    greenBTCNFTInfo(id: $id ) {
      id
      owner
      timeTrx
      hashTrx
      blockHeight
    }
  }
`

export interface gbtcInfo{
  "greenBTCNFTInfo":  {
                        id:           string,
                        owner:        string,
                        hashTrx:      string,
                        timeTrx:      string,
                        blockHeight:  string
                      }
}

export interface GBTCTrxInfo{ nftId:        string | undefined,
                              owner:        string | undefined,
                              hashTrx:      string | undefined,
                              timeTrx:      string | undefined,
                              blockHeight:      string | undefined
                            }

export async function fetchGBTCTrxInfo( chainId:ChainId, nftId: number) : Promise<GBTCTrxInfo> {
  try {
    let greenClient = greenBTCClient
    if (chainId=== ChainId.MATIC) greenClient = greenBTCClientClub

    const { data, errors, loading } = await greenClient.query<gbtcInfo>({
      query: GET_GREEN_BTC_NFT_INFO,
      variables: {
        id: nftId.toString(),
      },
      fetchPolicy: 'no-cache',
    })

    if (loading || errors) {
        console.log("Fetch Green BTC info error: ", errors, nftId)
        return {nftId:undefined, owner: undefined, hashTrx: undefined, timeTrx: undefined, blockHeight: undefined}
    }

    return {nftId: data.greenBTCNFTInfo.id, owner: data.greenBTCNFTInfo.owner, 
            hashTrx: data.greenBTCNFTInfo.hashTrx, timeTrx: data.greenBTCNFTInfo.timeTrx, 
            blockHeight: data.greenBTCNFTInfo.blockHeight}

  } catch (errors) {
    console.log("Fetch Green BTC info error: ", errors, nftId)
    return {nftId:undefined, owner: undefined, hashTrx: undefined, timeTrx: undefined, blockHeight: undefined}
  }
}
