import React, { RefObject, useMemo }from 'react'
import styled from 'styled-components'
import { darken, lighten } from 'polished'
//import { MouseoverTooltip } from '../../components/Tooltip'

import { RowBetween } from '../Row'
import { ChevronDown } from 'react-feather'
import { Button as RebassButton, ButtonProps } from 'rebass/styled-components'

//import LogoShrimp from '../../assets/svg/shrimp.png'
//import LogoCrab from '../../assets/svg/crab.png'
//import LogoOctopus from '../../assets/svg/octopus.png'
//import LogoFish from '../../assets/svg/fish.png'
//import LogoDolphin from '../../assets/svg/dolphin.png'
//import LogoWhale from '../../assets/svg/whale.png'
//import LogoShark from '../../assets/svg/shark.png'
//import LogoHumpback from '../../assets/svg/humpback.png'

import LogoShrimp from '../../assets/NFT/shrimp.png'
import LogoCrab from '../../assets/NFT/crab.png'
import LogoOctopus from '../../assets/NFT/octopus.png'
import LogoFish from '../../assets/NFT/fish.png'
import LogoDolphin from '../../assets/NFT/dolphin.png'
import LogoWhale from '../../assets/NFT/whale.png'
import LogoShark from '../../assets/NFT/shark.png'
import LogoHumpback from '../../assets/NFT/humpback.png'

const Base = styled(RebassButton)<{
  padding?: string
  width?: string
  borderRadius?: string
  altDisabledStyle?: boolean
}>`
  padding: ${({ padding }) => (padding ? padding : '18px')};
  width: ${({ width }) => (width ? width : '100%')};
  font-weight: 500;
  text-align: center;
  border-radius: 8px;
  border-radius: ${({ borderRadius }) => borderRadius ? 'borderRadius': undefined};
  outline: none;
  border: 1px solid transparent;
  color: white;
  text-decoration: none;
  display: flex;
  justify-content: center;
  flex-wrap: nowrap;
  align-items: center;
  cursor: pointer;
  position: relative;
  z-index: 1;
  &:disabled {
    cursor: auto;
  }

  > * {
    user-select: none;
  }
`

export const ButtonPrimary = styled(Base)`
  background-color: ${({ theme }) => theme.primary1};
  color: white;
  &:focus {
    box-shadow: 0 0 0 1pt ${({ theme }) => darken(0.05, theme.primary1)};
    background-color: ${({ theme }) => darken(0.05, theme.primary1)};
  }
  &:hover {
    background-color: ${({ theme }) => darken(0.05, theme.primary1)};
  }
  &:active {
    box-shadow: 0 0 0 1pt ${({ theme }) => darken(0.1, theme.primary1)};
    background-color: ${({ theme }) => darken(0.1, theme.primary1)};
  }
  &:disabled {
    background-color: ${({ theme, altDisabledStyle }) => (altDisabledStyle ? theme.primary1 : theme.bg3)};
    color: ${({ theme, altDisabledStyle }) => (altDisabledStyle ? 'white' : 'white')};
    cursor: auto;
    box-shadow: none;
    border: 1px solid transparent;
    outline: none;
    opacity: ${({ altDisabledStyle }) => (altDisabledStyle ? '0.7' : '1')};
  }
`

export const BlockLayout = styled.div<{mobile: boolean, scale:number}> `
    display: grid;
    grid-template-columns:   ${({ mobile }) => mobile ? 'repeat(80, 16px)': 'repeat(80, 16px)'};
    column-gap:  ${({ mobile }) => mobile ? '2px': '2px'};
    row-gap:  ${({ mobile }) => mobile ? '2px': '2px'};
    margin: 0px 1px 1px 0px;
    transform:  ${({scale}) => "scale(" + scale.toFixed(2)+ ")"};
    transform-origin: top left;
    touch-action: manipulation;
  `

//  transform: "scale(" + ${scale} +")";
//  transform: rotate(90deg) scale(2,2);
//  #dddde2 #00a848
// opacity: ${ ({noshow}) => noshow ? 0 : 100}

//width: ${({ mobile }) => mobile ? '16px': '16px'};
//height: ${({ mobile }) => mobile ? '16px': '16px'};
//border_color: ${({ theme }) => theme.primary1};
//border-width: ${({ border }) => border ? '1px': '0px'};

export const Cell = styled.div<{noshow: boolean, selected: boolean, grey:boolean, sold: boolean, linked: boolean, mobile: boolean}>`
  visibility: ${ ({noshow}) => noshow ? 'hidden' : 'visible'}
  width: ${({ mobile }) => mobile ? '16px': '16px'};
  height: ${({ mobile }) => mobile ? '16px': '16px'};
  border-radius: 0px;
  border: 0px;
  font-weight: 500;
  text-align: center;
  background-color: ${({ theme, selected, sold, linked, grey }) => linked ? darken(0.1,theme.red1) 
                                  : sold? theme.text4 : (grey ? theme.text3 : selected ? theme.primary2 : theme.primary5) };
  color: white;
  &:focus,
  &:hover {
    cursor: pointer;
    background-color: ${({ theme, sold, selected }) => sold ? darken(0.1,theme.red1): (selected? theme.primary1 :theme.purple2) };
  }
`

export const InfoLayout = styled.div<{mobile: boolean}> `
    display: grid;
    grid-template-columns: ${({ theme, mobile }) => mobile ? '1fr' : '1fr 1fr'};
    column-gap:  ${({ mobile }) => mobile ? '20px': '100px'};
    row-gap:  ${({ mobile }) => mobile ? '20px': '100px'};

    ${({ theme }) => theme.mediaWidth.upToMedium`
      column-gap:  20px;
    `};
  `

//  ${({ mobile }) => mobile ? 'repeat(6, 60px);': 'repeat(7, 60px);'};
export const SelectedCell = styled.div<{mobile: boolean, tight?:boolean}>`
  display: grid;
  width: 100%;
  justify-content: center;
  grid-template-columns:    ${({ mobile, tight }) => mobile ? 'repeat(6, 56px);': tight? 'repeat(7, 56px);': 'repeat(7, 60px);'};
  column-gap:  ${({ mobile }) => mobile ? '2px': '2px'};

  
  ${({ theme }) => theme.mediaWidth.upToLarge`
    grid-template-columns:  repeat(6, 56px);
  `};
`

export const TitleLayout = styled.div<{mobile: boolean}>`
    display: grid;
    grid-template-columns: 2fr 1fr;
    column-gap:  ${({ mobile }) => mobile ? '2px': '6px'};
    row-gap:  ${({ mobile }) => mobile ? '2px': '6px'};
  `

export const PayLayout = styled.div<{mobile: boolean}>`
    display: grid;
    grid-template-columns: ${({ mobile }) => mobile ? '4fr 3fr': '4fr 3fr'};
    column-gap:  ${({ mobile }) => mobile ? '2px': '6px'};
    row-gap:  ${({ mobile }) => mobile ? '2px': '6px'};
  `

interface ButtonCellProps {
  id: number
  info: RefObject<HTMLElement | undefined>
  noshow: boolean
  mobile: boolean
  active:  boolean
  grey:  boolean
  sold:  boolean  
  linked: boolean
  numCell: number
  removeOwnerToLinkById: () => void
  setOwnerToLinkById: (owner: number) => void
  toggleCell: (id: number) => void
  children:  React.ReactNode
}


export function LogoCell({numCell, height}: {numCell: number, height: string}) {
  const logo = useMemo(()=>{
    if((numCell>=1) && ( numCell <=3)) return LogoShrimp
    if((numCell>=4) && ( numCell <=6)) return LogoCrab
    if((numCell>=7) && ( numCell <=9)) return LogoOctopus
    if((numCell>=10) && ( numCell <=12)) return LogoFish
    if((numCell>=13) && ( numCell <=15)) return LogoDolphin
    if((numCell>=16) && ( numCell <=18)) return LogoShark
    if((numCell>=19) && ( numCell <=21)) return LogoWhale
    if(numCell>=22)  return LogoHumpback
    return undefined
  },[numCell])

  return <img style={{height: height, zIndex: 999}} src={logo} alt="logo" />
}

//onMouseOver={() => {setID(id)} } 
export function ButtonCell({ id, info, noshow, mobile, active, grey, sold, linked, numCell, setOwnerToLinkById, removeOwnerToLinkById, toggleCell, children}: ButtonCellProps ) {
  const idString = (id+1).toString()
  const updateInfo = () => { 
    if (info.current) {
      info.current.innerHTML = idString
    }
    if(sold) {
      setOwnerToLinkById(id)
    }
  }

//  {!!children ? children : (numCell!==0) ? <LogoCell numCell={numCell} /> : undefined}
  return (
      <Cell draggable='false' noshow={noshow} selected={active} mobile={mobile} sold={sold} linked={linked} grey={grey}
            onMouseEnter={() => {updateInfo()} }
            onMouseLeave={() => {removeOwnerToLinkById()} }
            onClick={()=>{toggleCell(id)} } >
        {!!children ? children : undefined}
      </Cell>
  )
}

export const ButtonLight = styled(Base)`
  background-color: ${({ theme }) => theme.bg3};
  color: ${({ theme }) => theme.primaryText1};
  font-size: 20px;
  font-weight: 500;
  &:focus {
    box-shadow: 0 0 0 1pt ${({ theme, disabled }) => !disabled && darken(0.03, theme.bg3)};
    background-color: ${({ theme, disabled }) => !disabled && darken(0.03, theme.bg3)};
  }
  &:hover {
    background-color: ${({ theme, disabled }) => !disabled && darken(0.03, theme.bg3)};
  }
  &:active {
    box-shadow: 0 0 0 1pt ${({ theme, disabled }) => !disabled && darken(0.05, theme.bg3)};
    background-color: ${({ theme, disabled }) => !disabled && darken(0.05, theme.bg3)};
  }
  :disabled {
    opacity: 0.4;
    :hover {
      cursor: auto;
      background-color: ${({ theme }) => theme.bg3};
      box-shadow: none;
      border: 1px solid transparent;
      outline: none;
    }
  }
`

export const ButtonGray = styled(Base)`
  background-color: ${({ theme }) => theme.bg3};
  color: ${({ theme }) => theme.text2};
  font-size: 16px;
  font-weight: 500;
  &:focus {
    box-shadow: 0 0 0 1pt ${({ theme, disabled }) => !disabled && darken(0.05, theme.bg2)};
    background-color: ${({ theme, disabled }) => !disabled && darken(0.05, theme.bg2)};
  }
  &:hover {
    background-color: ${({ theme, disabled }) => !disabled && darken(0.05, theme.bg2)};
  }
  &:active {
    box-shadow: 0 0 0 1pt ${({ theme, disabled }) => !disabled && darken(0.1, theme.bg2)};
    background-color: ${({ theme, disabled }) => !disabled && darken(0.1, theme.bg2)};
  }
`

export const ButtonSecondary = styled(Base)`
  border: 1px solid ${({ theme }) => theme.primary4};
  color: ${({ theme }) => theme.primary1};
  background-color: transparent;
  font-size: 16px;
  border-radius: 8px;
  padding: ${({ padding }) => (padding ? padding : '10px')};

  &:focus {
    box-shadow: 0 0 0 1pt ${({ theme }) => theme.primary4};
    border: 1px solid ${({ theme }) => theme.primary3};
  }
  &:hover {
    border: 1px solid ${({ theme }) => theme.primary3};
  }
  &:active {
    box-shadow: 0 0 0 1pt ${({ theme }) => theme.primary4};
    border: 1px solid ${({ theme }) => theme.primary3};
  }
  &:disabled {
    opacity: 50%;
    cursor: auto;
  }
  a:hover {
    text-decoration: none;
  }
`

export const ButtonSecondaryPosition = styled(ButtonSecondary)`
border: 1px solid ${({ theme }) => theme.bg3};
`

export const ButtonPink = styled(Base)`
  background-color: ${({ theme }) => theme.primary1};
  color: white;

  &:focus {
    box-shadow: 0 0 0 1pt ${({ theme }) => darken(0.05, theme.primary1)};
    background-color: ${({ theme }) => darken(0.05, theme.primary1)};
  }
  &:hover {
    background-color: ${({ theme }) => darken(0.05, theme.primary1)};
  }
  &:active {
    box-shadow: 0 0 0 1pt ${({ theme }) => darken(0.1, theme.primary1)};
    background-color: ${({ theme }) => darken(0.1, theme.primary1)};
  }
  &:disabled {
    background-color: ${({ theme }) => theme.primary1};
    opacity: 50%;
    cursor: auto;
  }
`

export const ButtonUNIGradient = styled(ButtonPrimary)`
  color: white;
  padding: 2px 8px;
  height: 32px;
  font-weight: 500;
  background-color: ${({ theme }) => theme.bg3};
  background: radial-gradient(174.47% 188.91% at 1.84% 0%, #ff007a 0%, #2172e5 100%), #edeef2;
  width: fit-content;
  position: relative;
  cursor: pointer;
  border: none;
  white-space: no-wrap;
  :hover {
    opacity: 0.8;
  }
  :active {
    opacity: 0.9;
  }
`

export const ButtonOutlined = styled(Base)`
  border: 1px solid ${({ theme }) => theme.bg5};
  background-color: transparent;
  color: ${({ theme }) => theme.text1};

  &:focus {
    box-shadow: 0 0 0 1px ${({ theme }) => theme.bg4};
  }
  &:hover {
    box-shadow: 0 0 0 1px ${({ theme }) => theme.bg4};
  }
  &:active {
    box-shadow: 0 0 0 1px ${({ theme }) => theme.bg4};
  }
  &:disabled {
    opacity: 50%;
    cursor: auto;
  }
`

export const ButtonEmpty = styled(Base)`
  background-color: transparent;
  color: ${({ theme }) => theme.primary1};
  display: flex;
  justify-content: center;
  align-items: center;

  &:focus {
    text-decoration: underline;
  }
  &:hover {
    text-decoration: underline;
  }
  &:active {
    text-decoration: underline;
  }
  &:disabled {
    opacity: 50%;
    cursor: auto;
  }
`

export const ButtonWhite = styled(Base)`
  border: 1px solid #edeef2;
  background-color: ${({ theme }) => theme.bg1};
  color: black;

  &:focus {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    box-shadow: 0 0 0 1pt ${darken(0.05, '#edeef2')};
  }
  &:hover {
    box-shadow: 0 0 0 1pt ${darken(0.1, '#edeef2')};
  }
  &:active {
    box-shadow: 0 0 0 1pt ${darken(0.1, '#edeef2')};
  }
  &:disabled {
    opacity: 50%;
    cursor: auto;
  }
`

const ButtonConfirmedStyle = styled(Base)`
  background-color: ${({ theme }) => lighten(0.5, theme.green1)};
  color: ${({ theme }) => theme.green1};
  border: 1px solid ${({ theme }) => theme.green1};

  &:disabled {
    opacity: 50%;
    cursor: auto;
  }
`

const ButtonErrorStyle = styled(Base)`
  background-color: ${({ theme }) => theme.red1};
  border: 1px solid ${({ theme }) => theme.red1};

  &:focus {
    background-color: ${({ theme }) => darken(0.05, theme.red1)};
    color: ${({ theme }) => theme.white};
  }
  &:hover {
    background-color: ${({ theme }) => darken(0.05, theme.red1)};
  }
  &:active {
    background-color: ${({ theme }) => darken(0.05, theme.red1)};
  }
  &:disabled {
    opacity: 50%;
    cursor: auto;
    box-shadow: none;
    background-color: ${({ theme }) => theme.red1};
    border: 1px solid ${({ theme }) => theme.red1};
  }
`

export const RateButton = styled.button<{ width: string }>`
  padding: 2px 8px;
  background-color: ${({ theme }) => theme.bg4};
  border: 1px solid ${({ theme }) => theme.bg4};
  border-radius: 0.5rem;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  overflow: hidden;
  :hover {
    border: 1px solid ${({ theme }) => theme.primary1};
  }
  :focus {
    border: 1px solid ${({ theme }) => theme.primary1};
    outline: none;
  }
`

export function ButtonConfirmed({
  confirmed,
  altDisabledStyle,
  ...rest
}: { confirmed?: boolean; altDisabledStyle?: boolean } & ButtonProps) {
  if (confirmed) {
    return <ButtonConfirmedStyle {...rest} />
  } else {
    return <ButtonPrimary {...rest} altDisabledStyle={altDisabledStyle} />
  }
}

export function ButtonError({ error, ...rest }: { error?: boolean } & ButtonProps) {
  if (error) {
    return <ButtonErrorStyle {...rest} />
  } else {
    return <ButtonPrimary {...rest} />
  }
}

export function ButtonDropdown({ disabled = false, children, ...rest }: { disabled?: boolean } & ButtonProps) {
  return (
    <ButtonPrimary {...rest} disabled={disabled}>
      <RowBetween>
        <div style={{ display: 'flex', alignItems: 'center' }}>{children}</div>
        <ChevronDown size={24} />
      </RowBetween>
    </ButtonPrimary>
  )
}

export function ButtonDropdownLight({ disabled = false, children, ...rest }: { disabled?: boolean } & ButtonProps) {
  return (
    <ButtonOutlined {...rest} disabled={disabled}>
      <RowBetween>
        <div style={{ display: 'flex', alignItems: 'center' }}>{children}</div>
        <ChevronDown size={24} />
      </RowBetween>
    </ButtonOutlined>
  )
}

export function ButtonRadio({ active, ...rest }: { active?: boolean } & ButtonProps) {
  if (!active) {
    return <ButtonWhite {...rest} />
  } else {
    return <ButtonPrimary {...rest} />
  }
}
