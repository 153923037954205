import React, { useContext, useState, useCallback, useEffect, useMemo } from 'react'
import { Fraction, JSBI, TokenAmount } from '@feswap/sdk'
//import { useHistory } from 'react-router-dom'

//import { darken } from 'polished'
import { Text } from 'rebass'
import styled, { ThemeContext } from 'styled-components'
import { ButtonLight, ButtonPrimary } from '../../components/Button'
import { StyledPageCard } from '../../components/earn/styled'
import { AutoColumn } from '../../components/Column'
import { Wrapper } from '../../components/swap/styleds'
import PageHeader from '../../components/PageHeader'
//import { ChevronsRight } from 'react-feather'
import { SeparatorBlack } from '../../components/SearchModal/styleds'
import { useArkreenBalance } from '../../state/wallet/hooks'
import { useCurrencyBalances } from '../../state/wallet/hooks'
import { useCurrency } from '../../hooks/Tokens'
import { useARECTokenAddress } from '../../hooks/useContract'
import { useActiveWeb3React } from '../../hooks'
import { useGetUserARECList, useGetActionList, OffsetAction, useUserBadgeInfo, BadgeInfo, MinerInfo, MinerNFT
           } from '../../state/issuance/hooks'
import { ARECSelect, DetailedARECInfo, ARECOption, OverviewHeadButton, getPowerString, M } from '../../components/ARecIssuance'
import { RECData, REC_STATUS, useMinerListByOwner, MINER_TYPE, useGetMinerNFTList, useBuyMinerURL } from '../../state/issuance/hooks'
import { DateTime } from 'luxon'
//import { BigNumber } from 'ethers'
import { RowBetween, RowFixed } from '../../components/Row'
// import { TitleLink } from '../../components/Link'
import { shortenAddress, shortenContent } from '../../utils'
import { ZERO_ADDRESS } from '../../constants'
import { useWalletModalToggle } from '../../state/application/hooks'

import Loader from '../../components/Loader'
import { Container } from '../../components/CurrencyInputPanel'
import { TYPE } from '../../theme'

import { useSimulationMode } from '../../state/user/hooks'
import { OrderLink } from '../Issuance'
import { ShoppingCart } from 'react-feather'
import { useMedia } from 'react-use'

import AppBody from '../AppBody'
import QuestionHelper from '../../components/QuestionHelper'
import { MouseoverTooltip } from '../../components/Tooltip'
import { BigNumber } from 'ethers'
//import { GetCertificateInfo, ARECSelect, ARECOption, ButtonRow, DetailedARECInfo } from '../../components/ARecIssuance'

const AssetRow3 = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 6px;
  align-items: center;
  justify-content: space-between;
`

const AssetRow4 = styled.div`
  display: grid;
  padding: 0.3rem 0.3rem 0rem;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  column-gap: 6px;
  align-items: center;
  justify-content: space-between;
`

const ARECContainer = styled.div`
  border-radius: 6px;
  border: 1px solid ${({ theme }) => theme.bg4};
  padding: 0.3rem 0.6rem 0.3rem 0.6rem;
  background: transparent;
`

export const ButtonInfo = styled(ButtonLight)<{ isSelected: boolean }>`
  background-color: ${({isSelected, theme}) => isSelected ? theme.primary5: 'transparent'};
  color: ${({ theme }) => theme.primaryText1};
  font-size: 20px;
  font-weight: 500;
  padding: 2px;
  border: 1px solid ${({ theme }) => theme.primary3};
  &:focus {
    box-shadow: 0 0 0 1pt ${({ theme }) => theme.primary4};
    border: 1px solid ${({ theme }) => theme.primary3};
    background-color: ${({ theme }) => theme.primary5};
  }
  &:hover {
    box-shadow: 2px 2px 3px 1px rgba(0,192,239,0.6);
    border: 1px solid ${({ theme }) => theme.primary1};
    background-color: ${({ theme }) => theme.primary5};
  }
  &:active {
    border: 1px solid ${({ theme }) => theme.primary3};
  }
`  

export function ProfileButton({onClick, title, value, autofocus}: {
  onClick: ()=>void
  title: string
  value?: string
  autofocus?: boolean
}) {
  const theme = useContext(ThemeContext)

  return (
    <ButtonInfo isSelected={autofocus??false} autoFocus={autofocus} style={{padding: '2px'}} onClick={onClick}>
      <AutoColumn gap="2px" style={{ border: '1px,solid'}}>
        <TYPE.Body style={{ textAlign: 'center'}}> {title} </TYPE.Body>
        <TYPE.Body fontSize={24} fontWeight={500} style={{ color:theme.primaryText1, textAlign:'center' }}>
          {value ?? <Loader/>}
        </TYPE.Body>
      </AutoColumn>
    </ButtonInfo>   
  )
}


function OverviewHelpInfo( ) {
  return (<>
            <Text> This is your asset profile in Arkreen ecosystem. You could click <M> AREC NFTs </M>, 
            <M> Retirements </M>, <M> Badge SBTs </M>, <M> Miners </M> to explore the details of 
            your various assets. </Text>
          </>
        )
  }

export default function Profile() {

  const { account } = useActiveWeb3React()
  //const history = useHistory()
  const theme = useContext(ThemeContext)

  const below540 = useMedia('(max-width: 540px)')

  // toggle wallet when disconnected
  const toggleWalletModal = useWalletModalToggle()
  const [simulationMode] = useSimulationMode()

  const MINER_BUY_URL = useBuyMinerURL()

  const arkreenBalance: TokenAmount | undefined = useArkreenBalance()     
  
  const ARECTokenAddress = useARECTokenAddress()
  const currencyARECT = useCurrency(ARECTokenAddress)
  const balanceARECT = useCurrencyBalances(account ?? undefined, [currencyARECT??undefined])[0]   
//  const badgeCount =  useBadgeCount(account??undefined)

  const { 
    numberOfARECNft, 
    allARECInfo,
    allARECNftTokensID
  } = useGetUserARECList()

  const { 
    allUnclaimedActionsIDs,
    totalUnclaimedAmount,
    allUnclaimedActions,
    totalClaimedAmount
  } = useGetActionList()

  const { 
    badgeCount,
    userARECBadgeIDList,
    userARECBadgeInfoList
    } = useUserBadgeInfo()    

  const {errorMiner, minerList} = useMinerListByOwner(simulationMode, account ?? undefined)

  const miners = useMemo(()=>{
    if(!minerList) return undefined
    return minerList.map( miner => miner.address)
  },[minerList])

  const { minersNFTID, minersNFTInfo } = useGetMinerNFTList(miners)

  const minersInfo = useMemo(()=>{
    if(!miners || !minersNFTID || !minersNFTInfo) return undefined

    const minersInfo :  { [miner: string]: {
      NFTID:      BigNumber | undefined,
      minerInfo:  MinerNFT | undefined
    }} = miners.reduce<{[miner: string]: {NFTID: BigNumber | undefined, minerInfo:  MinerNFT | undefined}}>( 
      (allMinerInfo, miner, index) => {
     
        
        allMinerInfo[miner] = {
          NFTID:      minersNFTID?.[index] ?? undefined,
          minerInfo:  minersNFTInfo?.[index] ?? undefined
        }
      return allMinerInfo
    },{})
    
    return minersInfo
  },[miners, minersNFTID, minersNFTInfo])


//  console.log("minersInfo TTTTTTTTTTTTT", minersInfo)

//  console.log('errorMiner, minerList', errorMiner, minerList, minersNFTID, minersNFTInfo)

  const [arecSelected, setARECSelected] = useState<number|undefined>()

  useEffect(()=>{
    if((arecSelected ===undefined) && (!!allARECInfo) && (allARECInfo.length !==0)){
      setARECSelected(0)
    }
  },[arecSelected, allARECInfo])

  const onARECSelect = useCallback( (arecSelect) => {
    setARECSelected(arecSelect.target.value)
  },[setARECSelected])

  
  const recPowerList = allARECInfo.map((recData: RECData) => {
    return getPowerString(recData.amountREC)
  })

  const recStatusList = allARECInfo.map((recData: RECData) => {
    const recStatus = (recData?.status === REC_STATUS.Pending) ? 'Pending':
                      (recData?.status === REC_STATUS.Certified) ? 'Certified' :
                      (recData?.status === REC_STATUS.Cancelled) ? 'Cancelled' :
                      (recData?.status === REC_STATUS.Rejected) ? 'Rejected' : ' '                                            
    return recStatus
  })  

  const [offsetSelected, setOffsetSelected] = useState<number|undefined>()

  useEffect(()=>{
    if((offsetSelected ===undefined) && (!!allUnclaimedActions) && (allUnclaimedActions.length !==0)){
      setOffsetSelected(0)
    }
  },[offsetSelected, allUnclaimedActions])

  const onOffsetSelect = useCallback( (offsetSelect) => {
    setOffsetSelected(offsetSelect.target.value)
  },[setOffsetSelected])


  const [badgeSelected, setBadgeSelected] = useState<number|undefined>()
  useEffect(()=>{
    if((badgeSelected ===undefined) && (!!userARECBadgeIDList) && (userARECBadgeIDList.length !==0)){
      setBadgeSelected(0)
    }
  },[badgeSelected, userARECBadgeIDList])

  const onBadgeSelect = useCallback( (badgeSelect) => {
    setBadgeSelected(badgeSelect.target.value)
  },[setBadgeSelected])

  const [minerSelected, setMinerSelected] = useState<number|undefined>()
  useEffect(()=>{
    if((minerSelected ===undefined) && (!!minerList) && (minerList.length !==0)){
      setMinerSelected(0)
    }
  },[minerSelected, minerList])

  const onMinerSelect = useCallback( (minerSelect) => {
    setMinerSelected(minerSelect.target.value)
  },[setMinerSelected])

  const retiredPowerUnclaimed = allUnclaimedActions.map((offsetAction: OffsetAction) => {
    const retiredPowerUnclaimed = new Fraction(offsetAction.amount.toString(), JSBI.BigInt(1000000))
    return (retiredPowerUnclaimed?.toFixed(3, { groupSeparator: ',' }) ?? '0').concat(' KWH')
  })

  const userBadgeAmountString = userARECBadgeInfoList?.map((badgeInfo: BadgeInfo) => {
    const offsetTotalAmount = new Fraction(badgeInfo.offsetTotalAmount.toString(), JSBI.BigInt(1000000))
    return (offsetTotalAmount?.toFixed(3, { groupSeparator: ',' }) ?? '0').concat(' KWH')
  })

  const [curButton, setCurButton] = useState<string>("AREC")

  const totalUnclaimedAmountString: string = useMemo(()=>{
    const totalUnclaimedAmountString = new Fraction(totalUnclaimedAmount.toString(), JSBI.BigInt(1000000))
    return totalUnclaimedAmountString.toFixed(3, { groupSeparator: ',' }).concat(' KWH')
  },[totalUnclaimedAmount])

  const totalClaimedAmountString: string = useMemo(()=>{
    const totalUnclaimedAmountString = new Fraction(totalClaimedAmount.toString(), JSBI.BigInt(1000000))
    return totalUnclaimedAmountString.toFixed(3, { groupSeparator: ',' }).concat(' KWH')
  },[totalClaimedAmount])

  const minerTypeString = (type: number) => {
    if (type === MINER_TYPE.GAME_MINER ) return 'Game Miner'
    if (type === MINER_TYPE.REMOTE_MINER ) return 'Remote Miner'
    return 'unknown Miner'
  }

  function DetailedRetirementProfile({retireID, retireData}:
                {retireID: number,  retireData: OffsetAction[]}) {
    const theme = useContext(ThemeContext)
    const totalRetirementAmount = retireData[retireID].amount

    const powerAmount = new Fraction(totalRetirementAmount.toString(), JSBI.BigInt(1000000))
    const powerAmountString = (powerAmount?.toFixed(3, { groupSeparator: ',' }) ?? '0').concat(' KWH')

    const retirementTime = DateTime.fromSeconds(retireData[retireID].createdAt.toNumber())
    const retirementTimeStr = retirementTime.toFormat("yyyy-MM-dd HH:mm:ss")
    const retireARECID = retireData[retireID].tokenId.and("0x3FFFFFFFFFFFFFFF").toNumber()
    const retireARECIDType = retireData[retireID].tokenId.shr(62).toNumber()          // To get the type 

    let retireARECIDTitle
    if(retireARECIDType ===0) {
      retireARECIDTitle = 'Retired AREC NFT ID:'
    } else if(retireARECIDType ===2) {
      retireARECIDTitle = 'Offet AREC NFT ID:'
    } else if(retireARECIDType ===3) {
      retireARECIDTitle = 'Offet Detail Info ID:'
    }

    return ( <ARECContainer>
              <RowBetween align="center" height='24px'>
                <RowFixed>
                  <Text fontWeight={500} fontSize={14} color={theme.text2}> Total Number of Retirements: </Text>
                  <QuestionHelper bkgOff={true} small={'s'} info={<>This is the total number of retirement actions
                    you have successfully committed.</>} />
                </RowFixed>
                <Text lineHeight={"24px"} fontWeight={700} fontSize={14} color={theme.text1}> {allUnclaimedActionsIDs?.length.toString()} </Text>
              </RowBetween>

              <RowBetween align="center" height='24px'>
                <RowFixed>
                  <Text fontWeight={500} fontSize={14} color={theme.text2}> Total Retirement Amount: </Text>
                  <QuestionHelper bkgOff={true} small={'s'} info={<>This is the total RE amount that you have retired and 
                    being available to mint your new badge SFT.</>} />                  
                </RowFixed>
                <Text lineHeight={"24px"} fontWeight={700} fontSize={14} color={theme.text1}> {totalUnclaimedAmountString}</Text>
              </RowBetween>

              <SeparatorBlack style={{margin:'0.2rem 0rem'}} />  

              <RowBetween align="flex-end" height="24px">
                <RowFixed>
                  <Text fontWeight={500} fontSize={14} color={theme.text2}> Retirement RE Amount: </Text>
                  <QuestionHelper bkgOff={true} small={'s'} info={<>This is the retired renewable energy amount 
                                                of the selected retirement action.</>} />
                </RowFixed>
                <Text lineHeight={"24px"} fontWeight={700} fontSize={14} color={theme.primaryText1}> {powerAmountString} </Text>
              </RowBetween>

              <RowBetween align="center" height="24px">
                <RowFixed>
                  <Text fontWeight={500} fontSize={14} color={theme.text2}> Retirement Time: </Text>
                  <QuestionHelper bkgOff={true} small={'s'} info={<>This is the time you committed your 
                    renewable energy retirement.</>} />
                </RowFixed>
                <Text lineHeight={"24px"} fontWeight={700} fontSize={14} color={theme.primaryText1}> {retirementTimeStr} </Text>
              </RowBetween>              

              { (retireARECID !==0) && (
                <RowBetween align="center" height="24px">
                  <RowFixed>
                    <Text fontWeight={500} fontSize={14} color={theme.text2}> {retireARECIDTitle} </Text>
                    <QuestionHelper bkgOff={true} small={'s'} info={<>This ID has one of the following three meanings:<br/>
                      <b>1.</b> The AREC NFT ID you redeemed to neutralize your carbon footprint.<br/> 
                      <b>2.</b> The retired AREC NFT you partially offset by burning some ART tokens.<br/> 
                      <b>3.</b> The detailed offset info ID created while you offset a large amount ART tokens which consumed more than
                                one retired AREC NFT</>} />
                  </RowFixed>                    
                  <Text lineHeight={"24px"} fontWeight={700} fontSize={14} height="100%" color={theme.primaryText1}> {retireARECID.toString().padStart(5,'0')} </Text>
                </RowBetween>                  
              )}

            </ARECContainer>
      )
    }

  function DetailedBadgeProfile({badgeIndex, badgeInfo}:
                {badgeIndex: number,  badgeInfo: BadgeInfo[]}) {
    const theme = useContext(ThemeContext)

    const offsetTotalAmount = new Fraction(badgeInfo[badgeIndex].offsetTotalAmount.toString(), JSBI.BigInt(1000000))
    const userBadgeAmountString = (offsetTotalAmount?.toFixed(3, { groupSeparator: ',' }) ?? '0').concat(' KWH')

    const retirementTime = DateTime.fromSeconds(badgeInfo[badgeIndex].creationTime.toNumber())
    const retirementTimeStr = retirementTime.toFormat("yyyy-MM-dd HH:mm:ss")

    return ( <ARECContainer>
              <RowBetween align="center" height='24px'>
                <RowFixed>
                  <Text fontWeight={500} fontSize={14} color={theme.text2}> Total Number of Badges: </Text>
                  <QuestionHelper bkgOff={true} small={'s'} info={<>This is the total number of retirement badges 
                                  you have successfully minted.</>} />
                </RowFixed>
                <Text lineHeight={"24px"} fontWeight={700} fontSize={14} color={theme.text1}> {badgeCount} </Text>
              </RowBetween>

              <RowBetween align="center" height='24px'>
                <RowFixed>
                  <Text fontWeight={500} fontSize={14} color={theme.text2}> Total RE Amount in badges: </Text>
                  <QuestionHelper bkgOff={true} small={'s'} info={<>This is the total RE amount that you have retired and 
                    minted in your all retirement badge SFTs.</>} />                  
                </RowFixed>
                <Text lineHeight={"24px"} fontWeight={700} fontSize={14} color={theme.text1}> {totalClaimedAmountString}</Text>
              </RowBetween>

              <SeparatorBlack style={{margin:'0.2rem 0rem'}} />  

              <RowBetween align="center" height='24px'>
                <RowFixed>
                  <Text fontWeight={500} fontSize={14} color={theme.text2}> Retirement Entity: </Text>
                  <QuestionHelper bkgOff={true} small={'s'} info={<>This is the address of the entity committed
                               the retirement and minted the badge. </>} />
                </RowFixed>
                  <Text lineHeight={"24px"} fontWeight={700} fontSize={14} color={theme.text1}> 
                  {shortenAddress(badgeInfo[badgeIndex].offsetEntity, 6)} </Text>
              </RowBetween>    

              {badgeInfo[badgeIndex].offsetEntityID && (
                <RowBetween align="center" height='24px'>
                  <RowFixed>
                    <Text fontWeight={500} fontSize={14} color={theme.text2}> Retirement Entity ID: </Text>
                    <QuestionHelper bkgOff={true} small={'s'} info={<>This is the identification of the entity committed
                                the retirement and minted the badge. </>} />
                  </RowFixed>
                  <MouseoverTooltip info={<>{badgeInfo[badgeIndex].offsetEntityID}</>}>
                    <Text lineHeight={"24px"} fontWeight={700} fontSize={14} color={theme.text1}> 
                      {shortenContent(badgeInfo[badgeIndex].offsetEntityID)} 
                    </Text>
                  </MouseoverTooltip>
                </RowBetween>    
              )}

              {badgeInfo[badgeIndex].beneficiary && (badgeInfo[badgeIndex].beneficiary !== ZERO_ADDRESS) && (
                <RowBetween align="center" height='24px'>
                  <RowFixed>
                    <Text fontWeight={500} fontSize={14} color={theme.text2}> Beneficiary Entity: </Text>
                    <QuestionHelper bkgOff={true} small={'s'} info={<>This is the address of the entity benefitted from
                                    the retirement badge. </>} />
                  </RowFixed>
                    <Text lineHeight={"24px"} fontWeight={700} fontSize={14} color={theme.text1}> 
                    {shortenAddress(badgeInfo[badgeIndex].beneficiary, 6)} </Text>
                </RowBetween>  
              )}                  

              {badgeInfo[badgeIndex].beneficiaryID && (
                <RowBetween align="center" height='24px'>
                  <RowFixed>
                    <Text fontWeight={500} fontSize={14} color={theme.text2}> Beneficiary Entity ID: </Text>
                    <QuestionHelper bkgOff={true} small={'s'} info={<>This is the identification of the entity 
                                benefitted from the retirement badge. </>} />
                  </RowFixed>
                  <MouseoverTooltip info={<>{badgeInfo[badgeIndex].beneficiaryID}</>}>
                    <Text lineHeight={"24px"} fontWeight={700} fontSize={14} color={theme.text1}> 
                      {shortenContent(badgeInfo[badgeIndex].beneficiaryID)} 
                    </Text>
                  </MouseoverTooltip>
                </RowBetween>    
              )}              
              
              {badgeInfo[badgeIndex].offsetMessage && (
                <RowBetween align="center" height='24px'>
                  <RowFixed>
                    <Text fontWeight={500} fontSize={14} color={theme.text2}> Retirement Message: </Text>
                    <QuestionHelper bkgOff={true} small={'s'} info={<>This is the message indicating some 
                              additional information to the retirement badge. </>} />
                  </RowFixed>
                  <MouseoverTooltip info={<>{badgeInfo[badgeIndex].offsetMessage}</>}>
                    <Text lineHeight={"24px"} fontWeight={700} fontSize={14} color={theme.text1}> 
                      {shortenContent(badgeInfo[badgeIndex].offsetMessage)} 
                    </Text>
                  </MouseoverTooltip>
                </RowBetween>    
              )}                   

              <RowBetween align="center" height='24px'>
                <RowFixed>
                  <Text fontWeight={500} fontSize={14} color={theme.text2}> Retirement RE Amount: </Text>
                  <QuestionHelper bkgOff={true} small={'s'} info={<>This is the amount of the retired renewable energy 
                                      of the selected retirement badge.</>} />
                </RowFixed>
                <Text lineHeight={"24px"} fontWeight={700} fontSize={14} color={theme.primaryText1}> {userBadgeAmountString} </Text>
              </RowBetween>

              <RowBetween align="center" height="24px">
                <RowFixed>
                  <Text fontWeight={500} fontSize={14} color={theme.text2}> Retirement Time: </Text>
                  <QuestionHelper bkgOff={true} small={'s'} info={<>This is the time you minted the selected
                                retirement badge.</>} />
                </RowFixed>
                <Text lineHeight={"24px"} fontWeight={700} fontSize={14} color={theme.primaryText1}> {retirementTimeStr} </Text>
              </RowBetween> 

              <RowBetween align="center" height="24px">
                <RowFixed>
                  <Text fontWeight={500} fontSize={14} color={theme.text2}> Number of Retirement Actions: </Text>
                  <QuestionHelper bkgOff={true} small={'s'} info={<>This is the time you minted the selected
                                retirement badge.</>} />
                </RowFixed>
                <Text lineHeight={"24px"} fontWeight={700} fontSize={14} color={theme.primaryText1}> 
                {badgeInfo[badgeIndex].offsetIds.length} </Text>
              </RowBetween> 

            </ARECContainer>
      )
    }

  function DetailedMinerProfile({minerIndex, minerList}: {minerIndex: number,  minerList: MinerInfo[]}) {
      const theme = useContext(ThemeContext)

      const totalREAmount = minerList.reduce<BigNumber>( (totalREAmount, miner:MinerInfo) => {
          return totalREAmount.add(BigNumber.from(miner.REOutput))
        },BigNumber.from(0)
      )

      const totalREAmountInKWH = new Fraction(totalREAmount.toString(), JSBI.BigInt(1000000))
      const totalREAmountString = (totalREAmountInKWH.toFixed(3, { groupSeparator: ',' }) ?? '0').concat(' KWH')

      const currentREAmountInKWH = new Fraction(minerList[minerIndex].REOutput, JSBI.BigInt(1000000))
      const currentREAmountString = (currentREAmountInKWH.toFixed(3, { groupSeparator: ',' }) ?? '0').concat(' KWH')

      const createTime = minersInfo?.[minerList[minerIndex].address].minerInfo?.timestamp
      const createTimeString = createTime ? DateTime.fromSeconds(createTime).toFormat("yyyy-MM-dd HH:mm:ss") : ''    

    return ( <ARECContainer>
        <RowBetween align="center" height='24px'>
          <RowFixed>
            <Text fontWeight={500} fontSize={14} color={theme.text2}> Total Number of Miners: </Text>
            <QuestionHelper bkgOff={true} small={'s'} info={<>This is the total number of all types of miners  
                            you are currently holding.</>} />
          </RowFixed>
          <Text lineHeight={"24px"} fontWeight={700} fontSize={14} color={theme.text1}> {minerList.length} </Text>
        </RowBetween>

        <RowBetween align="center" height='24px'>
          <RowFixed>
            <Text fontWeight={500} fontSize={14} color={theme.text2}> Overall RE output: </Text>
            <QuestionHelper bkgOff={true} small={'s'} info={<>This is the overall RE amount generated by 
                all your miners.</>} />                  
          </RowFixed>
          <Text lineHeight={"24px"} fontWeight={700} fontSize={14} color={theme.text1}> {totalREAmountString}</Text>
        </RowBetween>

        <SeparatorBlack style={{margin:'0.2rem 0rem'}} />  

        <RowBetween align="center" height='24px'>
          <RowFixed>
            <Text fontWeight={500} fontSize={14} color={theme.text2}> Miner Address: </Text>
            <QuestionHelper bkgOff={true} small={'s'} info={<>This is the address of the miner.</>} />
          </RowFixed>
          <MouseoverTooltip info={<>{minerList[minerIndex].address}</>}>
            <Text lineHeight={"24px"} fontWeight={700} fontSize={14} color={theme.primaryText1}> 
              {shortenAddress(minerList[minerIndex].address, 6)} 
            </Text>
          </MouseoverTooltip>
        </RowBetween>    

        <RowBetween align="center" height='24px'>
          <RowFixed>
            <Text fontWeight={500} fontSize={14} color={theme.text2}> Miner Type: </Text>
            <QuestionHelper bkgOff={true} small={'s'} info={<>This is the type of the selected miner, which could
              be game miner, remote miner, standard miner, and etc.</>} />
          </RowFixed>
          <Text lineHeight={"24px"} fontWeight={700} fontSize={14} color={theme.primaryText1}> 
            {minerTypeString(minerList[minerIndex].type)} 
          </Text>
        </RowBetween>   

        <RowBetween align="center" height='24px'>
          <RowFixed>
            <Text fontWeight={500} fontSize={14} color={theme.text2}> Miner Maker: </Text>
            <QuestionHelper bkgOff={true} small={'s'} info={<>This is the maker of the selected miner.</>} />
          </RowFixed>
          <Text lineHeight={"24px"} fontWeight={700} fontSize={14} color={theme.primaryText1}> 
          {minerList[minerIndex].maker} </Text>
        </RowBetween>          

        <RowBetween align="center" height='24px'>
          <RowFixed>
            <Text fontWeight={500} fontSize={14} color={theme.text2}> Accumulated RE Output: </Text>
            <QuestionHelper bkgOff={true} small={'s'} info={<>This is the total RE output by the 
              selected miner since it is onboarded.</>} />
          </RowFixed>
          <Text lineHeight={"24px"} fontWeight={700} fontSize={14} color={theme.primaryText1}> 
          {currentREAmountString} </Text>
        </RowBetween>          

        <RowBetween align="center" height='24px'>
          <RowFixed>
            <Text fontWeight={500} fontSize={14} color={theme.text2}> Miner Onboarding Time: </Text>
            <QuestionHelper bkgOff={true} small={'s'} info={<>This is the Onboarding time of the selected miner.</>} />
          </RowFixed>
          <Text lineHeight={"24px"} fontWeight={700} fontSize={14} color={theme.primaryText1}> {createTimeString} </Text>
        </RowBetween>           

        <RowBetween align="center" height='24px'>
          <RowFixed>
            <Text fontWeight={500} fontSize={14} color={theme.text2}> Miner NFT ID: </Text>
            <QuestionHelper bkgOff={true} small={'s'} info={<>This is the NFT ID of the selected miner.</>} />
          </RowFixed>
          <Text lineHeight={"24px"} fontWeight={700} fontSize={14} color={theme.primaryText1}> 
            {minersInfo?.[minerList[minerIndex].address].NFTID?.toString().padStart(4,'0')} 
          </Text>
        </RowBetween>              

      </ARECContainer>
    )
  }

  return (
    <>
      <OverviewHeadButton path={'Profile'} />
      <AppBody>
      <StyledPageCard bgColor={'red'}>
        <PageHeader header={'My Profile'}>
          <QuestionHelper bkgOff={true} text={'My Profile'} info={<OverviewHelpInfo/>} /> 
        </PageHeader>
        <Wrapper id="issuance-page">
          <Container style={{padding:'0.2rem 0.2rem 0.5rem'}}>
            <AssetRow3>
              <Text fontSize={28} fontWeight={500} style={{ textAlign:'center'}}>
                Assets
              </Text>

              <AutoColumn gap="2px" style={{padding: '2px'}}>
                <TYPE.Body style={{ margin: '0.5rem 1rem 0rem', textAlign:'center' }}> My AKRE </TYPE.Body>
                { !arkreenBalance ? (
                  <TYPE.Body fontSize={24} fontWeight={500} style={{ color:theme.primaryText1, textAlign:'center' }}>
                    <Loader />
                  </TYPE.Body>): (
                  <TYPE.Body fontSize={24} fontWeight={500} style={{ color:theme.primaryText1, textAlign:'center' }}>
                    {arkreenBalance?.toFixed(1)}
                  </TYPE.Body>
                )}
              </AutoColumn>
              <AutoColumn gap="2px" style={{padding: '2px'}}>
                <TYPE.Body style={{ margin: '0.5rem 1rem 0rem', textAlign:'center' }}> My ART </TYPE.Body>
                { !balanceARECT ? (
                  <TYPE.Body fontSize={24} fontWeight={500} style={{ color:theme.primaryText1, textAlign:'center' }}>
                    <Loader />
                  </TYPE.Body>): (
                  <TYPE.Body fontSize={24} fontWeight={500} style={{ color:theme.primaryText1, textAlign:'center' }}>
                    {balanceARECT?.toFixed(4)}
                  </TYPE.Body>
                )}
              </AutoColumn>
            </AssetRow3>
            <SeparatorBlack style={{margin:'0rem 0.5rem 0.2rem'}} />   
            <AssetRow4>
              <ProfileButton onClick={()=>{setCurButton('AREC')}} autofocus={curButton==='AREC'} 
                            title={below540?'ARECs':'AREC NFTs'} value = {numberOfARECNft?.toString()} />
              <ProfileButton onClick={()=>{setCurButton('Retirement')}} autofocus={curButton==='Retirement'}
                            title='Actions' value = {allUnclaimedActionsIDs?.length.toString()} />
              <ProfileButton onClick={()=>{setCurButton('Badge')}} autofocus={curButton==='Badge'}
                            title={below540?'Badges':'Badge SBTs'} value = {badgeCount?.toString()} />
              <ProfileButton onClick={()=>{setCurButton('Miner')}} autofocus={curButton==='Miner'}
                            title='Miners' value = {simulationMode ? '2': minerList?.length.toString()} />
            </AssetRow4>
          </Container>

          { (curButton ==='AREC') && (numberOfARECNft !== undefined) && (numberOfARECNft !==0) && (
              <Container style={{marginTop: "0.75rem"}}>
                <RowBetween align="center" height='20px' style={{padding: "0.75rem 1rem 0.2rem 1rem"}}>
                  <TYPE.Body color={theme.text2} fontWeight={500} fontSize={16}>
                    <strong>AREC NFT List:</strong>
                  </TYPE.Body>
                </RowBetween>                  
                <div style={{margin: '0.8rem 0.6rem 0.6rem'}}>
                  <ARECSelect size={4} itemSelected={true}  value={arecSelected ? arecSelected.toString():'0'} 
                               onChange = {onARECSelect}>
                    {allARECInfo.map((recData: RECData, index) => {
                      const optionText_ID = '0000'.concat(allARECNftTokensID[index].toString())
                      return  <ARECOption key={optionText_ID} value={index}> 
                                {'AREC_'.concat(optionText_ID.substring(optionText_ID.length-4)).concat(':')}
                                {'   '}
                                {recPowerList[index]} {`   `} {recStatusList[index]} 
                              </ARECOption>
                    })}
                  </ARECSelect>
                </div>
                { (allARECInfo[0] && (arecSelected !== undefined)) && (
                    <div style={{padding: '0rem 0.6rem 0.6rem 0.6rem'}}>
                      <DetailedARECInfo recData = {allARECInfo[arecSelected]} />
                    </div>
                )}
              </Container>
          )}

          { (curButton ==='AREC') && ((numberOfARECNft === undefined) || (numberOfARECNft ===0)) &&(
              <Container style={{marginTop: "0.75rem"}}>
                <div style={{padding: '0.3rem 0.6rem 0.6rem 0.6rem'}}>
                  <Text lineHeight={"24px"} fontWeight={500} fontSize={16} textAlign={'center'} color={theme.text1}> 
                    You have no AREC NFTs. <br/>
                    Please join the Arkreen community! 
                    <span role="img" aria-label="wizard-icon">👍</span>
                  </Text>
                </div>
              </Container>
            )}

          {((curButton ==='Retirement') && (allUnclaimedActionsIDs !== undefined) && (allUnclaimedActionsIDs.length !==0)) && (            
              <Container style={{marginTop: "0.75rem"}}>
                <RowBetween align="center" height='20px' style={{padding: "0.75rem 1rem 0.2rem 1rem"}}>
                  <TYPE.Body color={theme.text2} fontWeight={500} fontSize={16}>
                    <strong>Retirement Action List:</strong>
                  </TYPE.Body>
                </RowBetween>                  
                <div style={{margin: '0.8rem 0.6rem 0.6rem'}}>
                  <ARECSelect size={4} itemSelected={true}  value={offsetSelected ? offsetSelected.toString():'0'} 
                               onChange = {onOffsetSelect}>
                    {allUnclaimedActionsIDs.map((_, index) => {
                      const optionText_ID = '0000'.concat(allUnclaimedActionsIDs[index].toString())
                      return  <option value={index} key={optionText_ID} > 
                                {'ACT_'.concat(optionText_ID.substring(optionText_ID.length-4)).concat(':')}
                                {'         '} {retiredPowerUnclaimed[index]} 
                              </option>
                    })}
                  </ARECSelect>
                </div>
                { (offsetSelected !== undefined) &&(
                    <div style={{padding: '0.3rem 0.6rem 0.6rem 0.6rem'}}>
                      <DetailedRetirementProfile retireID = {offsetSelected} retireData = {allUnclaimedActions} />
                    </div>
                )}
              </Container>
            )}

            { (curButton ==='Retirement') && ((allUnclaimedActionsIDs === undefined) || (allUnclaimedActionsIDs.length ===0)) &&(
              <Container style={{marginTop: "0.75rem"}}>
                <div style={{padding: '0.3rem 0.6rem 0.6rem 0.6rem'}}>
                  <Text lineHeight={"24px"} fontWeight={500} fontSize={16} textAlign={'center'} color={theme.text1}> 
                    No retirement action available for badges. <br/>
                    Offset your carbon footprint with AREC! 
                    <span role="img" aria-label="wizard-icon">👍</span>
                  </Text>
                </div>
              </Container>
            )}            

            {((curButton ==='Badge') && (userARECBadgeIDList !== undefined) && (userARECBadgeIDList.length !==0)) && (            
              <Container style={{marginTop: "0.75rem"}}>
                <RowBetween align="center" height='20px' style={{padding: "0.75rem 1rem 0.2rem 1rem"}}>
                  <TYPE.Body color={theme.text2} fontWeight={500} fontSize={16}>
                    <strong>Retirement Badge List:</strong>
                  </TYPE.Body>
                </RowBetween>                  
                <div style={{margin: '0.8rem 0.6rem 0.6rem'}}>
                  <ARECSelect size={4} itemSelected={true}  value={badgeSelected ? badgeSelected.toString():'0'} 
                               onChange = {onBadgeSelect}>
                    {userARECBadgeIDList.map((badgeID, index) => {
                      const optionText_ID = badgeID.toString().padStart(4,'0')
                      return  <option value={index} key={optionText_ID} > 
                                {'BADGE_'.concat(optionText_ID).concat(':')}
                                {'         '} {userBadgeAmountString?.[index]} 
                              </option>
                    })}
                  </ARECSelect>
                </div>
                { (badgeSelected !== undefined) && (userARECBadgeInfoList !== undefined) && (userARECBadgeInfoList.length!==0) && (
                    <div style={{padding: '0.3rem 0.6rem 0.6rem 0.6rem'}}>
                      <DetailedBadgeProfile badgeIndex = {badgeSelected} badgeInfo = {userARECBadgeInfoList} />
                    </div>
                )}
                { (userARECBadgeInfoList !== undefined) && (userARECBadgeInfoList.length===0) &&(
                    <div style={{padding: '0.3rem 0.6rem 0.6rem 0.6rem'}}>
                      <Text lineHeight={"24px"} fontWeight={700} fontSize={14} color={theme.text1}> 
                        You do not have retirement badge. Please contribute to saving our earth!
                      </Text>
                    </div>
                )}
              </Container>
            )}         
            { (curButton ==='Badge') && ((userARECBadgeInfoList === undefined) || (userARECBadgeInfoList.length===0)) &&(
              <Container style={{marginTop: "0.75rem"}}>
                <div style={{padding: '0.3rem 0.6rem 0.6rem 0.6rem'}}>
                  <Text lineHeight={"24px"} fontWeight={500} fontSize={16} textAlign={'center'} color={theme.text1}> 
                    You do not have retirement badge yet. <br/>
                    Please contribute to saving our earth! 
                    <span role="img" aria-label="wizard-icon">👍</span>
                  </Text>
                </div>
              </Container>
            )}

            { (curButton ==='Miner') && (errorMiner ==='') && (
                (minerList === undefined)
                  ? <Container style={{marginTop: "0.75rem"}}>
                      <div style={{padding: '0.3rem 0.6rem 0.6rem 0.6rem'}}>
                        <Text lineHeight={"24px"} fontWeight={500} fontSize={16} textAlign={'center'} color={theme.text1}> 
                          Waiting for miner's info! <Loader/>
                        </Text>
                      </div>
                    </Container>
                  : (minerList.length ===0) ?
                    <Container style={{marginTop: "0.75rem"}}>
                      <div style={{padding: '0.3rem 0.6rem 0.6rem 0.6rem'}}>
                        <Text lineHeight={"24px"} fontWeight={500} fontSize={16} textAlign={'center'} color={theme.text1}>
                          { simulationMode ? `You are in simulation mode, there is no miner info!   Please order miner(s) from: ` 
                            : `You have no miner yet!   Please order from: `
                          }
                          <br/>
                          <OrderLink id="link" href= {MINER_BUY_URL} >
                            <ShoppingCart size={16} />
                            Order Miners
                          </OrderLink>  
                        </Text>          
                      </div>            
                    </Container>             
                  : <Container style={{marginTop: "0.75rem"}}>
                      <RowBetween align="center" height='20px' style={{padding: "0.75rem 1rem 0.2rem 1rem"}}>
                        <TYPE.Body color={theme.text2} fontWeight={500} fontSize={16}>
                          <strong> Miner List:</strong>
                        </TYPE.Body>
                      </RowBetween>                  
                      <div style={{margin: '0.8rem 0.6rem 0.6rem'}}>
                        <ARECSelect size={4} itemSelected={true}  value={minerSelected ? minerSelected.toString():'0'} 
                                    onChange = {onMinerSelect}>
                          {minerList.map((miner, index) => {
                            const optionText_ID = index.toString().padStart(2,' ')
                            return  <option value={index} key={optionText_ID} > 
                                      {optionText_ID.concat(':')}
                                      {'     '} {shortenAddress(miner.address,6)}  
                                      {'     '} {minerTypeString(miner.type)}  
                                    </option>
                          })}
                        </ARECSelect>
                      </div>

                      { (minerSelected !== undefined) && (minerList !== undefined) && (
                        <div style={{padding: '0.3rem 0.6rem 0.6rem 0.6rem'}}>
                          <DetailedMinerProfile minerIndex = {minerSelected} minerList = {minerList} />
                        </div>
                      )}
                    </Container>
              )
            }    

          {!account && (
            <ButtonPrimary style={{marginTop: "0.5rem"}} onClick={toggleWalletModal}>Connect Wallet</ButtonPrimary>
          )}
         </Wrapper>
        </StyledPageCard>
      </AppBody>
    </>
  )
}

