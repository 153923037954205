import React, { useContext, useMemo } from 'react'
import { Fraction, JSBI } from '@feswap/sdk'
//import { darken } from 'polished'
import { Text } from 'rebass'
import styled, { ThemeContext } from 'styled-components'
import { ButtonPrimary } from '../../components/Button'
import { StyledPageCard } from '../../components/earn/styled'
import { AutoColumn } from '../../components/Column'
import { Wrapper } from '../../components/swap/styleds'
import { OverviewHeadButton } from '../../components/ARecIssuance'
//import { useHistory } from 'react-router-dom'
import { useWalletModalToggle } from '../../state/application/hooks'
import PageHeader from '../../components/PageHeader'
import { useOverallARECInfo } from '../../state/issuance/hooks'
import Loader from '../../components/Loader'
import { useActiveWeb3React } from '../../hooks'
import { RowBetween, RowFixed } from '../../components/Row'

import { Container } from '../../components/CurrencyInputPanel'
import { TYPE } from '../../theme'
import { BigNumber } from 'ethers'
import AppBody from '../AppBody'
import QuestionHelper from '../../components/QuestionHelper'

export const ARECContainer = styled.div`
  border-radius: 6px;
  border: 1px solid ${({ theme }) => theme.bg4};
  padding: 0.3rem 0.6rem 0.3rem 0.6rem;
  background: transparent;
`

const DataRow = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr;
  column-gap: 6px;
  align-items: start;
  justify-content: space-between;
`

function OverviewHelpInfo( ) {
  return (<>
            <Text> This is the overview of whole Arkreen AREC ecosystem. </Text>
          </>
        )
  }

export function getPowerAndString(power: BigNumber): [string, string] {
    if( power.lt(BigNumber.from(1000_000_000)) ) {
      return [(new Fraction(power.toString(), JSBI.BigInt(1000_000))).toFixed(3, { groupSeparator: ',' }), 'KWH']
    } else {
      return [(new Fraction(power.toString(), JSBI.BigInt(1000_000_000))).toFixed(3, { groupSeparator: ',' }), 'MWH']
    }
  }  

export default function Overview() {

  const { account } = useActiveWeb3React()

  const theme = useContext(ThemeContext)
  //const history = useHistory()

  // toggle wallet when disconnected
  const toggleWalletModal = useWalletModalToggle()

  const { 
    AllARECCount,
    AllRedeemedARECCount,
    AllLiquidizedARECCount,
    AllREAIssued,
    AllREARedeemed,
    AllREALiquidized,  
    allARECRetirementTotalSupply,
    AllARECRetirementTotalAmount,
    ARECTotalSupply,
    ARECTotalOffset
  } = useOverallARECInfo()

  const [ AllARECRetirementTotalAmountString, unitRetiredString ] = useMemo (()=>{
    if (!AllARECRetirementTotalAmount) return [undefined, undefined]
    return getPowerAndString(AllARECRetirementTotalAmount)
  },[AllARECRetirementTotalAmount])
  

  const [AllREAIssuedString, unitIssuedString] = useMemo (()=>{
    if (!AllREAIssued) return [undefined, undefined]
    return getPowerAndString(AllREAIssued)
  },[AllREAIssued])
  
//  console.log("AllREAIssued", AllREAIssued?.toString(), AllREAIssuedString, unitIssuedString)

  const [AllREARedeemedString, unitRedeemedString] = useMemo (()=>{
    if (!AllREARedeemed) return [undefined, undefined]
    return getPowerAndString(AllREARedeemed)
  },[AllREARedeemed])

  const [AllREALiquidizedString, unitLiquidizedString]   = useMemo (()=>{
    if (!AllREALiquidized) return [undefined, undefined]
    return getPowerAndString(AllREALiquidized)
  },[AllREALiquidized])

  const ARECTotalSupplyString = useMemo (()=>{
    if (!ARECTotalSupply) return undefined
    return (new Fraction(ARECTotalSupply.toString(), JSBI.BigInt(1000_000_000))).toFixed(3)
  },[ARECTotalSupply])

  const ARECTotalOffsetString = useMemo (()=>{
    if (!ARECTotalOffset) return undefined
    return (new Fraction(ARECTotalOffset.toString(), JSBI.BigInt(1000_000_000))).toFixed(3)
  },[ARECTotalOffset])

  return (
    <>
    <OverviewHeadButton path={'Overview'} />
    <AppBody>
      <StyledPageCard bgColor={'red'} style={{margin:'0rem 0rem', border:'1px solid #99D3B0'}}>
        <PageHeader header={'Overview'}>
          <QuestionHelper bkgOff={true} text={'Overview'} info={<OverviewHelpInfo/>} /> 
        </PageHeader>
        <Wrapper id="issuance-page">
          <DataRow>
            <Container style={{margin:'0rem 0rem', backgroundColor:'#E6F4EB'}}>
              <AutoColumn gap="sm">
                <TYPE.Body style={{ margin: '0.5rem 1rem 0rem' }}>Total AREC Issued:</TYPE.Body>
                <RowBetween style={{ padding:'0rem 1rem'}}>
                  <TYPE.Body fontSize={24} fontWeight={500} style={{ color:theme.primaryText1, textAlign:'center' }}>
                    {AllARECCount?.toString()}
                  </TYPE.Body>
                  <TYPE.Body fontSize={20} fontWeight={400} style={{ textAlign: 'center' }}>
                    NFTs
                  </TYPE.Body>
                </RowBetween>
                <RowBetween style={{ padding:'0rem 1rem 0.5rem'}}>
                  <TYPE.Body fontSize={20} fontWeight={500} style={{color: theme.primaryText1, textAlign: 'center' }}>
                    {AllREAIssuedString}
                  </TYPE.Body>
                  <TYPE.Body fontSize={20} fontWeight={400} style={{ textAlign: 'center' }}>
                    {unitIssuedString}
                  </TYPE.Body>
                </RowBetween>
              </AutoColumn>
            </Container>
            
            <Container style={{margin:'0rem 0rem', backgroundColor:'#E6F4EB'}}>
              <AutoColumn gap="sm">
                <TYPE.Body style={{ margin: '0.5rem 1rem 0rem' }}>Total AREC Retired:</TYPE.Body>
                <RowBetween style={{ padding:'0rem 1rem'}}>
                  <TYPE.Body fontSize={24} fontWeight={500} style={{color:theme.primaryText1, textAlign: 'center' }}>
                    {allARECRetirementTotalSupply?.toString()}
                  </TYPE.Body>
                  <TYPE.Body fontSize={20} fontWeight={400} style={{ textAlign: 'center' }}>
                    SBTs
                  </TYPE.Body>
                </RowBetween>
                <RowBetween style={{ padding:'0rem 1rem 0.5rem'}}>
                  <TYPE.Body fontSize={20} fontWeight={500} style={{ color:theme.primaryText1, textAlign: 'center' }}>
                    {AllARECRetirementTotalAmountString}
                  </TYPE.Body>
                  <TYPE.Body fontSize={20} fontWeight={400} style={{ textAlign: 'center' }}>
                    {unitRetiredString}
                  </TYPE.Body>
                </RowBetween>
              </AutoColumn>
            </Container>
          </DataRow>

          <AutoColumn gap={'md'} style={{ padding:'1rem 0rem 0.5rem'}}>
            <Container style={{margin:'0rem 0rem', border:'1px solid #33A761'}}>
            
              <AutoColumn gap="4px" style={{padding: "0.5rem 0.5rem 0.5rem 0.5rem"}}>
                  <ARECContainer>
                    <RowBetween align="center">
                      <RowFixed height='24px'>
                        <Text fontWeight={500} fontSize={14} color={theme.text2}> Redeemed AREC NFTs: </Text>
                        <QuestionHelper bkgOff={true} small={'s'} info={<>This is the total number of AREC NFTS 
                            redeemed by all the community members of Arkreen ecosystem. </>} />
                      </RowFixed>
                      { (AllRedeemedARECCount === undefined) ? (
                          <Loader  />
                        ) : (
                          <Text lineHeight={"24px"} fontWeight={700} fontSize={14} color={theme.text2}> {AllRedeemedARECCount} </Text>
                        ) 
                      }
                    </RowBetween>

                    { AllREARedeemedString && (
                      <RowBetween align="center" height='24px'>
                        <RowFixed>
                          <Text fontWeight={500} fontSize={14} color={theme.text2}> Redeemed AREC Amount: </Text>
                          <QuestionHelper bkgOff={true} small={'s'} info={<> This is the total amount of renewable energy
                            denominate by the AREC NFTS redeemed by all the community members of Arkreen ecosystem. </>} />
                        </RowFixed> 
                        <Text lineHeight={"24px"} fontWeight={700} fontSize={14} color={theme.text2}> 
                          {AllREARedeemedString} {unitRedeemedString}
                        </Text>
                      </RowBetween>
                    )}   
                  </ARECContainer>

                  <ARECContainer style={{marginTop: "0.5rem"}}>
                    <RowBetween align="center" height='24px'>
                      <RowFixed>
                        <Text fontWeight={500} fontSize={14} color={theme.text2}> Liquidized AREC NFTs: </Text>
                        <QuestionHelper bkgOff={true} small={'s'} info={<> This is the total number of AREC NFTS 
                             liquidized to ART tokens by all Arkreen community members. </>} />
                      </RowFixed>                      
                      { (AllLiquidizedARECCount === undefined) ? (
                          <Loader  />
                        ) : (
                          <Text lineHeight={"24px"} fontWeight={700} fontSize={14} color={theme.text2}> {AllLiquidizedARECCount} </Text>
                        ) 
                      }
                    </RowBetween>
                    { AllREALiquidizedString && (
                      <RowBetween align="center" height='24px'>
                        <RowFixed>
                          <Text fontWeight={500} fontSize={14} color={theme.text2}> Liquidized AREC Amount: </Text>
                          <QuestionHelper bkgOff={true} small={'s'} info={<> This is the total amount of renewable energy
                            denominate by the AREC NFTS liquidized by all Arkreen community members. </>} />
                        </RowFixed>                            
                        <Text lineHeight={"24px"} fontWeight={700} fontSize={14} color={theme.text2}> 
                          {AllREALiquidizedString} {unitLiquidizedString}
                        </Text>
                      </RowBetween>
                    )} 
                    { ARECTotalSupplyString && (
                      <RowBetween align="center" height='24px'>
                        <RowFixed>
                          <Text fontWeight={500} fontSize={14} color={theme.text2}> Circulating ART Tokens: </Text>
                          <QuestionHelper bkgOff={true} small={'s'} info={<> This is the amount of the ART tokens held by 
                            all Arkreen community members, which may be staying in the owner's wallets or 
                            the DEX liquidity pools. </>} />
                        </RowFixed>   
                        <Text lineHeight={"24px"} fontWeight={700} fontSize={14} color={theme.text2}> 
                          {ARECTotalSupplyString} ART
                        </Text>
                      </RowBetween>
                    )}            

                    { ARECTotalOffsetString && (
                      <RowBetween align="center" height='24px'>
                        <RowFixed>
                          <Text fontWeight={500} fontSize={14} color={theme.text2}> Burned ART Token: </Text>
                          <QuestionHelper bkgOff={true} small={'s'} info={<> This is the amount of the ART tokens burned by 
                            all Arkreen community members in the carbon offset actions. </>} />
                        </RowFixed> 
                        <Text lineHeight={"24px"} fontWeight={700} fontSize={14} color={theme.text2}> 
                          {ARECTotalOffsetString} ART
                        </Text>
                      </RowBetween>
                    )}   

                </ARECContainer>
              </AutoColumn>
            </Container>   
          </AutoColumn>

          {!account && (
            <ButtonPrimary style={{marginTop: "0.5rem"}} onClick={toggleWalletModal}>Connect Wallet</ButtonPrimary>
          )}

        </Wrapper>  
      </StyledPageCard>
    </AppBody>
    </>
  )
}