import React, { CSSProperties } from "react";
import '../ProofCard/ProofCard.css'
import { useActiveWeb3React } from '../../hooks'
import { ChainId } from '@feswap/sdk'

type cardProps = {
    title: string,
    dataList: any[],
    style?:CSSProperties,
    link?:boolean
    email?:boolean
    account?:boolean
    hash?:boolean
    token?:boolean
    cells?:boolean
}

export default function ProofCard(props: cardProps): JSX.Element {

    const { chainId } = useActiveWeb3React()

    const linkHead = (chainId === ChainId.MATIC_TESTNET) ? "https://mumbai.polygonscan.com/" : "https://polygonscan.com/"

    function formatLongString(str: string,prefix: number,suffix: number){
        if(!str){
            return '';
        }
        if(str?.length<=prefix+suffix){
            return str;
        }else {
            const prefixStr =  str.substring(0,prefix);
            const suffixStr =  str.substring(str.length-suffix);
            return prefixStr+"..."+suffixStr
        }
    }

    return (
        <div className='detail-header'>
            <div>
                <span className="detail-title">{props.title}</span>
            </div>
            <div style={{paddingBlockStart:'20px'}}>
                {
                    props.dataList?props.dataList.map((item:any) => 
                        <div key={item.title} className='detail-header-info'>
                            
                            {
                            item.cells?
                            (
                                <div>
                                    <div className='detail-header-cell-title'>{item.icon} <span style={{marginLeft:'8px'}}>{item.title}</span></div>
                                    <div className="proof-cell" >{item.value}</div>
                                </div>
                            ):
                            (
                                <>
                                    <span className='detail-header-info-title'>{item.icon} <span style={{marginLeft:'8px'}}>{item.title}</span></span>
                                    <span className='detail-header-info-value'>
                                        {item.trec?<a className="proof-url-link" href={item.value} target='_blank' rel="noopener noreferrer" >IREC Certification Link</a>:''}
                                        {item.link?<a className="proof-url-link" href={item.value} target='_blank' rel="noopener noreferrer" >{item.value}</a>:''}
                                        {item.email?<a className="proof-url-link" href={"mailto:"+item.value} target='_blank' rel="noopener noreferrer" >{item.value}</a>:''}
                                        {item.account?<a className="proof-url-link" href={linkHead+"address/"+item.value} target='_blank' rel="noopener noreferrer" >{formatLongString(item.value,7,7)}</a>:''}
                                        {item.hash?<a className="proof-url-link" href={linkHead+"tx/"+item.value} target='_blank' rel="noopener noreferrer" >{formatLongString(item.value,7,7)}</a>:''}
                                        {(!item.trec && !item.link && !item.email && !item.account && !item.hash && !item.cells)?item.value:''}
                                    </span>
                                </>
                            )
                            }
                        </div>
                    ):<></>
                }
            </div>
        </div>
    )
}