import React, { useContext, useState, useRef, useCallback, useMemo, useEffect, ReactNode } from 'react'
import { Fraction, JSBI, ChainId, WETH, Token, TokenAmount, CurrencyAmount } from '@feswap/sdk'

//import { Currency, ETHER, Token, currencyEquals, WETH } from '@feswap/sdk'

//import { darken } from 'polished'
import { useMedia } from 'react-use'
import styled, { ThemeContext } from 'styled-components'
import { Text } from 'rebass'
import { BigNumber, Contract, utils } from 'ethers'
import { MaxUint256 } from '@ethersproject/constants'

import { ButtonPrimary, BlockLayout, ButtonCell, InfoLayout, SelectedCell, PayLayout } from '../../components/Button'

//import { OverviewHeadButton } from '../../components/ARecIssuance'
//import { useHistory } from 'react-router-dom'

import { useTransactionAdder } from '../../state/transactions/hooks'
import { useWalletModalToggle } from '../../state/application/hooks'
import { CellBody } from '../AppBody'
import { RowBetween, RowFixed } from '../../components/Row'
import { ARECContainer } from '../Overview'
import { M } from '../../components/ARecIssuance'
import { useActiveWeb3React } from '../../hooks'
import { useHashKeyESGContract, useArkreenTokenContract, useHashKeyARECTokenAddress, useHARTBankContract } from '../../hooks/useContract'
import { usePermitTokenContract, useFeswRouterContract } from '../../hooks/useContract'
import useTransactionDeadline from '../../hooks/useTransactionDeadline'
import { DateTime } from 'luxon'
import { useUserTransactionTTL, useGetGreenBTCInfo, useGreenBTCTokenSaver, useGreenBTCTokenMVPSaver,
          useSimulationMode, useGreenBTCTokenInfoClearer } from '../../state/user/hooks'        // useActorInfo
import { splitSignature } from 'ethers/lib/utils'
import { calculateGasMargin } from '../../utils'
import { TransactionResponse } from '@ethersproject/providers'
import { USDT, USDC } from '../../constants'
import { useCurrency } from '../../hooks/Tokens'

import { useSingleCallResult } from '../../state/multicall/hooks'
import {  ButtonConfirmed, LogoCell } from '../../components/Button'
//import ProgressSteps from '../../components/ProgressSteps'
import { ApprovalState, useApproveCallback } from '../../hooks/useApproveCallback'
import { RECRequest } from '../../state/issuance/hooks'
import Modal from '../../components/Modal'
import { AutoColumn } from '../../components/Column'
import { CloseIconSquare  } from '../../theme/components'
import QuestionHelper from '../../components/QuestionHelper'
import { MouseoverTooltip, MouseoverTooltipNew } from '../../components/Tooltip'
import { SeparatorBlack } from '../../components/SearchModal/styleds'
import { shortenAddress, shortenCID } from '../../utils'
import { ExternalLink } from 'theme'
import { ExternalLink as LinkIcon, Twitter, Globe } from 'react-feather'
import { ErrorPromptModal } from '../../components/ARecIssuance'
import Slider from '../../components/Slider'
import ArkreenLogo from '../../assets/svg/arkreen.png'
import HashKeyLogo from '../../assets/svg/HashKey-logo.png'
import HashKeyProLogo from '../../assets/svg/HashKeyPro.png'    

//import HashKeyLogo from '../../assets/svg/HashKey-Group.png'
//import BitMainLogo from '../../assets/svg/Bitmain.png'

import BitOriginLogo from '../../assets/svg/BitOrigin.png'

import CanaanLogo from '../../assets/svg/Canaan.png'
//import CanaanLogo from '../../assets/svg/EBang.png'
import EBangLogo from '../../assets/svg/EBang.png'

import iPolloLogo from '../../assets/svg/iPollo.jpg'      // iPollo.jpg
import iPolloALogo from '../../assets/svg/iPolloC.jpg'    // iPollo.jpg

import Easy2MineLogo from '../../assets/svg/Easy2Mine.jpg'
import CypherLogo from '../../assets/svg/Cypher.png'

import UniPowLogo from '../../assets/svg/Unipow.png'

import { useWindowSize } from '../../hooks/useWindowSize'
import { BasicLink } from '../../components/Link'
//import { MenuItem } from '../../components/Header'

import { usePlaceCells } from './placeCells'      
import { fetchGBTCTrxInfo } from './GBTCInfo'

import AllEntities  from './entity.json'

export const maxBrickNumber = 2427                                    // 1980
export const maxColumn =      80                                      // 1980
export const maxRow =         31                                      // 1980

export function getPowerAndString(power: BigNumber): [string, string] {
    if( power.lt(BigNumber.from(1000_000_000)) ) {
      return [(new Fraction(power.toString(), JSBI.BigInt(1000_000))).toFixed(3, { groupSeparator: ',' }), 'KWH']
    } else {
      return [(new Fraction(power.toString(), JSBI.BigInt(1000_000_000))).toFixed(3, { groupSeparator: ',' }), 'MWH']
    }
  }  


// Get AREC retirement NFT number
export function useMaxBrickNumber(): number | undefined {
  const hashKeyESGContract = useHashKeyESGContract(false)  
  const res = useSingleCallResult(hashKeyESGContract, 'maxRECToGreenBTC')
  if (res.result && !res.loading) {
    return (res.result[0] as BigNumber).toNumber()
  }
  return undefined
}

export function useTotalSupply(): number | undefined {
  const hashKeyESGContract = useHashKeyESGContract(false)  
  const res = useSingleCallResult(hashKeyESGContract, 'totalSupply')
  if (res.result && !res.loading) {
    return (res.result[0] as BigNumber).toNumber()
  }
  return undefined
}

export function useGetAllBrickIDs(idStart: number, idEnd: number): 
      { totalTokens: BigNumber | undefined, 
        bricksOwners: string[] | undefined,
        allBricks:  BigNumber[] |undefined
      } {
  const hashKeyESGContract = useHashKeyESGContract(false)  
  const res = useSingleCallResult(hashKeyESGContract, 'getAllBrickIDs', [idStart, idEnd])
  if (res.result && !res.loading) {
    return  { totalTokens: res.result[0] as BigNumber, 
              bricksOwners: res.result[1] as string[],
              allBricks: res.result[2] as BigNumber[]
            }
  }
  return {totalTokens: undefined, bricksOwners: undefined, allBricks: undefined}
}

  export interface BrickInfo {
      sold:       boolean
      owner:      string
      ESGID:      number
      numCell:    number
  }

  const Wrapper = styled.div`
    width: 100%;
  `

  export const SocialIcon = styled(ExternalLink)`
    border-radius: 5px;
    align-items: center;
    color: ${({ theme }) => theme.text2};
    background-color: ${({ theme }) => theme.bg5};
    :hover {
      color: ${({ theme }) => theme.text1};
      background-color: ${({ theme }) => theme.primary3};
      cursor: pointer;
      text-decoration: none;
    }
  `

/*
  export const MenuItem = styled(ExternalLink)`
  flex: 1;
  color: ${({ theme }) => theme.text2};
  height: 35px;
  width: 35px;
  border: none;
  margin-left: 8px;
  border-radius: 0.5rem;
  align-items: center;
  align-content: center;
  background-color: ${({ theme }) => theme.bg5};
  :hover {
    color: ${({ theme }) => theme.text1};
    background-color: ${({ theme }) => theme.bg3};
    cursor: pointer;
    text-decoration: none;
    font-size: 24px;
  }
  > svg {
    margin-right: 8px;
  }
`
*/

  export interface actorBrick {
    address:        string
    ESGID:          number
    level:          number
    brickIDs:       BigNumber
    brickIDsMVP:    BigNumber[] | undefined
    logo?:          string
    info?:          string
    blockHeight?:   string
    timeTrx?:       string
    hashTrx?:       string
    cellIDs:        number[]
  }

  export interface actorCompanyInfo {
    web_site: string
    logo: any
    background_color: any
    twitter: string
    name: string
  }

  interface BrickInfoContentProps {
    id:   number
    Title:    string
    actor:    actorBrick
    pendingText: string
    onDismiss: () => void
  }
  
  interface BrickInfoModalProps {
    id:     number
    actor:  actorBrick
    isOpen: boolean
    onDismiss: () => void
  }

  interface EnityInfo {
      [ESGID: number]: string | undefined  
  }

//  export const USDC: { [chainId in ChainId]: Token } = {

  export const allRegisteredEntity: EnityInfo = {
      10: 'Arkreen',
      11: 'HashKey',
      24: 'Canaan',
      26: 'Ebang',
      27: 'Cypher',
      28: 'Easy2Mine',
      29: 'iPollo',
      31: 'bitOrigin',
      39: 'Unipow',
    }
    
  export const allRegisteredEntityMatic: EnityInfo = {
    0: 'Arkreen',
    1: 'HashKey',
    2: 'bitOrigin',
    4: 'Ebang',
    5: 'Unipow',
    7: 'iPollo',
    8: 'Cypher',
    9: 'Easy2Mine',
    10: 'iPolloC',
    13: 'HashKeyPro',
  }

  export const logoLevel = [  'shrimp.png', 'crab.png', 'octopus.png', 'fish.png',
                              'dolphin.png', 'shark.png', 'whale.png', 'humpback.png']
 
  export const ESGContainer = styled.div`
  border-radius: 6px;
  border: 1px solid ${({ theme }) => theme.bg4};
  padding: 0.3rem 0.6rem 0.3rem 0.6rem;
  background: transparent;
`

  export const DivScalable = styled.div`
    overflow: scroll;
  `

  export function DivSizeable({ children }: { children: ReactNode }) {
   
    useEffect(() => {
      function handleResize() {
        console.log('resized to: ', window.innerWidth, 'x', window.innerHeight)
      }
  
      window.addEventListener('resize', handleResize)
      return () => {
        window.removeEventListener('resize', handleResize)
      }
    })

    return <DivScalable>{children}</DivScalable>
  }

  export const SliderLayout = styled.div`
    display: grid;
    grid-template-columns: 2fr 3fr;
    height: 30px;
    align-items: center;
  `
  function SetBuyNumberOfCells({ value, onChange }: { 
      value: number
      onChange: (rateSplit: number) => void 
    }) {
    const theme = useContext(ThemeContext)
    return (  <SliderLayout>
                <Text lineHeight={'30px'} fontWeight={500} color={theme.text2}>Will Buy <M size={'20px'}>{Math.floor(value/10)}</M> Cells</Text>
                <Slider value={value} min={0} step ={4} max ={216} size={12} wwidth={'93%'} onChange={onChange} />
              </SliderLayout> )
  }

  function BrickInfoContent({ id, Title, actor, onDismiss, pendingText }: BrickInfoContentProps) {
    const { account, chainId } = useActiveWeb3React()
    const theme = useContext(ThemeContext)
    const below540 = useMedia('(max-width: 800px)')  

    let brickIDs: number[] = []
    let allBrickID = actor.brickIDs
    while (!allBrickID.isZero()) {
      brickIDs.push(allBrickID.and("0xFFF").toNumber())
      allBrickID = allBrickID.shr(12)
    }

    let allBrickIDMVP = actor.brickIDsMVP
    if (allBrickIDMVP !== undefined) {
      for (let index = 0; index < allBrickIDMVP.length; index++) { 
        let allBrickID = allBrickIDMVP[index]
        while (!allBrickID.isZero()) {
          brickIDs.push(allBrickID.and("0xFFF").toNumber())
          allBrickID = allBrickID.shr(12)
        }
      }
    }

    let brickIDsHead = brickIDs.slice(0, 3).reduce<string>((brickIDs, id, index) => { 
                      return brickIDs + ((index === 0) ? '' : ', ') + id.toString().padStart(4,'0')}, '')

    const brickIDsFull = brickIDs.reduce<string>((brickIDs, id, index) => { 
                      return brickIDs + ((index === 0) ? '' : ', ') + id.toString().padStart(4,'0')}, '')

    if (brickIDs.length > 3) brickIDsHead = brickIDsHead + `... (${actor.level} cells)`
    
    const amountCO2 = actor.level * 2 * 0.709
    const level = (actor.level>21) ? 8 : Math.ceil(actor.level/3)
    const dateNow = actor.timeTrx ? DateTime.fromSeconds(parseInt(actor.timeTrx)).toFormat("yyyy-MM-dd hh:mm:ss") :undefined

    const entity = ((chainId === ChainId.MATIC_TESTNET) && account) ? allRegisteredEntity[actor.ESGID] : allRegisteredEntityMatic[actor.ESGID]
    const name = entity ? (AllEntities as any)[entity].name : 'EcoCoiner #' + (actor.ESGID+1).toString().padStart(3,'0')
    const logo = entity ? (AllEntities as any)[entity].logo : logoLevel[level-1] 
    const backgroundColor = entity ? (AllEntities as any)[entity].backgroundColor : "00923a"
    const padding = entity ? (AllEntities as any)[entity].padding : "0px 10px"
    const website = entity ? (AllEntities as any)[entity].website : undefined
    const twitter = entity ? (AllEntities as any)[entity].twitter as string : ''
            
    const linkHead = (chainId === ChainId.MATIC_TESTNET) ? "https://mumbai.polygonscan.com/tx/" : "https://polygonscan.com/tx/"

    const ActorLayout = styled.div`
      display: grid;
      width: 100%;
      grid-template-columns: 1fr 1fr;
      column-gap: 6px;
      align-items: center;
      justify-content: space-between;
    `

    return (
      <Wrapper >
        <AutoColumn>
        <RowBetween style={{padding: '1rem 1rem 0rem'}}>
          <Text fontSize={24}> <M>{Title}</M> #{(actor.ESGID+1).toString().padStart(4,'0')}</Text>
          <CloseIconSquare size={24} strokeWidth={'1px'} color={theme.primary} onClick={onDismiss} />
        </RowBetween>
        <SeparatorBlack style={{margin:'0.2rem 0rem 0rem'}} />  


          <RowBetween align="center" height='40px' style ={{padding:'0.6rem 0.8rem 0.3rem'}}>
            <RowFixed>
                { website && 
                  <SocialIcon id="link" href={website} draggable='false' style={{marginRight: '6px'}} >
                    <Globe size={12} style={{width:'24px'}}/>
                  </SocialIcon>
                }
                { twitter &&
                  <SocialIcon id="link" href={twitter} draggable='false' style={{marginRight: '6px'}}>
                    <Twitter size={12} style={{width:'24px'}} />
                  </SocialIcon>
                }
              <Text lineHeight={"32px"} fontWeight={700} color={theme.primary} > 
                Green BTC Actor:         
              </Text>
            </RowFixed>
            <MouseoverTooltip info={<>{actor.address}</>}>
              <Text lineHeight={"32px"} fontWeight={700}  color={theme.primary}> 
                {shortenAddress(actor.address, 6)} 
              </Text>
            </MouseoverTooltip>
          </RowBetween>            

            <ActorLayout style={{backgroundColor: theme.bg2, borderBottom: `1px solid ${theme.primary5}`}} >
              <div>
                <RowBetween align="center" height='32px' style={{padding:'0rem 0.8rem', borderBottom: `1px solid ${theme.primary5}`}}>
                  <RowFixed>
                    <Text fontWeight={500}  color={theme.text2}> Name: </Text>
                    <QuestionHelper bkgOff={true} small={'s'} info={<>This is the entity greenizing some of the cells of BTC block #779089.</>} />
                  </RowFixed>
                  <MouseoverTooltip info={<>{actor.address}</>}>
                      <Text lineHeight={"32px"} fontWeight={700}  color={theme.primary}> 
                        {name}
                      </Text>
                  </MouseoverTooltip>
                </RowBetween>

                <RowBetween align="center" height='32px' style={{padding:'0rem 0.8rem'}}>
                  <RowFixed>
                    <Text fontWeight={500} color={theme.text2}> Level: </Text>
                    <QuestionHelper bkgOff={true} small={'s'} info={<>Actor level is same as the number of cells the actor is possessing.</>} />
                  </RowFixed>
                  <Text lineHeight={"32px"} fontWeight={700} color={theme.primary}> 
                    {level}
                  </Text>
                </RowBetween>
   
              </div>
              <div style={{display:'flex', justifyContent:'right', paddingRight:'0.8rem'}}>
                <img style={{borderRadius:'6px', height: '60px', backgroundColor, padding }} 
                  src={ entity ? require('../../assets/svg/' + logo) : require('../../assets/NFT/' + logo) } alt="logo" />
              </div>
            </ActorLayout>

            <Text lineHeight={"32px"} fontWeight={700} color={theme.primary} style ={{padding:'0.3rem 0.8rem 0rem'}}> 
              Green Energy Impacts:          
            </Text>

            <div style={{backgroundColor: theme.bg2}}>    

              <RowBetween align="center" height='32px' style={{padding:'0rem 0.8rem', borderBottom: `1px solid ${theme.primary5}`}}>
                <RowFixed>
                  <Text fontWeight={500} color={theme.text2}> Greenized Power Consumption: </Text>
                  <QuestionHelper bkgOff={true} small={'s'} info={<>Amount of the energy consumed to mine the BTC block #779089 
                    that have been greenized by the actor.</>} />
                </RowFixed>
                <Text lineHeight={"32px"} fontWeight={700} color={theme.primary}> 
                  {actor.level *2}{`.00 MWh`}
                </Text>
              </RowBetween>     

              <RowBetween align="center" height='32px' style={{padding:'0rem 0.8rem', borderBottom: `1px solid ${theme.primary5}`}}>
                <RowFixed>
                  <Text fontWeight={500} color={theme.text2}> Carbon Emission Reduction: </Text>
                  <QuestionHelper bkgOff={true} small={'s'} info={<>Equivalent amount in Tons of carbon emission reduction 
                    by offsetting the power consumption to mine the Bitcoin block #779089 .</>} />
                </RowFixed>
                <Text lineHeight={"32px"} fontWeight={700} color={theme.primary}> 
                  {amountCO2.toFixed(2)}{` TCO`}<sub>2</sub>
                </Text>
              </RowBetween>                   
              
              { (brickIDs.length !==0) && (
                  <div style={{borderBottom: `1px solid ${theme.primary5}`}}>
                    <RowBetween align="center" height='32px' style={{padding:'0rem 0.8rem'}}>
                      <RowFixed>
                        <Text fontWeight={500} color={theme.text2}> Greenized Cells: </Text>
                        <QuestionHelper bkgOff={true} small={'s'} info={<>Green BTC cell ID of the cells greenized by the actor.</>} />
                      </RowFixed>
                      <div />
                      <MouseoverTooltipNew disableHover={(brickIDs.length <= 3)} text={<>{brickIDsFull}</>}>
                        <Text lineHeight={"32px"} fontWeight={700} color={theme.primary}> 
                          {brickIDsHead} 
                        </Text>
                      </MouseoverTooltipNew>
                    </RowBetween>

                    <div style={{overflowY:'auto', overflowX: 'hidden', justifyContent:'center', margin: '-0.3rem 0.8rem 0.5rem', 
                              padding:'2px', height: '4.4rem', borderTopLeftRadius:'6px',borderBottomLeftRadius:'6px',
                              borderTopRightRadius: (brickIDs.length>21) ? '0px': '6px',  
                              borderBottomRightRadius: (brickIDs.length>21) ? '0px': '6px',
                              border: `1px solid ${theme.primary4}`}}>
                      <div>          
                      <SelectedCell mobile={below540} tight={true} style={{alignItems:'flex-start', paddingLeft:'8px'}}>
                          {brickIDs.map((id) => { return (
                            <Text fontWeight={200} fontSize={18} key={id} color={theme.text2}> <M>{id.toString().padStart(4,'0')}</M> </Text>
                          )})}
                      </SelectedCell> 
                      </div>
                    </div>
                  </div>
              )}  
            </div>

            <Text lineHeight={"32px"} fontWeight={700} color={theme.primary} style ={{padding:'0.3rem 0.8rem 0rem'}}> 
              Transaction details:          
            </Text>

            <div style={{backgroundColor: theme.bg2}}>    

              <RowBetween align="center" height='32px' style={{padding:'0rem 0.8rem', borderBottom: `1px solid ${theme.primary5}`}} >
                <RowFixed>
                  <Text fontWeight={500} color={theme.text2}> Green Action NFT ID: </Text>
                  <QuestionHelper bkgOff={true} small={'s'} info={<>The NFT ID of the action greenizing 
                                  Bitcoin block #779089.</>} />
                </RowFixed>
                <Text lineHeight={"32px"} fontWeight={700} color={theme.primary}> 
                  {actor.ESGID+1}
                </Text>
              </RowBetween>    

              <RowBetween align="center" height='32px' style={{padding:'0rem 0.8rem', borderBottom: `1px solid ${theme.primary5}`}} >
                <RowFixed>
                  <Text fontWeight={500} color={theme.text2}> Green Action Time: </Text>
                  <QuestionHelper bkgOff={true} small={'s'} info={<>The time taking the action to greenize BTC.</>} />
                </RowFixed>
                <Text lineHeight={"32px"} fontWeight={700} color={theme.primary}> 
                  {dateNow}
                </Text>
              </RowBetween>               

              { (actor.hashTrx) &&
                <RowBetween align="center" height='32px' style={{padding:'0rem 0.8rem', borderBottom: `1px solid ${theme.primary5}`}}>
                    <RowFixed>
                      <Text fontWeight={500} color={theme.text2}> Transaction on Chain:</Text>
                      <QuestionHelper bkgOff={true} small={'s'} info={<>The link to the transaction on Polygon blockchain.</>} />
                    </RowFixed>

                    <div>
                      <ExternalLink href= {linkHead + actor.hashTrx} style={{paddingRight:'6px'}}>
                          <LinkIcon size={16} color={theme.text2} />
                      </ExternalLink>
                      <MouseoverTooltip info={<>{actor.hashTrx}</>}>
                        <Text lineHeight={"32px"} fontWeight={700} color={theme.primary}> 
                          {shortenCID(actor.hashTrx, 8)}
                        </Text>
                      </MouseoverTooltip>
                    </div>
                </RowBetween>  
              } 
            </div> 

            <div style={{marginTop:'0.4rem', padding:'0.8rem'}}>  
              <BasicLink to={`/proof?id=${actor.ESGID+1}`} draggable='false'>
                <ButtonConfirmed width={"100%"} padding={"12px 0px"} style={{fontSize:20}} >
                  <LinkIcon size={18} style={{marginRight: '0.5rem', marginTop: '-0.4rem'}}/>
                    Proof of Green BTC
                  </ButtonConfirmed> 
              </BasicLink>
            </div>
        </AutoColumn>
      </Wrapper>
    )
  }

  export function BrickInfoModal({
    id,
    actor,
    isOpen,
    onDismiss,
  }: BrickInfoModalProps) {
 
    return (
      <Modal isOpen={isOpen} onDismiss={onDismiss} minHeight={false} maxHeight={90}>
        <BrickInfoContent id={id} actor={actor} Title ={'Green BTC Star '}  onDismiss={onDismiss} pendingText={'pendingText'}/>
      </Modal>
    )
  }
  
  export function BlockLayoutWrapped({ mobile, scaleMin, onMouseLeave, onMouseEnter, children }: { 
                            mobile: boolean,
                            scaleMin: number
                            onMouseLeave: ()=>void,
                            onMouseEnter: ()=>void,
                            children: ReactNode }) 
  {
      const divRef = useRef<HTMLDivElement>()
      const [scale, setScale] = useState<number>(1.0)
      const [twoTouch, setTwoTouch] = useState<boolean>(false)
      const [distanceTouch, setDistanceTouch] = useState<number>(0)
    
      useEffect(() => {
        const curRef = divRef?.current
    
        function handleWheel(e: any) {
          if ((e.deltaY < 0) && (scale < 9.5)) {
            setScale(scale * 1.1)
          }
          if ((e.deltaY > 0) && (scale >= 1.08)) {
            setScale(scale / 1.1)
          }
    
          if (e.preventDefault) {
            e.preventDefault()
            e.stopPropagation()
          } 
          return false
        }

        function handleTouchStart(ev: any) {
          if(twoTouch) {
            ev.preventDefault()
            ev.stopPropagation()
          }
    
          if ((ev.touches.length === 2) && (!twoTouch)) {
            setTwoTouch(true)
            const distance = (ev.touches[1].clientX - ev.touches[0].clientX)**2 + (ev.touches[1].clientY - ev.touches[0].clientY) ** 2
            setDistanceTouch(distance)
          }
        }
    
        function handleTouchMove(ev: any) {
          if(twoTouch) { 
            ev.preventDefault()
            ev.stopPropagation()
            if (ev.touches.length === 2) {
              const distanceNew = (ev.touches[1].clientX - ev.touches[0].clientX)**2 +
                                  (ev.touches[1].clientY - ev.touches[0].clientY)**2
                
              if(distanceNew > distanceTouch * 1.3) {
                if (scale < 9.5) {
                  (scale < 3) ? setScale(scale*1.1) : setScale(scale*1.1)
                  setDistanceTouch(distanceNew)
                }
              } else if(distanceNew < distanceTouch * 0.8) {
                if (scale > scaleMin) {                              // 1.08
                  (scale < 3) ? setScale(scale/1.1) : setScale(scale/1.1)
                  setDistanceTouch(distanceNew)
                }
              }
            }
          }
        }
    
        function handleTouchEnd(ev: any) {
          if(twoTouch) { 
            if (ev.targetTouches.length === 0){
              setTwoTouch(false)
            }
          }
        }
        
        curRef?.addEventListener('wheel', handleWheel, { passive: false })
        curRef?.addEventListener("touchstart", handleTouchStart, {passive: false});
        curRef?.addEventListener("touchmove", handleTouchMove);
        curRef?.addEventListener("touchend", handleTouchEnd);
    
        return ()=> {
          curRef?.removeEventListener('wheel', handleWheel)
          curRef?.removeEventListener('touchstart', handleTouchStart)   
          curRef?.removeEventListener('touchmove', handleTouchMove)   
          curRef?.removeEventListener('touchend', handleTouchEnd)   
        }
      }, [twoTouch, scale, scaleMin, distanceTouch])

    return (
        <BlockLayout ref={divRef as any} scale={scale} mobile={mobile} onMouseLeave = {onMouseLeave} onMouseEnter={onMouseEnter}>
          {children}
        </BlockLayout>
    )
  }
  
export const SoldInfo = styled.div`
  display: grid;
  grid-template-columns: 4fr 1fr;
`

export default function GreenBTC() {

  const { account, chainId, library } = useActiveWeb3React()

  const theme = useContext(ThemeContext)
  const below540 = useMedia('(max-width: 800px)')  

  //const history = useHistory()

  // toggle wallet when disconnected
  const toggleWalletModal = useWalletModalToggle()
  const ref = useRef<HTMLDivElement>()

//  const maxBrickNumber = useMaxBrickNumber()


  const saverGreenBTCTokenInfo = useGreenBTCTokenSaver()
  const saverGreenBTCTokenInfoMVP = useGreenBTCTokenMVPSaver()
  
  const clearGreenBTCTokenInfo = useGreenBTCTokenInfoClearer()
  
  const hashKeyESGContract = useHashKeyESGContract(true)
  const arkreenTokenContract = useArkreenTokenContract(true)
  const HARTTokenAddress = useHashKeyARECTokenAddress()
  const HARTBankContract = useHARTBankContract(false)
  
  const deadline = useTransactionDeadline()         // custom from users settings
  const  [ttl] = useUserTransactionTTL()

  const WORK_NET =  (!account || !chainId) ?  ChainId.MATIC : chainId
  const Usdc = USDC[WORK_NET]
  const Usdt = USDT[WORK_NET]
  const MATIC = WETH[WORK_NET].address

  const [simulationMode, toggleSimulationMode] = useSimulationMode()
  const { totalTokens, bricksOwners, levels, allBricks, allBricksMVP, 
          allTRXHash, timeTrx, allBlockHeight} = useGetGreenBTCInfo(WORK_NET)
  const numGreenBTCNFT = useTotalSupply()

  const [cellStatus, setCellStatus] = useState<number[]>(new Array(maxBrickNumber).fill(-1))
  const [cellGrey, setCellGrey] = useState<number[]>(new Array(maxBrickNumber).fill(-1))
 
  useEffect(() => {
      async function updateGreenBTCTokenInfo() {
        if(!chainId || !numGreenBTCNFT || (numGreenBTCNFT===0) || !hashKeyESGContract) return 
        
        if(numGreenBTCNFT === totalTokens) return

        console.log("QQQQQQQQQQ", totalTokens, numGreenBTCNFT)

        let start = 0
        if( (totalTokens !== undefined) && (totalTokens > numGreenBTCNFT) ) {       // Only happen in test phase
          clearGreenBTCTokenInfo(chainId)
        } else {
          start = totalTokens ?? 0
        }
        const { totalTokens: totalTokensNew, owners, allBricks} = await hashKeyESGContract.getAllBrickIDs(start+1, 0)

        const allTrxInfo = await Promise.all( (owners as string[]).map( async( _, index) => {
          return await fetchGBTCTrxInfo(chainId, index + start +1)

        }))

        const flagMVP = BigNumber.from(1).shl(255)
        let setMVP = false
        const brickIdsMVP = await Promise.all( (allBricks as BigNumber[]).map( async(brickIDs, index) => {
            if(brickIDs.and(flagMVP).isZero()) {return undefined}
            else {
              setMVP = true
              return (await hashKeyESGContract.getMVPBlocks(start + index + 1)) as BigNumber[]
            }
        }) )

        if( (totalTokensNew as BigNumber).eq(numGreenBTCNFT)) {
          setCellGrey(new Array(maxBrickNumber).fill(-1))
          saverGreenBTCTokenInfo(chainId, owners, allBricks, allTrxInfo)
          if(setMVP) { 
            saverGreenBTCTokenInfoMVP(chainId, start, brickIdsMVP)
          }
        }
      }

      if(!simulationMode) toggleSimulationMode()
      updateGreenBTCTokenInfo()

  }, [chainId, simulationMode, toggleSimulationMode, numGreenBTCNFT, totalTokens, 
      hashKeyESGContract, clearGreenBTCTokenInfo, saverGreenBTCTokenInfo, saverGreenBTCTokenInfoMVP])

  const [signatureData, setSignatureData] = useState<{ v: number; r: string; s: string; deadline: number } | null>(null)
  const [numToBuy, setNumToBuy] = useState<number>(0)

  const setCellNumberToBuy = useCallback( (cellNumber: number) => {
    setNumToBuy(cellNumber)
  },[setNumToBuy])

  const allBrickInfo: BrickInfo[] | undefined = useMemo(() => {
      if (!maxBrickNumber || !totalTokens || !allBricks || !bricksOwners || !levels || !allBricksMVP) return undefined

      let allBrickInfo: BrickInfo[] = new Array<BrickInfo>(maxBrickNumber);
      allBricks.forEach((brickIDs, index) => {
        let arrayBrickIDs = [brickIDs]

        if (allBricksMVP[index] !== undefined) {
          arrayBrickIDs = arrayBrickIDs.concat(allBricksMVP[index] as BigNumber[])
        }

        for(let ind=0; ind<arrayBrickIDs.length; ind++) {
          let tempBrickIDs = arrayBrickIDs[ind]
          while (!tempBrickIDs.isZero()) {
            let brickID = tempBrickIDs.and('0xFFF').toNumber() - 1
            allBrickInfo[brickID] = { sold: true, owner: bricksOwners[index], ESGID:index, numCell: levels[index] }
            tempBrickIDs = tempBrickIDs.shr(12)
          }
        }
      })
      return allBrickInfo

    },[totalTokens, bricksOwners, levels, allBricks, allBricksMVP])

  const paymentToken: string[] = ['USDC', 'MATIC']
    
  const { placedOK, CellMatrix } = usePlaceCells(Math.floor(numToBuy/10), allBrickInfo)

  const [tokenPay, setTokenPay] = useState('USDC')

  const payTokenAddress: string = useMemo(()=>{
    if(!arkreenTokenContract || !chainId) return Usdc.address
    if (tokenPay==='USDT') return Usdt.address
    if (tokenPay==='USDC') return Usdc.address
    if (tokenPay==='AKRE') return arkreenTokenContract.address
    return WETH[chainId].address
  },[tokenPay, Usdt, Usdc, arkreenTokenContract, chainId])

  const paymentContract = usePermitTokenContract(payTokenAddress)

  const [cellInfoID, setCellInfoID] = useState<number>(-1)    // -1: close the brick info modal
  const [addressActor, setAddressActor] = useState<string>('')    

  const handleConfirmDismiss = useCallback(() => {
    setCellInfoID(-1)
  }, [])

  const cellSelected = cellStatus.filter((idValue) => idValue!==(-1))

  const clearAllCell = useCallback(() => {
    let newCellStatus = new Array<number>(maxBrickNumber).fill(-1)
    setCellGrey(cellStatus)
    setCellStatus(newCellStatus)
  },[cellStatus, setCellStatus])

  const toggleCell = useCallback((id: number) => {
    let newCellStatus = [...cellStatus]
    if(cellGrey[id]===id) return              // Grey cell cannot be selected
    if ( allBrickInfo && allBrickInfo[id] && allBrickInfo[id].sold ) {
      setCellInfoID(id)
      setAddressActor(allBrickInfo[id].owner)
      return
    } 

    newCellStatus[id] = newCellStatus[id]===(-1) ?  id : (-1)
    setCellStatus(newCellStatus)
  },[cellStatus, cellGrey, allBrickInfo])       // cellSelected

  useEffect(()=>{
    if( Math.floor(numToBuy/10) ===0) {
      let newCellStatus = new Array<number>(maxBrickNumber).fill(-1)
      setCellStatus(newCellStatus)
    }

    if(!placedOK || !CellMatrix) return

    let newCellStatus = new Array<number>(maxBrickNumber).fill(-1)
    for(let index=0; index<CellMatrix.length; index++) {
      let position = CellMatrix[index]
      newCellStatus[position] = position
    }
    setCellStatus(newCellStatus)
  },[numToBuy, placedOK, CellMatrix])

  const cellToHide = useMemo(() => {
    let cellToHide: boolean[]
    cellToHide = new Array<boolean>(maxBrickNumber).fill(false)
    if(chainId === ChainId.MATIC_TESTNET) {
  
      for(let index=0; index<7; index++) cellToHide[251+index] = true
      for(let index=0; index<8; index++) cellToHide[330+index] = true

      for(let index=0; index<11; index++) cellToHide[539+index] = true
      for(let index=0; index<12; index++) cellToHide[618+index] = true
      for(let index=0; index<12; index++) cellToHide[698+index] = true
      for(let index=0; index<12; index++) cellToHide[778+index] = true

  //    for(let index=0; index<15; index++) cellToHide[509+index] = true
  //    for(let index=0; index<16; index++) cellToHide[588+index] = true
  //    for(let index=0; index<16; index++) cellToHide[668+index] = true

      for(let index=0; index<10; index++) cellToHide[515+index] = true
      for(let index=0; index<11; index++) cellToHide[594+index] = true
      for(let index=0; index<11; index++) cellToHide[674+index] = true
      for(let index=0; index<11; index++) cellToHide[754+index] = true
      for(let index=0; index<11; index++) cellToHide[834+index] = true

      for(let index=0; index<13; index++) cellToHide[1081+index] = true
      for(let index=0; index<14; index++) cellToHide[1160+index] = true
      for(let index=0; index<14; index++) cellToHide[1240+index] = true
      for(let index=0; index<14; index++) cellToHide[1320+index] = true

      for(let index=0; index<14; index++) cellToHide[573+index] = true
      for(let index=0; index<15; index++) cellToHide[652+index] = true
      for(let index=0; index<15; index++) cellToHide[732+index] = true

      for(let index=0; index<9; index++) cellToHide[1615+index] = true
      for(let index=0; index<10; index++) cellToHide[1694+index] = true
      for(let index=0; index<10; index++) cellToHide[1774+index] = true

      for(let index=0; index<12; index++) cellToHide[1873+index] = true
      for(let index=0; index<13; index++) cellToHide[1952+index] = true
      for(let index=0; index<13; index++) cellToHide[2032+index] = true
      for(let index=0; index<13; index++) cellToHide[2112+index] = true

      for(let index=0; index<7; index++) cellToHide[1181+index] = true
      for(let index=0; index<8; index++) cellToHide[1260+index] = true
      for(let index=0; index<8; index++) cellToHide[1340+index] = true
      for(let index=0; index<8; index++) cellToHide[1420+index] = true  
      for(let index=0; index<8; index++) cellToHide[1500+index] = true
      for(let index=0; index<8; index++) cellToHide[1580+index] = true    
      for(let index=0; index<8; index++) cellToHide[1660+index] = true    

      for(let index=0; index<11; index++) cellToHide[2009+index] = true
      for(let index=0; index<12; index++) cellToHide[2088+index] = true
      for(let index=0; index<12; index++) cellToHide[2168+index] = true
    } else {
      for(let index=0; index<10; index++) cellToHide[251+index] = true
      for(let index=0; index<11; index++) cellToHide[330+index] = true
      for(let index=0; index<11; index++) cellToHide[410+index] = true

      for(let index=0; index<11; index++) cellToHide[515+index] = true
      for(let index=0; index<12; index++) cellToHide[594+index] = true
      for(let index=0; index<12; index++) cellToHide[674+index] = true
      for(let index=0; index<12; index++) cellToHide[754+index] = true

      for(let index=0; index<10; index++) cellToHide[459+index] = true
      for(let index=0; index<11; index++) cellToHide[538+index] = true
      for(let index=0; index<11; index++) cellToHide[618+index] = true
      for(let index=0; index<11; index++) cellToHide[698+index] = true
      for(let index=0; index<11; index++) cellToHide[778+index] = true

      for(let index=0; index<14; index++) cellToHide[654+index] = true
      for(let index=0; index<15; index++) cellToHide[733+index] = true
      for(let index=0; index<15; index++) cellToHide[813+index] = true
      for(let index=0; index<15; index++) cellToHide[893+index] = true

      for(let index=0; index<15; index++) cellToHide[2009+index] = true
      for(let index=0; index<16; index++) cellToHide[2088+index] = true
      for(let index=0; index<16; index++) cellToHide[2168+index] = true
      for(let index=0; index<16; index++) cellToHide[2248+index] = true

      for(let index=0; index<13; index++) cellToHide[1296+index] = true
      for(let index=0; index<14; index++) cellToHide[1375+index] = true
      for(let index=0; index<14; index++) cellToHide[1455+index] = true
      for(let index=0; index<14; index++) cellToHide[1535+index] = true
      for(let index=0; index<14; index++) cellToHide[1615+index] = true

      for(let index=0; index<8; index++) cellToHide[1183+index] = true
      for(let index=0; index<9; index++) cellToHide[1262+index] = true
      for(let index=0; index<9; index++) cellToHide[1342+index] = true
      for(let index=0; index<9; index++) cellToHide[1422+index] = true  
      for(let index=0; index<9; index++) cellToHide[1502+index] = true
      for(let index=0; index<9; index++) cellToHide[1582+index] = true    
      for(let index=0; index<9; index++) cellToHide[1662+index] = true   

      for(let index=0; index<13; index++) cellToHide[1873+index] = true
      for(let index=0; index<14; index++) cellToHide[1952+index] = true
      for(let index=0; index<14; index++) cellToHide[2032+index] = true
      for(let index=0; index<14; index++) cellToHide[2112+index] = true   
      
      for(let index=0; index<16; index++) cellToHide[1079+index] = true
      for(let index=0; index<17; index++) cellToHide[1158+index] = true
      for(let index=0; index<17; index++) cellToHide[1238+index] = true
      for(let index=0; index<17; index++) cellToHide[1318+index] = true   
      for(let index=0; index<17; index++) cellToHide[1398+index] = true     
      
      for(let index=0; index<12; index++) cellToHide[60+index] = true
      for(let index=0; index<13; index++) cellToHide[139+index] = true
      for(let index=0; index<13; index++) cellToHide[219+index] = true
      for(let index=0; index<13; index++) cellToHide[299+index] = true   
 
    }
    
    return cellToHide
  },[chainId])

  const [ outCells, setOutCells] = useState(false)

  const updateInfo = useCallback( () => { 
    setOutCells(true)
    if ( ref.current) {
      ref.current.innerHTML = "👍"
    }
  },[setOutCells])

  const enterCells = () => { 
    setOutCells(false)
  }

  const [payAmount, setPayAmount] = useState<string>('0') 
  const rouerDex = useFeswRouterContract()
  const paymentCurrency = useCurrency(!chainId? 'MATIC': (payTokenAddress===WETH[chainId].address) ? 'MATIC':payTokenAddress)
  
  const [ getAmountInputStatus, setGetAmountInputStatus] = useState<string>('')
  const payByBankOrDex = true                         // true: Bank; false: Dex
  
  const [priceMATIC, setPriceMATIC] = useState<BigNumber|undefined>()
  const [priceUSDC, setPriceUSDC]   = useState<BigNumber|undefined>()
  const [priceUSDT, setPriceUSDT]   = useState<BigNumber|undefined>()
  const [priceAKRE, setPriceAKRE]   = useState<BigNumber|undefined>()    

  useEffect(()=>{
    const getSalesPrice = async() =>{
      if(!HARTBankContract) return
      while(true) {
        try{
          const [priceForSale, ] = await HARTBankContract.saleIncome(HARTTokenAddress, MATIC)
          setPriceMATIC(priceForSale)
          break
        } catch(error){
          console.log("saleIncome Error", error, HARTTokenAddress, MATIC)
          continue
        }
      }
    }
    if(!account) return 
    getSalesPrice()
  }, [account, HARTTokenAddress, HARTBankContract, MATIC])

  useEffect(()=>{
    const getSalesPrice = async() =>{
      if(!HARTBankContract) return
      while(true) {
        try{
          const [priceForSale, ] = await HARTBankContract.saleIncome(HARTTokenAddress, Usdc.address)
          setPriceUSDC(priceForSale)
          break          
        } catch(error){
          console.log("saleIncome Error", error, HARTTokenAddress, Usdc.address)
          continue
        }
      }
    }
    if(!account) return 
    getSalesPrice()
  }, [account, HARTTokenAddress, HARTBankContract, Usdc.address])

  useEffect(()=>{
    const getSalesPrice = async() =>{
      if(!HARTBankContract) return
      while(true) {
        try{
          const [priceForSale, ] = await HARTBankContract.saleIncome(HARTTokenAddress, Usdt.address)
          setPriceUSDT(priceForSale)
          break
        } catch(error){
          console.log("saleIncome Error", error, HARTTokenAddress, Usdt.address)
          continue        
        }
      }
    }
    if(!account) return 
    getSalesPrice()
  }, [account, HARTTokenAddress, HARTBankContract, Usdt.address])

  useEffect(()=>{
    const getSalesPrice = async() =>{
      if(!account || !HARTBankContract || !arkreenTokenContract) return 
      while(true) {
        try{
          const [priceForSale, ] = await HARTBankContract.saleIncome(HARTTokenAddress, arkreenTokenContract.address)
          setPriceAKRE(priceForSale)
          break
        } catch(error){
          console.log("saleIncome Error", error, HARTTokenAddress, arkreenTokenContract.address)
          continue
        }
      }
    }
    getSalesPrice()
  }, [account, HARTTokenAddress, HARTBankContract, arkreenTokenContract])

  useEffect(() => {
    const getAmountInput = async(payTokenAddress: string, rouerDex: Contract, HARTBankContract: Contract) => {
      if(!arkreenTokenContract) return 

      const amountART = BigNumber.from(2).mul(cellSelected.length)   // ART decimal is 9, 1 Cell -> 2 HART
      const path = [payTokenAddress, HARTTokenAddress]
      try {
        if(payByBankOrDex) {
//          const [price, ] = await HARTBankContract.saleIncome(HARTTokenAddress, payTokenAddress)
          let price: BigNumber | undefined
          if(payTokenAddress === MATIC) price = priceMATIC
          else if (payTokenAddress === Usdc.address) price = priceUSDC
          else if (payTokenAddress === Usdt.address) price = priceUSDT
          else if (payTokenAddress === arkreenTokenContract.address) price = priceAKRE
          if(!price) return    

          const amountIn = amountART.mul(price)
          setPayAmount(amountIn.toString())              
          setGetAmountInputStatus('')
        } else {
          const amountIn: BigNumber[] = await rouerDex.estimateAmountsIn(amountART, path)
          // setPayAmount(amountIn[0].toString())                                // 
          setPayAmount(amountIn[0].mul(110).div(100).toString())              // add 10% as Buffer
          setGetAmountInputStatus('')
        }
      } catch(error){
          setGetAmountInputStatus('Error to get input amount!')
          console.log("getAmountInput Error", error, HARTBankContract.address, HARTTokenAddress, payTokenAddress, 
                        rouerDex.address, amountART.toString(), cellSelected.length)
      }
    }

    if (!account || !rouerDex || !chainId || !HARTBankContract) return
    if (cellSelected.length === 0) {
      setPayAmount('0')
      return
    }
    getAmountInput(payTokenAddress, rouerDex, HARTBankContract) 

  },[account, payTokenAddress, HARTTokenAddress, cellSelected, payByBankOrDex, rouerDex, HARTBankContract, setPayAmount, chainId, 
     MATIC, Usdc, Usdt, arkreenTokenContract, priceAKRE, priceMATIC, priceUSDC, priceUSDT])

  const paymentAmount: CurrencyAmount = useMemo (()=>{
    return paymentCurrency instanceof Token
            ? new TokenAmount(paymentCurrency, JSBI.BigInt(payAmount.toString()))
            : CurrencyAmount.ether(JSBI.BigInt(payAmount.toString()))

  },[paymentCurrency, payAmount])

  const paymentAmountString =useMemo(() => {
    if(cellSelected.length === 0) return ''
    if(!paymentAmount.equalTo('0')) return (paymentAmount.toFixed(4) + ' ' + tokenPay)
    return ''
  },[paymentAmount, tokenPay, cellSelected])

  const [approval, approveCallback] = useApproveCallback(paymentAmount, hashKeyESGContract?.address)

  // check if user has gone through approval process, used to show two step buttons, reset on token change
  const [approvalSubmitted, setApprovalSubmitted] = useState<boolean>(false)

  // mark when a user has submitted an approval, reset onTokenSelection for input field
  useEffect(() => {
    if (approval === ApprovalState.PENDING) {
      setApprovalSubmitted(true)

    }
  }, [approval, approvalSubmitted])

  const showApproveFlow = (cellSelected.length !==0 ) &&
                          ( approval === ApprovalState.NOT_APPROVED ||
                            approval === ApprovalState.PENDING ||
                            (approvalSubmitted && approval === ApprovalState.APPROVED) )
                            
  const [isApproving, setIsApproving] = useState<boolean>(false)

  const [ownerToLink, setOwnerToLink] = useState<number>(-1)     
  
  const setOwnerToLinkById = useCallback( (idBrick: number) => {
    if(!allBrickInfo || !allBrickInfo[idBrick]) return
    setOwnerToLink(allBrickInfo[idBrick].ESGID)
  },[allBrickInfo])

  const removeOwnerToLinkById = useCallback( () => {
    setOwnerToLink(-1)
  },[])

  const idlistLinked: number[] | undefined = useMemo(() => {
    if(ownerToLink===(-1) || !allBricks || !allBricksMVP) return undefined
    let idlistLinked: number[] = []

    let allBrickID = allBricks[ownerToLink]
    while (!allBrickID.isZero()) {
      idlistLinked.push(allBrickID.and("0xFFF").toNumber())
      allBrickID = allBrickID.shr(12)
    }

    let allBrickIDMVP = allBricksMVP[ownerToLink]
    if (allBrickIDMVP !== undefined) {
      for (let index = 0; index < allBrickIDMVP.length; index++) { 
        let allBrickID = allBrickIDMVP[index]
        while (!allBrickID.isZero()) {
          idlistLinked.push(allBrickID.and("0xFFF").toNumber())
          allBrickID = allBrickID.shr(12)
        }
      }
    }

    return idlistLinked
  },[ownerToLink, allBricks, allBricksMVP])

  const addTransaction = useTransactionAdder()

  const onTokenSelect = useCallback( (token) => {
    setTokenPay(token.target.value)
    setSignatureData(null)
    setPayAmount('0')
  },[setTokenPay])


  const [{ showConfirm, txnToConfirm, attemptingTxn, errorMessage, txHash }, setARECTxnState] = useState<{
    showConfirm: boolean
    txnToConfirm: RECRequest | undefined
    attemptingTxn: boolean
    errorMessage: string | undefined
    txHash: string | undefined
  }>({
    showConfirm: false,
    txnToConfirm: undefined,
    attemptingTxn: false,
    errorMessage: undefined,
    txHash: undefined
  })

  async function handleClearErrorMessage() {
    setARECTxnState({ attemptingTxn, txnToConfirm, showConfirm, errorMessage: undefined, txHash })
  }

//  async function onAttemptToApproveUSDC(tokenPay: Contract|null, spender?: string, amount?: BigNumber) {
  async function onAttemptToApprove() {

    if (!paymentContract || !hashKeyESGContract || !library || !deadline || !ttl || !chainId) throw new Error('missing dependencies')
    if (tokenPay === 'MATIC') return

    //    if (!approvalAmount) throw new Error('missing liquidity amount')
    
    // try to gather a signature for permission
    setIsApproving(true)
    let nonce
    let name
    try {
      nonce = await paymentContract.nonces(account)
      name = await paymentContract.name()
    } catch(error) {
      setIsApproving(false)
        console.log("Permit Error: ", error)
        await approveCallback()
        return
    }

    let EIP712Domain
    let domain
    if (tokenPay === 'USDC') {
      EIP712Domain = [
        { name: 'name', type: 'string' },
        { name: 'version', type: 'string' },
        { name: 'verifyingContract', type: 'address' },
        { name: 'salt', type: 'bytes32' },
      ]
      domain = {
        name:     name,
        version:  '1',
        verifyingContract: paymentContract.address,
        salt:  utils.hexZeroPad(utils.hexlify(chainId), 32) 
      }
    } else {
      EIP712Domain = [
        { name: 'name', type: 'string' },
        { name: 'version', type: 'string' },
        { name: 'chainId', type: 'uint256' },
        { name: 'verifyingContract', type: 'address' }
      ]
      domain = {
        name:     name,
        version:  '1',
        chainId:  chainId,
        verifyingContract: paymentContract.address
      }
    }

    const Permit = [
      { name: 'owner',    type: 'address' },
      { name: 'spender',  type: 'address' },
      { name: 'value',    type: 'uint256' },
      { name: 'nonce',    type: 'uint256' },
      { name: 'deadline', type: 'uint256' }
    ]

    const nowDeadline = Math.floor(DateTime.now().toSeconds() + ttl)
    const usedDeadline = deadline.lt(nowDeadline) ? nowDeadline : deadline.toNumber()

    const message = {
      owner:    account,
      spender:  hashKeyESGContract.address,
      value:    paymentAmount.raw.toString(),
      nonce:    nonce.toHexString(),
      deadline: usedDeadline
    }
    const data = JSON.stringify({
      types: {
        EIP712Domain,
        Permit
      },
      domain,
      primaryType: 'Permit',
      message
    })

    library
      .send('eth_signTypedData_v4', [account, data])
      .then(splitSignature)
      .then(signature => {
        setIsApproving(false)
        setSignatureData({
          v: signature.v,
          r: signature.r,
          s: signature.s,
          deadline: usedDeadline
        })
        console.log("setSignatureData", paymentContract.address, hashKeyESGContract.address, paymentAmount.raw.toString(),
                                        signature.v, signature.r, signature.s, usedDeadline)
      })
      .catch(error => {
        setIsApproving(false)
        // for all errors other than 4001 (EIP-1193 user rejected request), fall back to manual approve
        if (error?.code !== 4001) {
          console.log("Permit Error: ", error)
          approveCallback()
        }
      })
  }

  async function greenizeBTC() {

    if(!hashKeyESGContract || !chainId) return

    const useBankOrDex = true        // Force to use Bank

    let cellSelectedBase
    let cellSelectedMVP
    let bricksToGreenMVP: BigNumber[] | undefined

    if(cellSelected.length <= 21 ) {
      cellSelectedBase = cellSelected
    } else {
      cellSelectedBase = cellSelected.slice(0,21)
      cellSelectedMVP = cellSelected.slice(21)

      bricksToGreenMVP = new Array<BigNumber>(Math.ceil(cellSelectedMVP.length/21))
      for(let index=0; index<bricksToGreenMVP.length; index++) {
        let cellSelectedPart = cellSelectedMVP.slice(21*index, 21*(index+1)).map((id) => (id+1)).reverse()
        bricksToGreenMVP[index] =  cellSelectedPart.reduce<BigNumber>((bricksToGreen, blockId) => {
          return bricksToGreen.shl(12).add(BigNumber.from(blockId))
        },BigNumber.from(0))
      }
    } 

    const numBlocks = cellSelectedBase.map((id) => (id+1)).reverse()
    const bricksToGreenOrg: BigNumber =  numBlocks.reduce<BigNumber>((bricksToGreen, blockId) => {
      return bricksToGreen.shl(12).add(BigNumber.from(blockId))
    },BigNumber.from(0))

    const bricksToGreen = useBankOrDex ? bricksToGreenOrg.or(BigNumber.from(1).shl(255)) : bricksToGreenOrg 
//    const badgeInfo = [account, "Arkreen", "Arkreen Foundation", "Protect the Earth" ] 
//    const badgeInfo = [account, "Ebang", "Ebang", "Sustainable Bitcoin" ] 
//    const badgeInfo = [account, "Unipow", "Unipow", "Sustainable Bitcoin" ] 
//    const badgeInfo = [account, "", "", "Sustainable Bitcoin" ] 
//    const badgeInfo = [account, "iPollo", "iPollo Miner", "Sustainable Bitcoin" ] 
//    const badgeInfo = [account, "Cypher Capital", "Cypher Capital", "Sustainable Bitcoin" ] 
//    const badgeInfo = [account, "Easy2Mine", "Easy2Mine (E2M)", "Sustainable Bitcoin" ] 
//    const badgeInfo = [account, "Canaan", "Canaan", "Sustainable Bitcoin" ] 
//      const offsetMessage1 =  "On-site audience includes 514642729@qq.com, farmerrock@gmail.com, redemax333@gmail.com, " +
//                             "ttzwen@hotmail.com, lizakhho@gmail.com. " + 
//                             "Sponsored by HashKey Group, Bit Origin, Easy2Mine, Ebang, iPollo, Cypher Capital, " +
//                            "UNIPOW, Arkreen Network."

//      const offsetMessage2 =  "On-site audience includes 541417213@qq.com, yinngbh@gmail.com, " +
//                             "huangdaming1990@gmail.com, b1463912303@gmail.com, dd29fwyf@gmail.com. " + 
//                             "Sponsored by HashKey Group, Bit Origin, Easy2Mine, Ebang, iPollo, Cypher Capital, " +
//                             "UNIPOW, Arkreen Network."
//      const badgeInfo = [account, "Green BTC session on-site audience contribution", "Bitcoin Block Height #779089", offsetMessage2]

//                "On-site audience include 514642729@qq.com, farmerrock@gmail.com, redemax333@gmail.com, ttzwen@hotmail.com, 
//                lizakhho@gmail.com, 541417213@qq.com, yinngbh@gmail.com, huangdaming1990@
//                gmail.com, b1463912303@gmail.com, dd29fwyf@gmail.com, sponsored by HashKey Group, Bit Origin, Easy2Mine, Ebang,
//                iPollo, Cypher Capital, UNIPOW, Arkreen Network." ] 

//  const badgeInfo = [account, "HashKey Pro", "Bitcoin Block Height #779089", "Sustainable Bitcoin!" ]                              

//    zhang: 514642729@qq.com       // OK 0x2ede75766702f34b6157af1bc78db3e4cf60fba2 (15)
//    Martin: farmerrock@gmail.com  // OK
//    Vitalii: redemax333@gmail.com // OK
//    ttzwen:	ttzwen@hotmail.com    // OK: 0x4533c5E0271Ff4e1CF254a24F9590007FA18606D (18)
//    Zehao: lizakhho@gmail.com     // OK 0xe1dAdfd20bBCD8779D3cF29Eb07D6085Abd4b8Eb (19)
//    Maxine: 541417213@qq.com      // OK 0x08d2D1f482A17A2FA779a3D4eE4EF7776e2Ba452 (20)
//    BH: yinngbh@gmail.com         // OK
//    JEY: huangdaming1990@gmail.com  // OK 地址：0xC58207d24042771a7b7D5cB39e8504051262ad1C (22)
//    tommykw: b1463912303@gmail.com  // OK
//    waato: dd29fwyf@gmail.com       // OK 0x4f4e4247CCc329bdd68EB9f1419B234d50803c9e (24)


    const badgeInfo = [account, "waato: dd29fwyf@gmail.com", "Bitcoin Block Height #779089", "Sustainable Bitcoin!" ]      

    const paymentAmountValue = paymentAmount.raw.toString()

    if (!signatureData) {
      if(tokenPay ==='MATIC') {
        if ((!cellSelectedMVP) || (!bricksToGreenMVP)) {
          setARECTxnState({ attemptingTxn: true, txnToConfirm, showConfirm, errorMessage: undefined, txHash: undefined })
          await hashKeyESGContract.estimateGas['greenizeBTCNative']( bricksToGreen, MaxUint256, badgeInfo, {value: paymentAmountValue})
            .then(async(estimatedGasLimit) => {
              await hashKeyESGContract.greenizeBTCNative( bricksToGreen, MaxUint256, badgeInfo,
                                                              { gasLimit: calculateGasMargin(estimatedGasLimit),
                                                                value: paymentAmountValue })
              .then(async (response: TransactionResponse) => {
                clearAllCell()
                setSignatureData(null)
                addTransaction(response, {
                  summary: `Greenize BTC by ${paymentAmount.toFixed(3)} ${tokenPay}`
                })
                setARECTxnState({ attemptingTxn: false, txnToConfirm, showConfirm, errorMessage: undefined, txHash: response.hash })
              })
              .catch((error: any) => {
                // if the user rejected the tx, pass this along
                if (error?.code === 4001) {
                  throw new Error(`Greenize BTC failed: You denied transaction signature.`)
                } else {
                  // otherwise, the error was unexpected and we need to convey that
                  throw new Error(`Greenize BTC failed: ${error.message}`)
                }
              })
            })
            .catch((error: any) => {
              console.log("Greenize BTC failed:", error)
              const dataMsg = error?.data?.message ? ' Details: ' + error.data.message : ''
              setARECTxnState({attemptingTxn: false, txnToConfirm, showConfirm, errorMessage: error.message + dataMsg, txHash: undefined })
            })
        } else {
          setARECTxnState({ attemptingTxn: true, txnToConfirm, showConfirm, errorMessage: undefined, txHash: undefined })
          await hashKeyESGContract.estimateGas['greenizeBTCNativeMVP']( bricksToGreen, bricksToGreenMVP, MaxUint256, badgeInfo, {value: paymentAmountValue})
            .then(async(estimatedGasLimit) => {
              await hashKeyESGContract.greenizeBTCNativeMVP( bricksToGreen, bricksToGreenMVP, MaxUint256, badgeInfo,
                                                              { gasLimit: calculateGasMargin(estimatedGasLimit),
                                                                value: paymentAmountValue })
              .then(async (response: TransactionResponse) => {
                clearAllCell()
                setSignatureData(null)
                addTransaction(response, {
                  summary: `Greenize BTC by ${paymentAmount.toFixed(3)} ${tokenPay}`
                })
                setARECTxnState({ attemptingTxn: false, txnToConfirm, showConfirm, errorMessage: undefined, txHash: response.hash })
              })
              .catch((error: any) => {
                // if the user rejected the tx, pass this along
                if (error?.code === 4001) {
                  throw new Error(`Greenize BTC failed: You denied transaction signature.`)
                } else {
                  // otherwise, the error was unexpected and we need to convey that
                  throw new Error(`Greenize BTC failed: ${error.message}`)
                }
              })
            })
            .catch((error: any) => {
              console.log("Greenize BTC failed:", error)
              const dataMsg = error?.data?.message ? ' Details: ' + error.data.message : ''
              setARECTxnState({attemptingTxn: false, txnToConfirm, showConfirm, errorMessage: error.message + dataMsg, txHash: undefined })
            })
        }

      } else {
        if ((!cellSelectedMVP) || (!bricksToGreenMVP)) {
          setARECTxnState({ attemptingTxn: true, txnToConfirm, showConfirm, errorMessage: undefined, txHash: undefined })
          await hashKeyESGContract.estimateGas['greenizeBTC']( payTokenAddress, paymentAmountValue, bricksToGreen, MaxUint256, badgeInfo )
            .then(async(estimatedGasLimit) => {
              await hashKeyESGContract.greenizeBTC( payTokenAddress, paymentAmountValue, bricksToGreen, MaxUint256, badgeInfo,
                                                              { gasLimit: calculateGasMargin(estimatedGasLimit) })
              .then(async (response: TransactionResponse) => {
                clearAllCell()
                setSignatureData(null)
                addTransaction(response, {
                  summary: `Greenize BTC by ${paymentAmount.toFixed(3)} ${tokenPay}`
                })
                setARECTxnState({ attemptingTxn: false, txnToConfirm, showConfirm, errorMessage: undefined, txHash: response.hash })
              })
              .catch((error: any) => {
                // if the user rejected the tx, pass this along
                if (error?.code === 4001) {
                  throw new Error(`Greenize BTC failed: You denied transaction signature.`)
                } else {
                  // otherwise, the error was unexpected and we need to convey that
                  throw new Error(`Greenize BTC failed: ${error.message}`)
                }
              })
            })
            .catch((error: any) => {
              console.log("Greenize BTC Failed:", error)
              const dataMsg = error?.data?.message ? ' Details: ' + error.data.message : ''
              setARECTxnState({attemptingTxn: false, txnToConfirm, showConfirm, errorMessage: error.message + dataMsg, txHash: undefined })
            })
        } else {

          setARECTxnState({ attemptingTxn: true, txnToConfirm, showConfirm, errorMessage: undefined, txHash: undefined })
          await hashKeyESGContract.estimateGas['greenizeBTCMVP']( 
                                payTokenAddress, paymentAmountValue, bricksToGreen, bricksToGreenMVP, MaxUint256, badgeInfo )
            .then(async(estimatedGasLimit) => {
              await hashKeyESGContract.greenizeBTCMVP( 
                                payTokenAddress, paymentAmountValue, bricksToGreen, bricksToGreenMVP, MaxUint256, badgeInfo,
                                { gasLimit: calculateGasMargin(estimatedGasLimit) })
              .then(async (response: TransactionResponse) => {
                clearAllCell()
                setSignatureData(null)
                addTransaction(response, {
                  summary: `Greenize BTC-MVP by ${paymentAmount.toFixed(3)} ${tokenPay}`
                })
                setARECTxnState({ attemptingTxn: false, txnToConfirm, showConfirm, errorMessage: undefined, txHash: response.hash })
              })
              .catch((error: any) => {
                // if the user rejected the tx, pass this along
                if (error?.code === 4001) {
                  throw new Error(`Greenize BTC-MVP failed: You denied transaction signature.`)
                } else {
                  // otherwise, the error was unexpected and we need to convey that
                  throw new Error(`Greenize BTC-MVP failed: ${error.message}`)
                }
              })
            })
            .catch((error: any) => {
              console.log("Greenize BTC Failed:", error)
              const dataMsg = error?.data?.message ? ' Details: ' + error.data.message : ''
              setARECTxnState({attemptingTxn: false, txnToConfirm, showConfirm, errorMessage: error.message + dataMsg, txHash: undefined })
            })          
        }  

      }
    }
  
    if(!signatureData) return

    const signatureToPay = [ payTokenAddress, paymentAmountValue, signatureData.deadline,
                              signatureData.v, signatureData.r, signatureData.s]            
                        
    if ((!cellSelectedMVP) || (!bricksToGreenMVP)) {
      setARECTxnState({ attemptingTxn: true, txnToConfirm, showConfirm, errorMessage: undefined, txHash: undefined })
      await hashKeyESGContract.estimateGas['greenizeBTCPermit']( bricksToGreen, badgeInfo, signatureToPay)
        .then(async(estimatedGasLimit) => {
          await hashKeyESGContract.greenizeBTCPermit( bricksToGreen, badgeInfo, signatureToPay, 
                                                          { gasLimit: calculateGasMargin(estimatedGasLimit) })
          .then(async (response: TransactionResponse) => {
            clearAllCell()
            setSignatureData(null)
            addTransaction(response, {
              summary: `Greenize BTC by ${paymentAmount.toFixed(3)} ${tokenPay}`
            })
            setARECTxnState({ attemptingTxn: false, txnToConfirm, showConfirm, errorMessage: undefined, txHash: response.hash })
          })
          .catch((error: any) => {
            // if the user rejected the tx, pass this along
            if (error?.code === 4001) {
              throw new Error(`Greenize BTC failed: You denied transaction signature.`)
            } else {
              // otherwise, the error was unexpected and we need to convey that
              throw new Error(`Greenize BTC failed: ${error.message}`)
            }
          })
        })
        .catch((error: any) => {
          console.log("Greenize BTC failed:", error)
          const dataMsg = error?.data?.message ? ' Details: ' + error.data.message : ''
          setARECTxnState({attemptingTxn: false, txnToConfirm, showConfirm, errorMessage: error.message + dataMsg, txHash: undefined })
        })
    } else {
      setARECTxnState({ attemptingTxn: true, txnToConfirm, showConfirm, errorMessage: undefined, txHash: undefined })
      await hashKeyESGContract.estimateGas['greenizeBTCPermitMVP']( bricksToGreen, bricksToGreenMVP, badgeInfo, signatureToPay)
        .then(async(estimatedGasLimit) => {
          await hashKeyESGContract.greenizeBTCPermitMVP( bricksToGreen, bricksToGreenMVP, badgeInfo, signatureToPay, 
                                                          { gasLimit: calculateGasMargin(estimatedGasLimit) })
          .then(async (response: TransactionResponse) => {
            clearAllCell()
            setSignatureData(null)
            addTransaction(response, {
              summary: `Greenize BTC by ${paymentAmount.toFixed(3)} ${tokenPay}`
            })
            setARECTxnState({ attemptingTxn: false, txnToConfirm, showConfirm, errorMessage: undefined, txHash: response.hash })
          })
          .catch((error: any) => {
            // if the user rejected the tx, pass this along
            if (error?.code === 4001) {
              throw new Error(`Greenize BTC failed: You denied transaction signature.`)
            } else {
              // otherwise, the error was unexpected and we need to convey that
              throw new Error(`Greenize BTC failed: ${error.message}`)
            }
          })
        })
        .catch((error: any) => {
          console.log("Greenize BTC failed:", error)
          const dataMsg = error?.data?.message ? ' Details: ' + error.data.message : ''
          setARECTxnState({attemptingTxn: false, txnToConfirm, showConfirm, errorMessage: error.message + dataMsg, txHash: undefined })
        })
    }
  }

  const { width, height } = useWindowSize()

  const CellSize = useMemo<number|undefined>(()=>{
      if (!width) return undefined
      if(below540) return (width-8)
      return  width*0.96
  },[width, below540])

  const scaleMin = (CellSize === undefined) ? 1.0:  (CellSize/1440 >= 1.0) ? 1.0 : (CellSize/1440)

  const greenStar = useMemo<actorBrick|undefined>(() => {
    if(cellInfoID ===(-1) || !allBrickInfo || !allBricks || !allBlockHeight || 
                              !timeTrx || !allTRXHash || !allBricksMVP ) return undefined
    const ESG_ID = allBrickInfo[cellInfoID].ESGID                         
    return {
      id:             cellInfoID,
      ESGID:          ESG_ID,
      level:          allBrickInfo[cellInfoID].numCell,
      address:        addressActor,
      brickIDs:       allBricks[ESG_ID],
      brickIDsMVP:    allBricksMVP[ESG_ID],
      logo:           undefined,
      info:           undefined,
      blockHeight:    allBlockHeight[ESG_ID],
      timeTrx:        timeTrx[ESG_ID],
      hashTrx:        allTRXHash[ESG_ID],
      cellIDs:        []
    }

  },[cellInfoID, addressActor, allBrickInfo, allBricks, allBricksMVP, allTRXHash, timeTrx, allBlockHeight ])

//  totalTokens, bricksOwners, levels, allBricks, allBricksMVP, 
//          allTRXHash, timeTrx, allBlockHeight

//  const actorInfo = useActorInfo(11)
//  console.log("AAAAAAAAAAAAAA actorInfo", actorInfo)
  console.log("cellInfoID", cellInfoID, greenStar)
  
  return (
    <>
      <ErrorPromptModal isOpen={!!errorMessage} errString={errorMessage} onDismiss={handleClearErrorMessage} />
      { (cellInfoID !==(-1) && greenStar) && 
        ( <BrickInfoModal isOpen={cellInfoID !==(-1)} actor = { greenStar } 
            id = {cellInfoID} onDismiss={handleConfirmDismiss} /> )
      }

      { CellSize && height &&
        (<div style={{overflow: 'auto', maxWidth: CellSize }} >
          <BlockLayoutWrapped mobile={below540} scaleMin={scaleMin} onMouseLeave={() => {updateInfo()}} onMouseEnter={() => {enterCells()}} >
            {cellStatus.map((id, index) => { return (<ButtonCell info={ref} id={index} 
                                              noshow={cellToHide[index]}  mobile={below540} key={index} active = {id===index} 
                                              grey = {index===cellGrey[index]}
                                              sold = {allBrickInfo?.[index]?.sold ?? false} toggleCell={toggleCell}
                                              linked = {(ownerToLink === allBrickInfo?.[index]?.ESGID)}
                                              numCell = {allBrickInfo?.[index]?.numCell ?? 0 }
                                              setOwnerToLinkById = {setOwnerToLinkById} removeOwnerToLinkById = {removeOwnerToLinkById}> 
                                              { (chainId === ChainId.MATIC) 
                                                ? ( (index===250)
                                                    ? <div style={{backgroundColor:'#00923a', width:"196px", height:"52px", border:'0px'}}>
                                                        <img draggable='false' style={{borderRadius:'1px', backgroundSize:"52px 196px",
                                                                  height:'52px', zIndex: 999}} src={ArkreenLogo} alt="logo" />
                                                      </div>
                                                    : (index===514) 
                                                    ? <div style={{backgroundColor:'#16233b', width:"214px", height:"70px", border:'0px'}}>
                                                        <img draggable='false' style={{borderRadius:'1px', backgroundColor:'#16233b',
                                                                      height:'70px', zIndex: 999}} src={HashKeyLogo} alt="logo" />
                                                      </div>
                                                    : (index===458) 
                                                    ? <div style={{backgroundColor:'#00923a', width:"196px", height:"88px", border:'0px'}}>
                                                      <img draggable='false' style={{borderRadius:'1px', backgroundColor:'#00923a',stroke:'blue', padding: '4px',
                                                                  height:'88px', zIndex: 999}} src={BitOriginLogo} alt="logo" />
                                                      </div>
                                                    : (index===653) 
                                                    ? <div style={{backgroundColor:'#CCCCCC', width:"268px", height:"70px", border:'0px'}}>
                                                            <img draggable='false' style={{borderRadius:'1px', backgroundColor:'#CCCCCC',
                                                                      margin:'10px', height:'50px', zIndex: 999}} src={EBangLogo} alt="logo" />
                                                      </div>  
                                                    : (index===2008) 
                                                    ? <div style={{backgroundColor:'#000000', width:"286px", height:"70px", border:'0px', padding:'5px 3px', justifyContent:'center'}}>
                                                          <img draggable='false' style={{borderRadius:'1px',
                                                              height:'60px', zIndex: 999}} src={UniPowLogo} alt="logo" />
                                                      </div>   
                                                    : (index===1295) 
                                                    ? <div style={{backgroundColor:'#FFFFFF', width:"232px", height:"88px", border:'0px', justifyContent:'center'}}>
                                                            <img draggable='false' style={{borderRadius:'1px', backgroundColor:'#FFFFFF',
                                                                      height:'88px', zIndex: 999}} src={iPolloLogo} alt="logo" />
                                                      </div>   
                                                      
                                                    : (index===1182) 
                                                    ? <div style={{backgroundColor:'#00923a', width:"160px", height:"124px", border:'0px', justifyContent:'center'}}>
                                                            <img draggable='false' style={{borderRadius:'1px', backgroundColor:'#00923a',
                                                                      height:'124px', zIndex: 999}} src={CypherLogo} alt="logo" />
                                                      </div>    

                                                    : (index===1872) 
                                                    ? <div style={{backgroundColor:'#FFFFFF', width:"250px", height:"70px", border:'0px', justifyContent:'center'}}>
                                                            <img draggable='false' style={{borderRadius:'1px', backgroundColor:'#FFFFFF',
                                                                      height:'70px', zIndex: 999}} src={Easy2MineLogo} alt="logo" />
                                                      </div>    

                                                    : (index===1078) 
                                                    ? <div style={{backgroundColor:'#FFFFFF', width:"304px", height:"88px", border:'0px'}}>
                                                        <img draggable='false' style={{borderRadius:'1px', backgroundColor:'#FFFFFF', width:"304px",
                                                                  height:'88px', zIndex: 999}} src={iPolloALogo} alt="logo" />
                                                      </div>     
                                                    : (index===59) 
                                                    ? <div style={{backgroundColor:'#DDDDDD', width:"232px", height:"70px", border:'0px', padding:'3px 0px'}}>
                                                        <img draggable='false' style={{borderRadius:'1px', backgroundColor:'#DDDDDD',
                                                                  height:'64px', zIndex: 999}} src={HashKeyProLogo} alt="logo" />
                                                      </div>                                                          

                                                    :undefined 
                                                  )
                                                : ((index===250) 
                                                ? <div style={{backgroundColor:'#00923a', width:"142px", height:"34px", border:'0px'}}>
                                                    <img draggable='false' style={{borderRadius:'1px', backgroundSize:"34px 142px",
                                                                 height:'34px', zIndex: 999}} src={ArkreenLogo} alt="logo" />
                                                  </div>
                                                : (index===538) 
                                                  ? <div style={{backgroundColor:'#16233b', width:"214px", height:"70px", border:'0px'}}>
                                                      <img draggable='false' style={{borderRadius:'1px', backgroundColor:'#16233b',
                                                                    height:'70px', zIndex: 999}} src={HashKeyLogo} alt="logo" />
                                                    </div>
                                                  : (index===514) 
                                                    ? <div style={{backgroundColor:'#00923a', width:"196px", height:"88px", border:'0px'}}>
                                                        <img draggable='false' style={{borderRadius:'1px', backgroundColor:'#00923a',stroke:'blue', padding: '4px',
                                                                    height:'88px', zIndex: 999}} src={BitOriginLogo} alt="logo" />
                                                      </div>
                                                    : (index===1080) 
                                                      ? <div style={{backgroundColor:'#CCCCCC', width:"250px", height:"70px", border:'0px'}}>
                                                          <img draggable='false' style={{borderRadius:'1px', backgroundColor:'#CCCCCC',
                                                                    height:'70px', zIndex: 999}} src={CanaanLogo} alt="logo" />
                                                        </div>     
                                                      : (index===572) 
                                                      ? <div style={{backgroundColor:'#CCCCCC', width:"268px", height:"52px", border:'0px'}}>
                                                            <img draggable='false' style={{borderRadius:'1px', backgroundColor:'#CCCCCC',
                                                                      margin:'5px', height:'42px', zIndex: 999}} src={EBangLogo} alt="logo" />
                                                          </div>                                                         
                                                      
                                                      : (index===1614) 
                                                      ? <div style={{backgroundColor:'#FFFFFF', width:"178px", height:"52px", border:'0px', justifyContent:'center'}}>
                                                            <img draggable='false' style={{borderRadius:'1px', backgroundColor:'#FFFFFF',
                                                                      height:'52px', zIndex: 999}} src={iPolloLogo} alt="logo" />
                                                        </div>                                                         
                                                      
                                                      : (index===1872) 
                                                      ? <div style={{backgroundColor:'#FFFFFF', width:"232px", height:"70px", border:'0px', justifyContent:'center'}}>
                                                            <img draggable='false' style={{borderRadius:'1px', backgroundColor:'#FFFFFF',
                                                                      height:'70px', zIndex: 999}} src={Easy2MineLogo} alt="logo" />
                                                        </div>    

                                                      : (index===1180) 
                                                      ? <div style={{backgroundColor:'#00923a', width:"142px", height:"124px", border:'0px', justifyContent:'center'}}>
                                                            <img draggable='false' style={{borderRadius:'1px', backgroundColor:'#00923a',
                                                                      height:'124px', zIndex: 999}} src={CypherLogo} alt="logo" />
                                                        </div>    

                                                      : (index===2008) 
                                                      ? <div style={{backgroundColor:'#000000', width:"214px", height:"52px", border:'0px', padding:'5px 0px', justifyContent:'center'}}>
                                                            <img draggable='false' style={{borderRadius:'1px', backgroundColor:'#000000', color:'#FFFFFF',
                                                                      height:'42px', zIndex: 999}} src={UniPowLogo} alt="logo" />
                                                        </div>   

                                                      :undefined) }

                                            </ButtonCell> )
                                  })
            }
          </BlockLayoutWrapped>
        </div>)
      }

      <InfoLayout mobile={below540} style={{marginTop: '15px'}}>

        <CellBody mobile={below540} style={{justifyContent:'center'}} >
          <ARECContainer style={{padding: '20px', height:'100%'}}>
            <RowBetween>
              { (idlistLinked ===undefined)
                ? (<div>
                    <RowFixed height='24px'>
                      { (cellSelected.length ===0)
                        ? <Text fontWeight={500} fontSize={16} color={theme.text2}> 
                            Thanks to greenize BTC ! 
                          </Text>
                        : <Text fontWeight={500} fontSize={16} color={(cellSelected.length > 21)? 'red' : theme.text2}> You will be 
                            at <M size={'20px'}>{(cellSelected.length>21) ? 8 : Math.floor((cellSelected.length-1)/3+1)}</M> Green Level 
                          </Text>
                      }
                    </RowFixed>
                    <RowFixed height='24px'>
                      <Text fontWeight={500} fontSize={16} color={theme.text2}> You have selected <M size={'20px'}>{cellSelected.length}</M> cells: </Text>
                    </RowFixed>
                  </div>)
                : (<div>
                    <RowFixed height='24px'>
                      <Text fontWeight={500} fontSize={16} color={theme.text2}> This cell has been <M>GREENIZED</M>. </Text>
                    </RowFixed>
                    <RowFixed height='24px'>
                      <Text fontWeight={500} fontSize={16} color={theme.text2}> Totally <M>{idlistLinked.length}</M> cells in this acion: </Text>
                    </RowFixed>
                  </div>)
              }
              <RowFixed height='40px'>
                { (idlistLinked && idlistLinked.length!==0)
                  ?  ( <LogoCell numCell = {idlistLinked?.length ?? 0} height={'60px'}/> )
                  : (outCells && (cellSelected.length!==0)) 
                    ? (<LogoCell numCell = {cellSelected.length} height={'60px'}/>)
                    : (
                      <Text ref={ref as any} fontWeight={500} fontSize={30} color={theme.primary1} paddingRight={"24px"} textAlign="right">
                        <span role="img" aria-label="party-hat">👍</span>
                      </Text>
                    )
                }    
              </RowFixed>
            </RowBetween>

            <div style={{overflowY:'auto', overflowX: 'hidden', justifyContent:'center', marginTop: '6px', 
                          paddingTop:'3px', height: '5rem', borderRadius:'6px', border: `1px solid ${theme.primary4}`}}>
            { (!!idlistLinked && (idlistLinked.length > 0)) 
              ? (<SelectedCell mobile={below540} style={{alignItems:'flex-start', paddingLeft:'8px'}}>
                  {idlistLinked.slice(0,below540 ? 18: 21).map((id,index) => { return (
                        <Text fontWeight={200} fontSize={20} key={id} color={theme.text2}> 
                          <M>{id.toString().padStart(4,'0')}{(index===20)? '...': ''}</M> </Text>
                   )})}
                </SelectedCell>)
              : (cellSelected.length>0) 
                ? (
                    <SelectedCell mobile={below540} style={{alignItems:'flex-start', paddingLeft:'8px'}}>
                      {cellSelected.map((id) => { return (
                        <Text fontWeight={200} fontSize={20} key={id} color={theme.text2}> <M>{(id+1).toString().padStart(4,'0')}</M> </Text>
                      )})}
                    </SelectedCell>
                  )
                : (
                    <Text fontWeight={300} fontSize={16} color={theme.text4} style={{paddingLeft:'10px'}}> 
                      Selected Cell IDs displayed here.
                    </Text>
                  )
            } 
            </div>
            { (bricksOwners && ownerToLink!==(-1)) 
              ? ( <RowBetween alignItems={"center"} height={'30px'} padding={'10px 0px'}>
                      <Text lineHeight={"30px"} fontWeight={500}  color={theme.text2}>Actor: </Text>
                      <Text lineHeight={"30px"} fontWeight={700}  color={theme.primary}> 
                        {below540 ? shortenAddress(bricksOwners[ownerToLink], 12) : bricksOwners[ownerToLink]} 
                      </Text>
                  </RowBetween> ) 
              : (              
                  <SetBuyNumberOfCells value={numToBuy} onChange={setCellNumberToBuy} /> 
                )
            }

          </ARECContainer>
        </CellBody>

            <CellBody mobile={below540} style={{justifyContent:'center'}}> 
              <ARECContainer style={{padding: '20px', height:'100%'}}>

                <PayLayout mobile={below540}>
                  <div>
                    <RowFixed height='24px'>
                      <Text fontWeight={500} fontSize={16} color={theme.text2}> You will pay: </Text>
                    </RowFixed>

                    {(getAmountInputStatus === '')
                      ? (<RowFixed height='36px' alignItems={'flex-end'}>
                        <Text fontWeight={500} fontSize={24} color={theme.text2}> <M>{paymentAmountString}</M> </Text>
                      </RowFixed>)
                      : (<RowFixed height='36px' alignItems={'flex-end'}>
                        <Text fontWeight={500} fontSize={16} color={theme.red2}> {getAmountInputStatus} </Text>
                      </RowFixed>)
                    }
                  </div>
                  <div>
                    <RowBetween height='24px'>
                      <div/>
                      <Text fontWeight={500} fontSize={16} color={theme.text2} paddingRight={"8px"} > Payment Token </Text>
                    </RowBetween>

                    <RowBetween>
                      <div/>
                      <select style={{ fontSize:16, fontWeight:500, width:'80%', fontFamily: 'Inter, sans-serif', padding:'5px'}}
                        onInput = {onTokenSelect} >
                        {paymentToken.map((token: string) => {
                          return <option value={token} key={token}> {token} </option>
                        })}
                      </select>
                    </RowBetween>
                  </div>
                </PayLayout>

                {!account &&
                  <ButtonPrimary style={{marginTop: "10px", fontSize:22}} onClick={toggleWalletModal}>
                    Connect to wallet
                  </ButtonPrimary>
                }

                { account && (
                    showApproveFlow 
                    ? ( <RowBetween>
                          <ButtonConfirmed onClick={()=>{onAttemptToApprove()}} 
                                width={"49%"}  style={{marginTop: "10px", fontSize:22}}
                                disabled={(signatureData !== null) || isApproving} >
                              Permit {tokenPay}
                          </ButtonConfirmed> 
                          <ButtonConfirmed onClick={()=>{greenizeBTC()}}
                                  width={"49%"} style={{marginTop: "10px", fontSize:22}}
                                  disabled={(cellSelected.length ===0 ) || (signatureData == null)} >
                              Greenize BTC
                          </ButtonConfirmed> 
                        </RowBetween> )
                    : (
                        <ButtonConfirmed onClick={()=>{greenizeBTC()}} 
                                  width={"100%"} style={{marginTop: "10px", fontSize:22}} 
                                  disabled={(cellSelected.length ===0 )}>
                          Greenize BTC
                        </ButtonConfirmed> )
                  )
                }

                <Text fontWeight={500} fontSize={24} textAlign={'center'} color={theme.primary2} paddingTop={'16px'}> 
                  Sustainable BitCoin!
                </Text>

              </ARECContainer>
            </CellBody>

      </InfoLayout>
    </>
  )
}



/*
{ (idlistLinked?.length!==0) && (
    <CellBody mobile={below540} style={{justifyContent:'center'}}> 
      <ARECContainer style={{padding: '20px', height:'100%'}}>


      { (bricksOwners && ownerToLink!==(-1) && (idlistLinked?.length!==0)) && (
      <RowBetween>
        <AutoColumn>
          <RowFixed>
            <Text fontWeight={500}  color={theme.text2}>Actor: </Text>
          </RowFixed>
          <Text lineHeight={"30px"} fontWeight={700}  color={theme.primary}> 
            {below540 ? shortenAddress(bricksOwners[ownerToLink], 12) : shortenAddress(bricksOwners[ownerToLink], 10)} 
          </Text>
        </AutoColumn>
        <LogoCell numCell = {idlistLinked?.length ?? 0} height={'60px'}/>
      </RowBetween>
    )}

      </ARECContainer>
    </CellBody> 
  ) 
}
*/
