import React, { useState, useContext } from 'react'
import { Text } from 'rebass'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { NavLink } from 'react-router-dom'

import styled, { ThemeContext } from 'styled-components'
import Web3Network, { NETWORK_LABELS } from '../Web3Network'
//import Logo from '../../assets/svg/arkreen_2.png'
import Discord from '../../assets/svg/discord.svg'
import Logo from '../../assets/NFT/Logo.png'

import { useActiveWeb3React } from '../../hooks'

import { BlueCard } from '../Card'
//import Settings from '../Settings'

import Row, { RowFixed } from '../Row'

import Web3Status from '../Web3Status'

import Modal from '../Modal'
import UniBalanceContent from './UniBalanceContent'
//import { MenuItem } from '../MenuInfo'

import { Twitter, BookOpen } from 'react-feather'
import { ExternalLink } from '../../theme'

//import MenuApp from '../MenuApp'
//import MenuInfo from '../MenuInfo'

const HeaderFrame = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  width: 100%;
  top: 0;
  position: relative;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding: 1rem 1.5rem; 
  z-index: 2;

  ${({ theme }) => theme.mediaWidth.upToLarge`
    grid-template-columns: 1fr 1fr;
  `};

  ${({ theme }) => theme.mediaWidth.upToMedium`
    padding:  0.2rem 1rem;
    grid-template-columns: 1fr;
    justify-content: flex-start;
  `};
`

export const MenuItem = styled(ExternalLink)`
  flex: 1;
  color: ${({ theme }) => theme.text2};
  height: 35px;
  width: 35px;
  border: none;
  margin-left: 8px;
  border-radius: 0.5rem;
  align-items: center;
  align-content: center;
  background-color: ${({ theme }) => theme.bg5};
  :hover {
    color: ${({ theme }) => theme.text1};
    background-color: ${({ theme }) => theme.bg3};
    cursor: pointer;
    text-decoration: none;
    font-size: 24px;
  }
  > svg {
    margin-right: 8px;
  }
`

export const MenuItemDocs = styled(ExternalLink)`
  flex: 1;
  border-radius: 8px;
  padding: 8px 12px;
  border: 1px solid green;
  align-items: center;
  align-content: center;  
  width: '120px';
  color: ${({ theme }) => theme.text2};
  background-color: ${({ theme }) => theme.bg5};
  :focus,
  :active, 
  :hover {
    color: ${({ theme }) => theme.text1};
    background-color: ${({ theme }) => theme.primary2};
    cursor: pointer;
    text-decoration: none;
  }
  > svg {
    margin-right: 8px;
  }
`

const HeaderControls = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-self: flex-end;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    flex-direction: row;
    justify-content: space-between;
    justify-self: center;
    width: 100%;
    max-width: 960px;
    padding: 0.5rem;
    position: fixed;
    bottom: 0px;
    left: 0px;
    width: 100%;
    z-index: 99;
    height: 48px;
    background-color: ${({ theme }) => theme.bg1};
  `};
`

const HeaderElement = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  ${({ theme }) => theme.mediaWidth.upToMedium`
   flex-direction: row-reverse;
    align-items: center;
  `};
`

const HeaderElementWrap = styled.div`
  display: flex;
  align-items: center;
`

const HeaderLinks = styled(Row)`
  justify-content: left;
  display: flex;
  align-items: center;
  justify-items: left;
  grid-row-gap: 8px;  
`

const AccountElement = styled.div<{ active: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${({ theme, active }) => (!active ? theme.bg1 : theme.bg3)};
  border-radius: 8px;
  white-space: nowrap;
  width: 100%;
  cursor: pointer;

  :focus {
    border: 1px solid blue;
  }
`

const HideSmall = styled.span`
  ${({ theme }) => theme.mediaWidth.upToSmall`
    display: none;
  `};
`

const NetworkCard = styled(BlueCard)`
  border-radius: 8px;
  padding: 8px;
  white-space: nowrap;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin: 0;
    margin-right: 0.5rem;
    width: initial;
    overflow: hidden;
    text-overflow: ellipsis;
    flex-shrink: 1;
  `};
`

/*
const Title = styled.a`
  display: flex;
  align-items: center;
  pointer-events: auto;
  justify-self: flex-start;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    justify-self: center;
  `};
  :hover {
    cursor: pointer;
    color: black;
  }
`
*/

/*
const UniIcon = styled.div`
  transition: transform 0.3s ease;
  :hover {
    transform: rotate(-3deg);
  }
`
*/

const activeClassName = 'ACTIVE'

export const StyledNavLink = styled(NavLink).attrs({
  activeClassName
})`
  ${({ theme }) => theme.flexRowNoWrap}
  align-items: left;
  border-radius: 3rem;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  color: ${({ theme }) => theme.text2};
  font-size: 1.2rem;
  width: fit-content;
  margin: 0 12px;
  font-weight: 500;

  &.${activeClassName} {
    border-radius: 12px;
    font-weight: 900;
    color: ${({ theme }) => theme.navlink};
  }

  :hover,
  :focus {
    color: ${({ theme }) => theme.navlink};
  }
  
  ${({ theme }) => theme.mediaWidth.upToMedium`
    font-size: 1rem;
    margin: 0 3px;
  `};
`

function Header({ history }: RouteComponentProps) {
//  const below1080 = useMedia('(max-width: 1080px)')
  const { account, chainId } = useActiveWeb3React()
  const theme = useContext(ThemeContext)

//  const [isDark] = useDarkModeManager()

 
  const [showUniBalanceModal, setShowUniBalanceModal] = useState(false)

//  <MenuApp />
//  <MenuInfo />

  return (
    <HeaderFrame>
      <Modal isOpen={showUniBalanceModal} onDismiss={() => setShowUniBalanceModal(false)}>
        <UniBalanceContent setShowUniBalanceModal={setShowUniBalanceModal} />
      </Modal>
          <HeaderLinks>
              <img style={{borderRadius:'6px', margin:'0px', height:'36px'}} src={Logo} alt="logo" />
              <span style={{backgroundColor: theme.primary2, color:'white', marginLeft:'6px', padding:'2px 12px', fontSize:'22px', borderRadius:'6px'}}> 
                Green BTC 
              </span>
          </HeaderLinks>
      <HeaderControls>
      <HeaderElement>
        <MenuItemDocs id="link" href="https://docs.arkreen.com/how-to-use/green-btc" draggable='false' >
          <RowFixed>
              <BookOpen size={14} style={{ marginRight: '5px' }}/>
              <Text style={{fontWeight:500, fontSize:'16px'}}> Docs </Text>
          </RowFixed>
        </MenuItemDocs>

          <HideSmall>
            {chainId && NETWORK_LABELS[chainId] && (!!account) && (
              <NetworkCard title={NETWORK_LABELS[chainId]}>
                <RowFixed>
                  <Web3Network />
                  <Text style={{marginLeft:'4px'}}>
                    {NETWORK_LABELS[chainId]}
                  </Text>
                </RowFixed>
              </NetworkCard>
            )}
          </HideSmall>
          <AccountElement active={!!account} 
            style={{ pointerEvents: 'auto', backgroundColor:'#FCF5EC' }}>
            <Web3Status />
          </AccountElement>
        </HeaderElement>
        <HeaderElementWrap>
            <MenuItem id="link" href="https://discord.gg/j5fSNRKGme" draggable='false' style={{padding:'0px'}}>
              <img style={{width:'20px', height:'20px', margin: '8px 8px'}} src={Discord} alt="" />
            </MenuItem>
            <MenuItem id="link" href="https://twitter.com/arkreen_network" draggable='false' style={{padding:'0px'}}>
              <Twitter size={18} style={{margin: '9px 9px' }}  />
            </MenuItem>
        </HeaderElementWrap>
      </HeaderControls>
    </HeaderFrame>
  )
}

export default withRouter(Header)

/*
<HeaderLinks>
<StyledNavLink id={`swap-nav-link`} to={'/swap'}>
  Swap
</StyledNavLink>
<StyledNavLink
  id={`pool-nav-link`}
  to={'/liquidity'}
  isActive={(match, { pathname }) =>
    Boolean(match) ||
    pathname.startsWith('/add') ||
    pathname.startsWith('/remove') ||
    pathname.startsWith('/create') ||
    pathname.startsWith('/find')
  }
>
  Liquidity
</StyledNavLink>
<StyledNavLink id={`stake-nav-link`} to={'/fesw'}>
  Mining
</StyledNavLink>
<StyledNavLink id={`nft-nav-link`} to={'/nft'}>
  NFT
</StyledNavLink>
<StyledNavLink id={`dao-nav-link`} to={'/vote'}>
  DAO
</StyledNavLink>
<StyledExternalLink id={`charts-nav-link`} href={'https://info.feswap.io'}>
  Charts <span style={{ fontSize: '11px' }}>↗</span>
</StyledExternalLink>	 
<StyledExternalLink id={`docs-nav-link`} href={'https://www.feswap.io/docs'}>
  Docs <span style={{ fontSize: '11px' }}>↗</span>
</StyledExternalLink>	 
</HeaderLinks>
*/
