import { useMemo } from 'react'
import { maxBrickNumber, maxColumn, maxRow, BrickInfo }  from './index'

  const template = [  [1,   1,  0],         //  1: 1*1
                      [1,   2,  0],         //  2: 1*2
                      [1,   3,  0],         //  3: 1*3
                      [2,   2,  0],         //  4: 2*2
                      [2,   2,  1],         //  5: 2*2+1
                      [2,   3,  0],         //  6: 2*3
                      [2,   3,  1],         //  7: 2*3+1
                      [2,   4,  0],         //  8: 2*4
                      [3,   3,  0],         //  9: 3*3
                      [2,   5,  0],         // 10: 2*5
                      [3,   3,  2],         // 11: 3*3+2
                      [3,   4,  0],         // 12: 3*4
                      [3,   4,  1],         // 13: 3*4+1
                      [2,   7,  0],         // 14: 2*7
                      [3,   5,  0],         // 15: 3*5
                      [4,   4,  0],         // 16: 4*4
                      [4,   4,  1],         // 17: 4*4+1
                      [3,   6,  0],         // 18: 3*6
                      [4,   4,  3],         // 19: 4*4+3
                      [4,   5,  0],         // 20: 4*5
                      [3,   7,  0],         // 21: 3*7
                    ]    


  export function useRandomPlaceCells( numCells: number, allBrickInfo: BrickInfo[] | undefined ): {
      CellMatrix: number[] | undefined }
  {
    return useMemo(() => {
      if ((!allBrickInfo) || (numCells <= 0)) return { CellMatrix: undefined }
      
      let index = 0
      let CellMatrix = new Array<number>(maxBrickNumber).fill(-1)
      while(index < numCells) {
        let random = Math.floor((Math.random()*maxBrickNumber)+1)     // 12 MSB used as the positon , 4 LSB used as the direction
        if (CellMatrix[random] === -1) {
          if( !allBrickInfo[random] || !allBrickInfo[random].sold) {
            CellMatrix[random] = random
            index += 1
          }
        }
        continue
      }
      return {CellMatrix}
    }, [numCells, allBrickInfo])
  }

    
  export function usePlaceCells( numCells: number, allBrickInfo: BrickInfo[] | undefined ): {
      placedOK:    boolean
      CellMatrix: number[] | undefined }
  {
    return useMemo(() => {
      if ((!allBrickInfo) || (numCells<=0) || (numCells>21)) return { placedOK: false, CellMatrix: undefined }

      let direction
      let originX
      let originY
      
      while(true) {
        let random = Math.floor((Math.random()*65536)+1)     // 12 MSB used as the positon , 4 LSB used as the direction
        let origin = (random >> 4) % maxBrickNumber

        direction  = random & 0x0F
        originX = origin % maxColumn
        originY = Math.floor(origin / maxColumn)
        let addition = (template[numCells-1][2] === 0) ? 0 : 1 
        if ((originX + template[numCells-1][1] - 1) >= maxColumn) continue
        if ((originY + template[numCells-1][0] - 1 + addition ) >= maxRow) continue
        break
      }

      let CellMatrix = new Array<number>(numCells)
      for(let indH = 0; indH < template[numCells-1][0]; indH++) {
        for(let indW = 0; indW < template[numCells-1][1]; indW++) {
          const cellIndex = indH * template[numCells-1][1] + indW
          CellMatrix[cellIndex] = (originY + indH) * maxColumn + originX + indW
        }
      }
      for(let indA = 0; indA < template[numCells-1][2]; indA++) {
        const cellIndex = template[numCells-1][0] * template[numCells-1][1] + indA
        CellMatrix[cellIndex] = (originY + template[numCells-1][0]) * maxColumn + originX + indA
      }

      let placedOK = true 
      let stop = false
      while(!stop) {
        stop = true
        for (let index=0; index<numCells; index++) {                      // Check if conflict,  if yes, transform
          const position = CellMatrix[index]
          if ((position >= 0) && (position < maxBrickNumber)) { 
            if(!allBrickInfo[position]) continue
            if(!allBrickInfo[position].sold) continue
          }

          let failed = false
          for (let ind=0; ind<numCells; ind++) {
            if ((direction & 0x03) === 0x00) {           // move right
              if( ((CellMatrix[template[numCells-1][1]-1]) % maxColumn) === (maxColumn-1)) {
                failed = true;
                break;
              }
              CellMatrix[ind] += 1
            } else if ((direction & 0x03) === 0x01) {
              if((CellMatrix[0] % maxColumn) === 0) { 
                failed = true;
                break;
              }
              CellMatrix[ind] -= 1                      // move left
            } else if ((direction & 0x03) === 0x02) {
              if( Math.floor(CellMatrix[0] / maxColumn) === 0) { 
                failed = true;
                break;
              }
              CellMatrix[ind] -= maxColumn                     // move up
            } else {
              let height = Math.floor(CellMatrix[0] / maxColumn) + template[numCells-1][0] - 1
              if(CellMatrix[template[numCells-1][2]] !== 0) height += 1
              if( height >= (maxRow-1)) {
                failed = true;
                break;
              }
              CellMatrix[ind] += maxColumn                    // move down
            }
          }

          if(failed) placedOK = false
          else stop = false
          break
        }
      }
      return {placedOK, CellMatrix}
    },[numCells, allBrickInfo])
  }

  export function usePlaceCellsAll( allBrickInfo: BrickInfo[] | undefined ): number[][] | undefined {
 
    return useMemo(() => {
      if (!allBrickInfo) return undefined

      const random = Math.floor((Math.random()*65536)+1)     // 12 MSB used as the positon , 4 LSB used as the direction
      
      // const direction  = random & 0x0F
      const origin = (random >> 4) % maxBrickNumber
      
      const originX = origin % maxColumn
      const originY = Math.floor(origin / maxColumn)
      let CellMatrix = new Array<number[]>(21)              // 21 cells maximum

      for (let index=0; index<21; index++) {                // Place as the template
        CellMatrix[index] = new Array<number>(index+1)
        for(let indH = 0; indH < template[index][0]; indH++) {
          for(let indW = 0; indW < template[index][1]; indW++) {
            const cellIndex = indH * template[index][1] + indW
            CellMatrix[index][cellIndex] = (originY + indH) * maxColumn + originX + indW
          }
        }
        for(let indA = 0; indA < template[index][2]; indA++) {
          const cellIndex = template[index][0] * template[index][1] + indA
          CellMatrix[index][cellIndex] = (originY + template[index][0]) * maxColumn + originX + indA
        }
      }

      for (let index=0; index<21; index++) {                // Check if conflict,  if yes, transform
        for(let cellIndex = 0; cellIndex < (index+1); cellIndex++) {
          const position = CellMatrix[index][cellIndex]
          let failed = false
          if (position >= maxBrickNumber) failed = true
          if(!allBrickInfo[position]) continue
          if(allBrickInfo[position].sold) failed = true
          if(failed) {
            // todo
          }
        }
      }

      return CellMatrix

  },[allBrickInfo])
}
