import React from 'react'
import { Text } from 'rebass'

import { ButtonError } from '../../components/Button'
import { RowBetween } from '../../components/Row'
import { BottomGrouping, Wrapper } from '../../components/swap/styleds'
import PageHeader from '../../components/PageHeader'
import {StyledPageCard} from '../../components/earn/styled'
import { AutoColumn } from '../../components/Column'
import { Container } from '../../components/CurrencyInputPanel'
import { useWindowSize } from '../../hooks/useWindowSize'
import { useActiveWeb3React } from '../../hooks'


import AppBody from '../AppBody'
import QuestionHelper from '../../components/QuestionHelper'
import { useSimulationMode } from '../../state/user/hooks'

export default function Test() {
  const { chainId } = useActiveWeb3React()

  const { width, height } = useWindowSize()
  const [simulationMode, toggleSimulationMode] = useSimulationMode()
  
  const fontSize = window.document.documentElement.style.fontSize;
  const userAgent = navigator.userAgent;
  console.log('width, height BBBBBBBBBBBB', width, height, fontSize, userAgent)

  async function getData() {
    const url = new URL("https://testapi.arkreen.com/device/device/getMinersByOwnerAddress")
    const parameter = {
      "address":  "0x576ab950b8b3b18b7b53f7edd8a47986a44ae6f4",
      "offset":   "1",
      "limit":    "10",
    }
    const search = new URLSearchParams(parameter).toString();
    console.log("search", search, url+'?'+search)
 
    const response = await fetch(url+'?'+search)
    
    const json = await response.json()
    console.log('Json data', json)
  }

    // Example POST method implementation:
  //  async function postData(url = '', data = {}) {
  async function getMiners() {
      const url = "https://101.35.163.23:3001/v1"
      const parameter = {
        jsonrpc: "2.0",
        id: "",
        method: "rec_getOwnerRecData",
        params: {
        owner:    "0x576ab950b8b3b18b7b53f7edd8a47986a44ae6f4",
        endDate:  "20221120"
        }
      }

      console.log("JSON.stringify(parameter):", JSON.stringify(parameter))


//      http://101.35.163.23:300/v1
//      "https://testapi.arkreen.com/v1  
// 0x364a71eE7a1C9EB295a4F4850971a1861E9d3c7D      
// 0x13BAAc85653407206e4b4ae4DB40f420aF8eeaD8
//  mode: 'no-cors',                  // no-cors, *cors, same-origin

      // Default options are marked with *
      const response = await fetch(url, {
        method: 'POST',                   // *GET, POST, PUT, DELETE, etc.
//        cache: 'no-cache',              // *default, no-cache, reload, force-cache, only-if-cached
//        credentials: 'same-origin',     // include, *same-origin, omit        
//        redirect: 'follow',             // manual, *follow, error
//        referrerPolicy: 'no-referrer',  // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(parameter)      // body data type must match "Content-Type" header
      });
      console.log('response data', response)
      const json = await response.json()
      console.log('Json data', json)
    }


  // Example POST method implementation:
  //  async function postData(url = '', data = {}) {
  async function postData() {
    const url = "https://api.arkreen.com/account/accountreward/list"
    const parameter = {
      "address":  "0x576ab950b8b3b18b7b53f7edd8a47986a44ae6f4",
      "offset":   1,
      "limit":    25,
    }

    // Default options are marked with *
    const response = await fetch(url, {
      method: 'POST',                 // *GET, POST, PUT, DELETE, etc.
      mode: 'cors',                   // no-cors, *cors, same-origin
      cache: 'no-cache',              // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin',     // include, *same-origin, omit
      headers: {
        'Content-Type': 'application/json'
      },
      redirect: 'follow',             // manual, *follow, error
      referrerPolicy: 'no-referrer',  // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      body: JSON.stringify(parameter)      // body data type must match "Content-Type" header
    });
    console.log('response data', response)
    const json = await response.json()
    console.log('Json data', json)
  }

  async function getJsonData() {
    postData()
  }


  return (
    <>
      <AppBody>
      <StyledPageCard bgColor={'red'}>
        <PageHeader header={'Test'}>
          { chainId && ( <QuestionHelper text={'Test'} info={<>Test</>} /> )} 
        </PageHeader>
        <Wrapper id="issuance-page">

        <AutoColumn gap={'md'}>
            <Container style={{boxShadow:"inset 0px 0px 8px #00913A", margin:'0rem 0rem'}}>
              <AutoColumn gap="4px" style={{padding: "0.75rem 1rem 0.75rem 1rem"}}>

                <RowBetween align="center" height='20px'>
                  <Text fontWeight={500} fontSize={14} > Version </Text>
                  <Text fontWeight={700} fontSize={14} > 
                    Ver_0.127 2023/1/24 17:26
                  </Text>
                </RowBetween>                

                <RowBetween align="center" height='20px'>
                  <Text fontWeight={500} fontSize={14} > Width, Height: </Text>
                  <Text fontWeight={700} fontSize={14} > 
                    ( {width}, {height} )
                  </Text>
                </RowBetween>

              </AutoColumn>
            </Container>  
          </AutoColumn>
          <BottomGrouping>

                <RowBetween style={{marginTop:'1rem'}}>
                  <ButtonError  onClick={getJsonData}>
                    <Text fontSize={20} fontWeight={500}>
                      postData
                    </Text>
                  </ButtonError>
                </RowBetween>                

                <RowBetween style={{marginTop:'1rem'}}>
                  <ButtonError  onClick={()=>getData()}>
                    <Text fontSize={20} fontWeight={500}>
                      getData
                    </Text>
                  </ButtonError>
                </RowBetween>  

                <RowBetween style={{marginTop:'1rem'}}>
                  <ButtonError  onClick={()=>getMiners()}>
                    <Text fontSize={20} fontWeight={500}>
                      getMiners
                    </Text>
                  </ButtonError>
                </RowBetween>     

                <RowBetween style={{marginTop:'1rem'}}>
                  <ButtonError  onClick={()=>toggleSimulationMode()}>
                    <Text fontSize={20} fontWeight={500}>
                      { simulationMode ? 'Swicth off Simulation Mode' : 'Swicth on Simulation Mode'}
                    </Text>
                  </ButtonError>
                </RowBetween>

          </BottomGrouping>
        </Wrapper>
        </StyledPageCard>
      </AppBody>
    </>
  )
}