import React ,{useEffect,useState} from 'react';
import ProofCard from '../../components/ProofCard/index'
import { useHistory,useLocation } from 'react-router-dom'
import {EnergyIcon,UserIcon,IdIcon,
  DescriptionIcon,DateIcon,ActorLevelIcon,
  TwitterIcon,GreenizedCellsIcon,TxHashIcon,RenewableEnergyIcon,
  NameOfAssetIcon,TokenIcon,MintTimeIcon,CIDIcon,WebSiteIcon
} from '../../components/icon/ArkreenIcon'
import './Proof.css'
import {useActorInfo} from 'state/user/hooks'
import {allRegisteredEntity,allRegisteredEntityMatic,logoLevel} from '../GreenBTC/index'
import entity from '../GreenBTC/entity.json';
import { useActiveWeb3React } from '../../hooks'
import { ChainId } from '@feswap/sdk'
import arecInfo from '../Proof/arec.json';

function Proof(){
  const history  = useHistory();
  const location = useLocation();
  const { chainId } = useActiveWeb3React()

  type ActorCompanyInfo = {
    web_site?:string,
    logo?:string,
    twitter?:string,
    name?:string,
    account?:string
    background_color?:string
  }

  type GreenBTCInfoType = {
    cells?:any,
    impact?:any,
    time?:string,
    hash?:string
  }

  const [greenBTCInfo,setGreenBTCInfo] = useState<GreenBTCInfoType>({})
  const [userLevel,setUserLevel] = useState(0)
  const [companyInfo,setCompanyInfo] = useState<ActorCompanyInfo>()

  const GreenHeroInfo = [
    {
      icon: <UserIcon/>,
      title: 'Account',
      value: companyInfo?.account,
      account: true
    },
    {
      icon: <WebSiteIcon/>,
      title: 'Web Site',
      value: companyInfo?.web_site,
      link: true
    },
  ]

  function formatLongString(str: string,prefix: number,suffix: number){
    if(!str){
        return '';
    }
    if(str?.length<=prefix+suffix){
        return str;
    }else {
        const prefixStr =  str.substring(0,prefix);
        const suffixStr =  str.substring(str.length-suffix);
        return prefixStr+"..."+suffixStr
    }
}

  const arec = arecInfo[(chainId === ChainId.MATIC_TESTNET) ? "80001" : "137"]

  const AREC_NFT_Info = [
    {
      icon: <IdIcon/>,
      title: 'AREC ID',
      value: arec.id,
    },
    {
      icon: <RenewableEnergyIcon/>,
      title: 'Amount of Renewable Energy',
      value: arec.amountRenewableEnergy,
    },
    {
      icon: <RenewableEnergyIcon/>,
      title: 'Region',
      value: arec.region,
    },
    {
      icon: <TokenIcon/>,
      title: 'AREC NFT',
      value: arec.arecNft,
      account: true
    },
    {
      icon: <TokenIcon/>,
      title: 'HART Token',
      value: arec.hartToken,
      account: true
    },
    {
      icon: <NameOfAssetIcon/>,
      title: 'Name of Asset',
      value: arec.nameOfAsset,
    },
    {
      icon: <MintTimeIcon/>,
      title: 'Mint Time',
      value: arec.mintTime,
    },
    {
      icon: <TxHashIcon/>,
      title: 'Mint Transaction',
      value: arec.mintTransaction,
      hash: true
    },
    {
      icon: <CIDIcon/>,
      title: 'TREC CID',
      value: formatLongString(arec.trecCID,21,7),
    },
    {
      icon: <DescriptionIcon/>,
      title: 'Asset Description',
      value: arec.assetDescription,
    },
  ]

  const TRECs = [
    {
      icon:<WebSiteIcon/>,
      title:'I-REC Redemption Cert',
      value:'https://bafkreifhkz55fcrddqrlmvgt2hmensuqfzbs3raopmynkter3ihfsnpoti.ipfs.dweb.link',
      trec:true
    },
  ]

  const GreenBTCInfo = [
    {
      icon: <GreenizedCellsIcon/>,
      title: 'Greenized Cells',
      value: greenBTCInfo.cells,
      cells: true
    },
    {
      icon: <EnergyIcon/>,
      title:'Green Energy Impact',
      value: greenBTCInfo.impact,
    },
    {
      icon: <DateIcon/>,
      title: 'Green Action Time',
      value: greenBTCInfo.time,
    },
    {
      icon: <TxHashIcon/>,
      title:'Transaction on Chain',
      value: greenBTCInfo.hash,
      hash:true
    },
  ]

  const utm = new URLSearchParams(location.search)
  const id = Number(utm.get("id"))-1
  const name = (chainId === ChainId.MATIC_TESTNET) ? allRegisteredEntity[id] : allRegisteredEntityMatic[id]
  const actorCompanyInfo = (entity as any)[""+name];
  const actorInfo = useActorInfo(id);
  console.log(actorInfo)
  
  
  function initData(){
    const level =  actorInfo?.cellIDs.length;
    const timeTrx =  actorInfo?.timeTrx;
    const hashTrx =  actorInfo?.hashTrx;
    const cellIDs =  actorInfo?.cellIDs;
    const address =  actorInfo?.address;
    //
    let cells:any = []
    cellIDs?.forEach((item:any) => {
      console.log(item)
      cells.push(<div className='proof-cell-td'>{item}</div>)
    })
    //
    const greenBTCInfo = {
      cells:<div className='proof-cell-grid'>{cells}</div>,
      impact:level?<span>{((level*2)+' MWh/'+(Number(level * 2 * 0.709).toFixed(2))+' TCO')}<sub>2</sub></span>:'',
      time:timeTrx?formatDate(Number(timeTrx)*1000,'yyyy-MM-dd HH:mm:ss'):'',
      hash:hashTrx
    } 
    const userLevel =  level?(level>21) ? 8 : Math.ceil(level/3):0
    setUserLevel(userLevel)
    setGreenBTCInfo(greenBTCInfo)
    const logoName = actorCompanyInfo ? actorCompanyInfo.logo : logoLevel[userLevel-1] 
    const logoPath = actorCompanyInfo ? require('../../assets/svg/' + logoName) : require('../../assets/NFT/' + logoName)
    setCompanyInfo(
      {
        web_site: actorCompanyInfo?.website,
        logo: logoPath,
        twitter: actorCompanyInfo?.twitter,
        name: actorCompanyInfo?.name?actorCompanyInfo?.name:'EcoCoiner #'+(id+1),
        background_color: actorCompanyInfo?actorCompanyInfo?.backgroundColor:'#CCE9D8',
        account:address
      }
    )
  }
  
  function goBack(){
    history.goBack();
  }

  function formatDate(value: number, format: string) {
    if (!value){ return; }
    if (!format){ format = 'yyyy-MM-dd'; }
    let time = new Date(value);
    if (time instanceof Date){
      const dict: any = {
        yyyy: time.getFullYear(),
        M: time.getMonth() + 1,
        d: time.getDate(),
        H: time.getHours(),
        m: time.getMinutes(),
        s: time.getSeconds(),
        MM: ('' + (time.getMonth() + 101)).substr(1),
        dd: ('' + (time.getDate() + 100)).substr(1),
        HH: ('' + (time.getHours() + 100)).substr(1),
        mm: ('' + (time.getMinutes() + 100)).substr(1),
        ss: ('' + (time.getSeconds() + 100)).substr(1),
      };
      return format.replace(/(yyyy|MM?|dd?|HH?|mm?|ss?)/g, function () {
        return dict[arguments[0]];
      });
    }else{
      return ''
    }
  }

  useEffect(() => {initData()},[])//eslint-disable-line

  return (
    <div className='proof-main'>
      <div className='proof-top'>
        <div className='proof-top-btn'><button className='back-btn' onClick={() => goBack()}>Back</button></div>
        <div className='proof-top-title'><span className='proof-title'>Proof</span></div>
      </div>
      <div className='proof-context'>
          <div className='proof-context-left'>
          <div className='proof-user'>
              <div className='proof-user-avatar'>
                <div className='proof-user-icon' style={{backgroundColor:companyInfo?.background_color}}>
                  <img alt='user icon' src={companyInfo?.logo} />
                </div>
                <div className='proof-user-title'>
                  <div className='proof-user-title-x'>
                    <div style={{flex:1}}>
                      <div className='proof-user-name'>{companyInfo?.name}</div>
                      <div className='proof-user-label'>
                        <ActorLevelIcon/>
                        <span className='proof-actor-level-title'>Actor Level:</span>
                        <span className='proof-actor-level'>{userLevel}</span>
                      </div>
                    </div>
                    <div className='proof-user-link'>
                      <span><a className="proof-url-link" href={companyInfo?.twitter} target='_blank' rel="noopener noreferrer"><TwitterIcon/></a></span>
                    </div>
                  </div>
                </div>
              </div>
              <div className='proof-card-main'>
                  <ProofCard title='HashKey Green BTC Info' dataList={GreenBTCInfo}/>
                  <ProofCard title='EcoCoiner' dataList={GreenHeroInfo}/>
                </div>
            </div>
          </div>
          <div className='proof-context-right'>
            <div className='proof-context-table'>
              <ProofCard title='AREC NFT' dataList={AREC_NFT_Info}/>
            </div>
            <div className='proof-context-table'>
              <ProofCard title='I-REC' dataList={TRECs}/>
            </div>
          </div>
        </div>
    </div>
  );
}

export default Proof;