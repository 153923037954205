import React, { useContext, useState, useCallback, useMemo, useEffect } from 'react'
import { CurrencyAmount, TokenAmount, Token, Fraction, JSBI } from '@feswap/sdk'
import { MessageCircle as HelpCircle } from 'react-feather'      // MessageCircle  Coffee
import { Text } from 'rebass'
import styled, { ThemeContext } from 'styled-components'
import { ButtonError, ButtonLight } from '../../components/Button'
import { AutoColumn } from '../../components/Column'
import CurrencyInputPanel from '../../components/CurrencyInputPanel'
import { RowBetween, RowFixed } from '../../components/Row'
import { MouseoverTooltip } from '../../components/Tooltip'
import { BottomGrouping, Wrapper } from '../../components/swap/styleds'
import PageHeader from '../../components/PageHeader'
import {StyledPageCard} from '../../components/earn/styled'
import { useTransactionAdder } from '../../state/transactions/hooks'
import { useArkreenRECTokenContract, useARECTokenAddress } from '../../hooks/useContract'
import { useCurrency } from '../../hooks/Tokens'
import { tryParseAmount } from '../../state/swap/hooks'
import { BigNumber } from 'ethers'
import { calculateGasMargin } from '../../utils'
import { TransactionResponse } from '@ethersproject/providers'
import { useGetActionList, RECRequest, useGetARECInfo, useGetARECTotalLiquidized, useRatioFeeToSolidify } from '../../state/issuance/hooks'
import { useCurrencyBalances } from '../../state/wallet/hooks'
import Loader from '../../components/Loader'
import { useActiveWeb3React } from '../../hooks'
import { useWalletModalToggle } from '../../state/application/hooks'
import { Container } from '../../components/CurrencyInputPanel'
import { ErrorPromptModal } from '../../components/ARecIssuance'
import { useMedia } from 'react-use'
import Card from '../../components/Card'
import { SeparatorBlack } from '../../components/SearchModal/styleds'

import TransactionConfirmationModal, { ConfirmationModalContentTitle } from '../../components/TransactionConfirmationModal'
import AppBody from '../AppBody'
import QuestionHelper from '../../components/QuestionHelper'
import { OffsetSolidyButton, M } from '../../components/ARecIssuance'
import { getPowerAndString } from '../Overview'
import { TYPE } from '../../theme'

function RetirementHelpInfo( ) {
  return (<>
            <Text> This is to solidify ART tokens to claim AREC NFTs.
              Solidified ART tokens are burned forever, and the claimed AREC NFTs are sent to your wallet.
              You need to pay some fee proportional to the RE amount contained in the claimed AREC NFTs,
              normally in the rate of 10%. </Text>
            <Text> <M>1.</M> Connect your wallet on Polygon. </Text>
            <Text> <M>2.</M> Input how many ART tokens you want to pay for solidifying. Some information 
                        related to the claimed AREC NFTs will be shown. Please check these information. </Text>
            <Text> <M>3.</M> Click <M>Solidify ART to Claim AREC</M>.</Text>
            <Text> <M>4.</M> Check all the information displayed in the confirmation window, click 
                        the button <M>Confirm to Solidify ART</M> if no issues.</Text>
            <Text> <M>5.</M> Confirm and sign the transaction with your wallet.</Text>
          </>
        )
  }

  const HelpForSolidify1 = <> While the ART tokens are solidified, they are actually burned, and 
                        some AREC NFTs containing close amount of renewable energy will be claimed 
                        to your wallet. Arkreen always try to match AREC NFTs as much as possible, unmatched
                        ART tokens will keep untouched. You need to some fees for solidifying ART tokens. </>

  const ButtonRow = styled.div`
    display: grid;
    width: 100%;
    height: 20px
    grid-template-columns: 20px 1fr 20px;
    column-gap: 6px;
    align-items: start;
    justify-content: space-between;
  `  

export default function Solidify() {

  const { account, chainId } = useActiveWeb3React()

  const theme = useContext(ThemeContext)
  const below540 = useMedia('(max-width: 540px)')

  // toggle wallet when disconnected
  const toggleWalletModal = useWalletModalToggle()

  const { AllLiquidizedARECCount,
      } = useGetActionList()
  
  const ARECTokenAddress = useARECTokenAddress()
  const currencyARECT = useCurrency(ARECTokenAddress)
  const balanceARECT = useCurrencyBalances(account ?? undefined, [currencyARECT??undefined])[0]
  const [userInput, setUserInput] = useState<string>('')

  const amountInput : CurrencyAmount | undefined = useMemo(() => {
      if( userInput === '') return undefined
      return tryParseAmount(userInput, currencyARECT ?? undefined)
    },
    [currencyARECT, userInput]
  )

  const totalAREClLiquidized = useGetARECTotalLiquidized()
  const infoAREC = useGetARECInfo(3)
  const ratioFeeToSolidify = useRatioFeeToSolidify()

  const [totalAREClLiquidizedString, unitAREClLiquidizedString] = 
                        totalAREClLiquidized ? getPowerAndString(totalAREClLiquidized) : ['', '']

  const [minARTToLiquidizeString, unitARTToLiquidizeString] = 
                        infoAREC ? getPowerAndString(infoAREC.amountAREC[0].amountREC) : ['', '']

  const [minARTToLiquidize, minARTAmount] = useMemo(()=>{
    let minARTToLiquidize, minARTAmount
    if( infoAREC && currencyARECT && ratioFeeToSolidify ) {
      minARTToLiquidize = infoAREC.amountAREC[0].amountREC as BigNumber
      const solidifyAllValue = ratioFeeToSolidify.mul(minARTToLiquidize).add('10000').div('10000').add(minARTToLiquidize)
      minARTAmount = new TokenAmount(currencyARECT as Token, JSBI.BigInt(solidifyAllValue.toString()))
    }
    return [minARTToLiquidize, minARTAmount]
  },[infoAREC, currencyARECT, ratioFeeToSolidify])

  const amountInputString = useMemo(()=>{
    if(!amountInput) return ''
    return amountInput.toSignificant(4)
  },[amountInput])

  const contractARECT = useArkreenRECTokenContract(true)
  const atMaxAmountInput = Boolean(balanceARECT && amountInput?.equalTo(balanceARECT))
  const atMinAmountInput = Boolean(minARTToLiquidize && minARTAmount && amountInput?.equalTo(minARTAmount))

  const handleMaxInput = useCallback(() => {
    balanceARECT && setUserInput(balanceARECT.toExact())
  }, [balanceARECT])

  const handleMinInput = useCallback(() => {
    if(!minARTToLiquidize || !minARTAmount || !currencyARECT) return 
    setUserInput(minARTAmount.toExact())
  }, [minARTToLiquidize, currencyARECT, minARTAmount])

  const ifMoreInput = useMemo(()=>{
    if( !amountInput  || !balanceARECT) return false
    if(amountInput.greaterThan(balanceARECT)) return true
    return false
  },[balanceARECT, amountInput])

  const [solidifyValue, setSolidifyValue] = useState<BigNumber|undefined>()
  const [solidifiedAmount, setSolidifiedAmount] = useState<BigNumber|undefined>()
  const [numberAREC, setNumberAREC] = useState<BigNumber|undefined>()
  const [feeSolidify, setFeeSolidify] = useState<BigNumber|undefined>()
  const [errString, setErrString] = useState<string>('')


  useEffect(()=>{

    async function trySolidify() {
 
      if((!contractARECT) || (!amountInput) || !ratioFeeToSolidify || amountInput?.equalTo('0') ) return
      const amountInputValue = BigNumber.from(amountInput.raw.toString())

      try {
        const [_solidifiedAmount, _numberAREC, _feeSolidify] = await contractARECT.callStatic['solidify'](amountInputValue)
        setErrString('')
        const solidifiedAmount = _solidifiedAmount as BigNumber
        const solidifyValue = ratioFeeToSolidify.mul(solidifiedAmount).add(10000).div(10000).add(solidifiedAmount)
        setSolidifiedAmount(solidifiedAmount)
        setNumberAREC(_numberAREC as BigNumber)
        setFeeSolidify(_feeSolidify as BigNumber)
        setSolidifyValue(solidifyValue)

      }  catch (error) {
        const errorMessage = (error as any).data.message as string
        if(errorMessage?.startsWith("execution reverted: ")) {
          setErrString(errorMessage.substring(20)) 
        } else {
          console.log("Error AAAAAAAA:", error )
        }
        setSolidifyValue(undefined)
        setSolidifiedAmount(undefined)
        setNumberAREC(undefined)
        setFeeSolidify(undefined)
      }
    }

    if((!contractARECT) || (!amountInput) || ifMoreInput || amountInput?.equalTo('0') ) return
    trySolidify()

  },[contractARECT, amountInput, ratioFeeToSolidify, ifMoreInput])

  const [solidifiedAmountString, feeSolidifyString, solidifyValueString] = useMemo(()=>{
    if( !solidifiedAmount || solidifiedAmount.isZero() || !feeSolidify || !solidifyValue ) return ['', '', '']

    const solidifiedAmountString = (new Fraction(solidifiedAmount.toString(), JSBI.BigInt(1000_000_000))).toFixed(3) + " ART"
    const feeSolidifyString = (new Fraction(feeSolidify.toString(), JSBI.BigInt(1000_000_000))).toFixed(3) + " ART"
    const solidifyValueString = (new Fraction(solidifyValue.toString(), JSBI.BigInt(1000_000_000))).toFixed(3) + " ART"

    return [solidifiedAmountString, feeSolidifyString, solidifyValueString]
  },[solidifiedAmount, feeSolidify, solidifyValue])

  const [{ showConfirm, txnToConfirm, attemptingTxn, errorMessage, txHash }, setARECTxnState] = useState<{
    showConfirm: boolean
    txnToConfirm: RECRequest | undefined
    attemptingTxn: boolean
    errorMessage: string | undefined
    txHash: string | undefined
  }>({
    showConfirm: false,
    txnToConfirm: undefined,
    attemptingTxn: false,
    errorMessage: undefined,
    txHash: undefined
  })

  const addTransaction = useTransactionAdder()

  async function handleClearErrorMessage() {
    setARECTxnState({ attemptingTxn, txnToConfirm, showConfirm, errorMessage: undefined, txHash })
  }


  const handleConfirmDismiss = useCallback(() => {
    setARECTxnState({ attemptingTxn, txnToConfirm, showConfirm: false, errorMessage, txHash })
  }, [attemptingTxn, txnToConfirm, errorMessage, txHash])

  const [isWaitingWallet, setIsWaitingWallet] = useState<boolean>(false)
 
  async function handleSolidify() {

    if((!contractARECT) || (!amountInput) || amountInput?.equalTo('0') ) return

    setIsWaitingWallet(true)
    setARECTxnState({ attemptingTxn: true, txnToConfirm, showConfirm, errorMessage: undefined, txHash: undefined })
    await contractARECT.estimateGas['solidify'](solidifyValue)
        .then(async(estimatedGasLimit) => {
        await contractARECT.solidify(solidifyValue, { gasLimit: calculateGasMargin(estimatedGasLimit) })
        .then((response: TransactionResponse) => {
          setUserInput('')
          setIsWaitingWallet(false)
          addTransaction(response, {
            summary: `Solidify ART token: ${amountInputString} ART`
          })
          setARECTxnState({ attemptingTxn: false, txnToConfirm, showConfirm, errorMessage: undefined, txHash: response.hash })
        })
        .catch((error: any) => {
            setIsWaitingWallet(false)
            // if the user rejected the tx, pass this along
            if (error?.code === 4001) {
                throw new Error(`Solidify ART to Claim AREC: You denied transaction signature.`)
            } else {
              // otherwise, the error was unexpected and we need to convey that
              throw new Error(`Solidify ART to Claim AREC: ${error.message}`)
            }
        })
      })
      .catch((error: any) => {
        setIsWaitingWallet(false)
        console.log("Error of Solidify ART to Claim AREC:", error)
        const dataMsg = error?.data?.message ? ' Details: ' + error.data.message : ''
        setARECTxnState({attemptingTxn: false, txnToConfirm, showConfirm, errorMessage: error.message + dataMsg, txHash: undefined })
      })
  }

  const attemptString = `You are solidifying: ${amountInputString} ART, to claim AREC NFTs containing close amount of
                        renewable energy.`

  function modalHeader() {
    return (
      <AutoColumn gap={'sm'} style={{ marginTop: '20px' }}>
        <Container style={{boxShadow:"inset 0px 0px 8px #00913A", margin:'0rem 0rem'}}>
          <AutoColumn gap="4px" style={{padding: "0.75rem 1rem 0.75rem 1rem"}}>
              <RowBetween align="center" height='20px'>
                <Text fontWeight={500} fontSize={14} color={theme.text2}> Total ART desired to solidify: </Text>
                <Text fontWeight={700} fontSize={14} color={theme.primary1}> {amountInput?.toFixed(3)} ART</Text>
              </RowBetween>
              <SeparatorBlack style={{margin:'0.2rem 0rem'}} />
              <RowBetween align="center" height='20px'>
                <RowFixed>
                  <Text fontWeight={500} fontSize={14} color={theme.text2}> Amount of ART to be solidified: </Text>
                </RowFixed>
                <Text fontWeight={700} fontSize={14} color={theme.text2}> {solidifiedAmountString} </Text>
              </RowBetween>
              <RowBetween align="center" height='20px'>
                <RowFixed>
                  <Text fontWeight={500} fontSize={14} color={theme.text2}> Number of AREC NFTs Received: </Text>
                </RowFixed>
                <Text fontWeight={700} fontSize={14} color={theme.text2}> {numberAREC?.toNumber()} </Text>
              </RowBetween>
              <RowBetween align="center" height='20px'>
                <RowFixed>
                  <Text fontWeight={500} fontSize={14} color={theme.text2}> Fee to Solidify ART: </Text>
                </RowFixed>
                <Text fontWeight={700} fontSize={14} color={theme.text2}> {feeSolidifyString} </Text>
              </RowBetween>   
              <RowBetween align="center" height='20px'>
                <RowFixed>
                  <Text fontWeight={500} fontSize={14} color={theme.text2}> Total ART Amount to Pay: </Text>
                </RowFixed>
                <Text fontWeight={700} fontSize={14} color={theme.primaryText1}> {solidifyValueString} </Text>
              </RowBetween>   
          </AutoColumn>
        </Container>  
        <AutoColumn justify="flex-start" gap="sm" style={{ padding: '12px 0 0 0px' }}>
          <TYPE.italic textAlign="left" style={{ width: '100%' }}>
            {`The amount of ART to be solidified is estimated. You will receive maximum AREC NFTs within the 
              ATR amount you specified, but it maybe different from the amount shown above. `}
          </TYPE.italic>
        </AutoColumn>
      </AutoColumn>
    )
  }

  function modalBottom() {
    return (
      <RowBetween>
        <ButtonError
          onClick={() => handleSolidify() }
          id="liquidize-button"
        >
          <Text fontSize={20} fontWeight={500}>
            Confirm to Solidify ART
          </Text>
        </ButtonError>
    </RowBetween>
    )
  }

//  <AppBody style={{marginTop: below540 ?'30px': '60px'}} >

  return (
    <>
    <OffsetSolidyButton path={'Solidify'} />
    <AppBody>
      <StyledPageCard bgColor={'red'}>
        <PageHeader header={'ART Solidify'}>
          { chainId && ( <QuestionHelper bkgOff={true} text={'ART Solidify'} info={<RetirementHelpInfo/>} /> ) } 
        </PageHeader>
        <Wrapper id="issuance-page">
          <ErrorPromptModal isOpen={!!errorMessage} errString={errorMessage} onDismiss={handleClearErrorMessage} />
          
          <TransactionConfirmationModal
            isOpen={showConfirm && !errorMessage}
            onDismiss={handleConfirmDismiss}
            attemptingTxn={attemptingTxn}
            hash={txHash ? txHash : ''}
            content={() => (
              <ConfirmationModalContentTitle
                title={'You will solidify'}
                onDismiss={handleConfirmDismiss}
                topContent={modalHeader}
                bottomContent={modalBottom}
              />
            )}
            pendingText={attemptString}
            pendingTitle={'Solidify ART to Claim AREC'}
            submittedTitle={'Solidify ART Submitted'}
          />

          <AutoColumn gap={'md'}>
            <Container style={{boxShadow:"inset 0px 0px 8px #00913A", margin:'0rem 0rem'}}>
              <AutoColumn gap="4px" style={{padding: "0.75rem 1rem 0.75rem 1rem"}}>
                  <RowBetween align="center" height='20px'>
                    <Text fontWeight={500} fontSize={14} color={theme.text2}> ART Token Amount: </Text>
                    { (balanceARECT === undefined) ? (
                        <Loader  />
                      ) : (
                        <Text fontWeight={700} fontSize={14} color={theme.primaryText1}> {balanceARECT.toSignificant(4)} ART</Text>
                      )
                    }
                  </RowBetween>

                  { (AllLiquidizedARECCount !== undefined) && (
                    <RowBetween align="center" height='20px'>
                      <Text fontWeight={500} fontSize={14} color={theme.text2}> Number of Liquidized AREC NFT: </Text>
                      <Text fontWeight={700} fontSize={14} color={theme.text2}> {AllLiquidizedARECCount} </Text>
                    </RowBetween>
                  )}

                  { (totalAREClLiquidized !== undefined) && (
                    <RowBetween align="center" height='20px'>
                      <Text fontWeight={500} fontSize={14} color={theme.text2}> RE Amount of All Liquidized AREC: </Text>
                      <Text fontWeight={700} fontSize={14} color={theme.text2}> 
                                    {totalAREClLiquidizedString} {unitAREClLiquidizedString} </Text>
                    </RowBetween>
                  )}
                  { (infoAREC !== undefined) && (
                    <RowBetween align="center" height='20px'>
                      <Text fontWeight={500} fontSize={14} color={theme.text2}> Minimum Amount of ART to solidify: </Text>
                      <Text fontWeight={700} fontSize={14} color={theme.text2}> 
                                    {minARTToLiquidizeString} {unitARTToLiquidizeString} </Text>
                    </RowBetween>
                  )}

              </AutoColumn>
            </Container>  
            <CurrencyInputPanel
              label={'ART Amount to Pay:'}
              value={userInput}
              showMaxButton={!atMaxAmountInput}
              showMinButton={!atMinAmountInput}
              currency={currencyARECT}
              onUserInput={setUserInput}
              onMax={handleMaxInput}
              onMin={handleMinInput}
              disableCurrencySelect = {true}
              id="sponsor-currency-input"
              customBalanceText = 'Balance: '
            />

            { (solidifiedAmountString) &&
              <Card padding={'.25rem .75rem 0 .75rem'} borderRadius={'20px'}>
                <AutoColumn gap="4px">
                    <RowBetween align="center">
                        <RowFixed>
                          <Text fontWeight={500} fontSize={14} color={theme.text2}>
                            Amount of ART to be solidified:
                          </Text>
                          <QuestionHelper bkgOff={true} small={'s'} info={<>
                            This is the estimated ART amount to be solidified to claim AREC NFTs. The amount of RE contained
                            in the AREC NFTS you will receive is exactly equal to the amount of ART solidified. All 
                            solidified ART tokens are burned forever.</>}/>
                        </RowFixed>
                        <Text fontWeight={700} fontSize={14} color={theme.text2}>
                          {solidifiedAmountString}
                        </Text>
                    </RowBetween>
                    <RowBetween align="center">
                        <RowFixed>
                          <Text fontWeight={500} fontSize={14} color={theme.text2}>
                            Number of AREC NFTs Received:
                          </Text>
                          <QuestionHelper bkgOff={true} small={'s'} info={<>
                            This is the number of AREC NTFs you will receive by solidifying some ART tokens. Arkreen will match 
                            the liquidized AREC NFTs as much as possible to meet your specified ART amount. As AREC NFT can 
                            only be claimed as a whole, it may happen that only part of the amount you have specified to solidify 
                            can be matched. All the AREC NFTs you will receive will be in normal state 
                            instead of liquidized state.</>} />
                        </RowFixed>
                        <Text fontWeight={700} fontSize={14} color={theme.text2}>
                          {numberAREC?.toNumber()}
                        </Text>
                    </RowBetween>
                    <RowBetween align="center">
                          <RowFixed>
                            <Text fontWeight={500} fontSize={14} color={theme.text2}>
                              Fee to Solidify ART:
                            </Text>
                            <QuestionHelper bkgOff={true} small={'s'} info={<>
                                This is the fee to solidify ART to claim AREC NFTs. This fee is proportional to the RE amount 
                                contained in the claimed AREC NFTs, normally in the rate of 10%, which may be subject to 
                                change based on Arkreen governance rules. </>}/>
                          </RowFixed>
                          <Text fontWeight={700} fontSize={14} color={theme.text2}>
                            {feeSolidifyString}
                          </Text>
                    </RowBetween>   
                    <RowBetween align="center">
                          <RowFixed>
                            <Text fontWeight={500} fontSize={14} color={theme.text2}>
                              Total ART Amount to Pay:
                            </Text>
                            <QuestionHelper bkgOff={true} small={'s'} info={<>
                                This is the total amount of ART tokens you need to pay to claim the AREC NFTs, which includes
                                the solidifying fee and the ART tokens burned to claimed the AREC. </>}/>
                          </RowFixed>
                          <Text fontWeight={700} fontSize={14} color={theme.primaryText1}>
                            {solidifyValueString}
                          </Text>
                    </RowBetween>   
                </AutoColumn>
              </Card>
          }

          </AutoColumn>
          <BottomGrouping>
            {!account ? (
                <ButtonLight onClick={toggleWalletModal}> Connect Wallet </ButtonLight>
              ) : (userInput === '' || !amountInput) ? (
                <ButtonError disabled={true} error={false}>
                  <Text fontSize={20} fontWeight={500}>  
                    Input Solidify Amount
                  </Text>
                </ButtonError>
              ) : ((balanceARECT === undefined) || (balanceARECT.equalTo('0') )) ? (
                <ButtonError disabled={true} error={false}>
                  <Text fontSize={20} fontWeight={500}>  
                    No AREC Token to Solidify
                  </Text>
                </ButtonError>
              ) : (ifMoreInput) ? (
                <ButtonError disabled={true} error={false}>
                  <Text fontSize={20} fontWeight={500}>  
                    Solidify Too More
                  </Text>
                </ButtonError>                
              ) : (errString !== '') ? (
                <ButtonError disabled={true} error={false}>
                  <Text fontSize={20} fontWeight={500}>  
                    { errString  }
                  </Text>
                </ButtonError>                
              ) : (
                  <RowBetween marginTop="10px">
                    <ButtonError
                      disabled={isWaitingWallet}
                      onClick={() => {    
                        setARECTxnState({ attemptingTxn: false, 
                                          txnToConfirm, 
                                          showConfirm: true,
                                          errorMessage: undefined, 
                                          txHash: undefined })
                        }}
                    >
                      <ButtonRow>
                        <div />
                        <Text fontSize={20} fontWeight={500}>
                          Solidify ART to Claim AREC
                        </Text>
                        { below540 ? <div/> :
                          <MouseoverTooltip info ={HelpForSolidify1} >
                            <HelpCircle size="20" color={theme.text5} style={{ marginLeft: '8px' }} />
                          </MouseoverTooltip>
                        }
                      </ButtonRow>
                    </ButtonError>
                  </RowBetween>
              )
            }
          </BottomGrouping>
        </Wrapper>
      </StyledPageCard>
    </AppBody>
    </>
  )
}