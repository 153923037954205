import React from 'react'
import styled from 'styled-components'

export const BodyWrapper = styled.div`
  position: relative;
  max-width: 480px;
  width: 100%;
  background-color: ${({ theme }) => theme.bg1};
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.01), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04),
  0px 24px 32px rgba(0, 0, 0, 0.01);
  border-radius: 16px;
  padding: 0rem;
`

//width: ${({ mobile }) => (mobile ? '420px': '540px') };
export const CellWrapper = styled.div<{mobile: boolean}>`
  width: 540px;
  background-color: ${({ theme }) => theme.bg2};
  border: 1px, solid, ${({ theme }) => theme.primary2};
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.01), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04),
  0px 24px 32px rgba(0, 0, 0, 0.01);
  border-radius: 6px;
  padding: 0rem;
  margin: 0.5rem;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    width: 420px;
`};

`



//box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.01), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04),
//0px 24px 32px rgba(0, 0, 0, 0.01);      // original


/**
 * The styled container element that wraps the content of most pages and the tabs.
 */
export default function AppBody({ style, children, ...rest }: { style?: React.CSSProperties, children: React.ReactNode }) {
  return <BodyWrapper style={style} {...rest}>{children}</BodyWrapper>
}

export function CellBody({ mobile,  style, children, ...rest }: { mobile: boolean, style?: React.CSSProperties, children: React.ReactNode }) {
  return <CellWrapper mobile={mobile} style={style} {...rest}>{children}</CellWrapper>
}


export function AppBodyFat({ children }: { children: React.ReactNode }) {
  return <BodyWrapper style={{maxWidth: '640px'}}>{children}</BodyWrapper>
}
