import { useEffect, useState } from 'react'
import { useActiveWeb3React } from '../../hooks'
import { calculateGasMargin } from '../../utils'
import { TransactionResponse } from '@ethersproject/providers'
//import { ChainId, Token } from '@feswap/sdk'
import { useTransactionAdder } from '../transactions/hooks'
import { BigNumber } from 'ethers'
import { useSingleCallResult, useSingleContractMultipleData, NEVER_RELOAD } from '../multicall/hooks'
import { ZERO_ADDRESS } from '../../constants'
import { useMemo } from 'react'
import { DateTime } from 'luxon'
//import axios from "axios";

import {  useRECIssuanceContract, useArkreenRetirementContract, useArkreenRECTokenContract, useArkreenMinerContract,
  useARECTokenAddress, useArkreenRetirementAddress } from '../../hooks/useContract'
import { useGetARECConfirmCounter, useSetARECConfirmCounter } from '../user/hooks'
import { ChainId } from '@feswap/sdk'
//import { isUndefined } from 'lodash'


export interface RECRequest {
  issuer:       string
  startTime:    BigNumber;
  endTime:      BigNumber;
  amountREC:    BigNumber;
  cID:          string;
  region:       string;      
  url:          string;
  memo:         string;
} 

export interface SignatureToPay {
  token:      string
  value:      BigNumber;
  deadline:   BigNumber;  
  v:          BigNumber;
  r:          BigNumber;
  s:          BigNumber;              
}

export interface RECData {
  issuer:       string;
  serialNumber: string;
  minter:       string;
  startTime:    number;
  endTime:      number;
  amountREC:    BigNumber;
  status:       number;
  cID:          string;
  region:       string;
  url:          string;
  memo:         string;
}

export enum REC_STATUS {
  Pending,            // 0
  Rejected,           // 1
  Cancelled,          // 2
  Certified,          // 3
  Retired,            // 4
  Liquidized          // 5
}

export interface  OffsetAction {
  offsetEntity:   string
  issuerREC:      string        
  amount:         BigNumber
  tokenId:        BigNumber                
  createdAt:      BigNumber
  bClaimed:       boolean
}

export enum MINER_TYPE {
  GAME_MINER    =1,           // 1
  REMOTE_MINER  =2,           // 2
}

export interface  BadgeInfo {
  offsetEntity:         string
  beneficiary:          string
  offsetEntityID:       string
  beneficiaryID:        string
  offsetMessage:        string
  creationTime:         BigNumber
  offsetTotalAmount:    BigNumber
  offsetIds:            BigNumber[]
}

export interface REData {
  miner:            string
  startDate:        string
  endDate:          string
  lastDate:         string
  totalPowerOutput: string
  totalREOutput:    string
  region:           string
}

export interface RequestIssueOwnerRec {
  owner?:             string
  issuer?:            string
  startDate?:         string
  endDate?:           string
  totalARECPower?:    string  
  valueApproval?:     string
  deadlineApproval?:  number
  signatureApproval?: string
}

export interface RecCID {
  cid:              string
  uri?:             string
}

export interface MinerInfo {
  address:          string
  type:             number
  maker:            string
  power:            string
  REOutput:         string
}

export interface MinerNFT {
  owner:            string;
  mAddress:         string;
  mType:            number;
  mStatus:          number;
  timestamp:        number;
} 

//export const arkreenToken = new Token(ChainId.MATIC_TESTNET, arkreenTokenAddress, 18, 'AKRE', 'Arkreen DAO Token')

export function useRECIssuanceCallback(
  recRequest:     RECRequest,
  signatureToPay: SignatureToPay
): {
  recIssuanceCallback: () => Promise<string>
} {
  // get claim data for this account
  const { library, chainId, account } = useActiveWeb3React()
  const arkreenRECIssuanceContract = useRECIssuanceContract(true)  

  // used for popup summary
  const addTransaction = useTransactionAdder()

  const recIssuanceCallback = async function() {
//    if (!sponsorAmount || !account || !library || !chainId|| !sponsorContract ) return
  if ( !account || !library || !chainId || !arkreenRECIssuanceContract ) return

   return arkreenRECIssuanceContract.estimateGas['mintRECRequest'](recRequest, signatureToPay).then(estimatedGasLimit => {
      return arkreenRECIssuanceContract
        .mintRECRequest(recRequest, signatureToPay, {gasLimit: calculateGasMargin(estimatedGasLimit) })
        .then((response: TransactionResponse) => {
          addTransaction(response, {
            summary: `mintRECRequest from ${recRequest?.issuer}`,
          })
          return response.hash
        })
    })
  }

  return { recIssuanceCallback }
}

// Get AREC retirement NFT number
export function useARECRetirementTotalSupply(): number | undefined {
  const arkreenRetirementContract = useArkreenRetirementContract(false)     
  const res = useSingleCallResult(arkreenRetirementContract, 'totalSupply')
  if (res.result && !res.loading) {
    return parseInt(res.result[0])
  }
  return undefined
}

// Get AREC retirement NFT number
export function useARECRetirementTotalAmount(): BigNumber | undefined {
  const arkreenRetirementContract = useArkreenRetirementContract(false)     
  const res = useSingleCallResult(arkreenRetirementContract, 'totalOffsetRetired')
  if (res.result && !res.loading) {
    return BigNumber.from(res.result[0])
  }
  return undefined
}

// Get AREC Nft count of the current user
export function useBadgeCount(address?: string): number | undefined {
  const { account } = useActiveWeb3React()
  const arkreenRetirementContract = useArkreenRetirementContract(false)    
  const res = useSingleCallResult(arkreenRetirementContract, 'balanceOf', [address ?? account ?? ZERO_ADDRESS])
  if (res.result && !res.loading) {
    return parseInt(res.result[0])
  }
  return undefined
}

// Get AREC Nft count of the current user
export function useARECCount(address?: string): number | undefined {
  const { account } = useActiveWeb3React()
  const arkreenRECIssuanceContract = useRECIssuanceContract(false)    
  const res = useSingleCallResult(arkreenRECIssuanceContract, 'balanceOf', [address ?? account ?? ZERO_ADDRESS])
  if (res.result && !res.loading) {
    return parseInt(res.result[0])
  }
  return undefined
}

// Get AREC Nft count of the current user
export function useARECIdByIndex(index: number): number | undefined {
  const { account } = useActiveWeb3React()
  const arkreenRECIssuanceContract = useRECIssuanceContract(false)    
  const res = useSingleCallResult(arkreenRECIssuanceContract, 'tokenOfOwnerByIndex', [account ?? ZERO_ADDRESS, index])
  if (account && res.result && !res.loading) {
    return parseInt(res.result[0])
  }
  return undefined
}

// Get AREC Nft total supply
export function useARECTotalSupply(): number | undefined {
  const arkreenRECIssuanceContract = useRECIssuanceContract(false)    
  const res = useSingleCallResult(arkreenRECIssuanceContract, 'totalSupply', [])
  if (res.result && !res.loading) {
    return parseInt(res.result[0])
  }
  return undefined
}

// Get all AREC amount issued
export function useAllREAIssued(): BigNumber | undefined {
  const arkreenRECIssuanceContract = useRECIssuanceContract(false)    
  const res = useSingleCallResult(arkreenRECIssuanceContract, 'allRECIssued')
  if (res.result && !res.loading) {
    return BigNumber.from(res.result[0])
  }
  return undefined
}

// Get all AREC amount Redeemed
export function useAllREARedeemed(): BigNumber | undefined {
  const arkreenRECIssuanceContract = useRECIssuanceContract(false)    
  const res = useSingleCallResult(arkreenRECIssuanceContract, 'allRECRedeemed')
  if (res.result && !res.loading) {
    return BigNumber.from(res.result[0])
  }
  return undefined
}

// Get all AREC amount Liquidized
export function useAllREALiquidized(): BigNumber | undefined {
  const arkreenRECIssuanceContract = useRECIssuanceContract(false)    
  const res = useSingleCallResult(arkreenRECIssuanceContract, 'allRECLiquidized')
  if (res.result && !res.loading) {
    return BigNumber.from(res.result[0])
  }
  return undefined
}

// Get AREC Issuance price by payment token
export function useIssuancePriceByToken(payToken: string): BigNumber | undefined {
  const arkreenRECIssuanceContract = useRECIssuanceContract(false)    
  const res = useSingleCallResult(arkreenRECIssuanceContract, 'paymentTokenPrice',[payToken])
  if (res.result && !res.loading) {
    return BigNumber.from(res.result[0])
  }
  return undefined
}

export function useAllActionIds(): BigNumber[] | undefined {
  const { account } = useActiveWeb3React()
  const arkreenRetirementContract = useArkreenRetirementContract(false)    

  const res = useSingleCallResult(arkreenRetirementContract, 'getUserEvents', [account ?? ZERO_ADDRESS])
  if (account && res.result && !res.loading) {
    return res.result[0]
  }
  return undefined
}

export function useGetUserARECList(): {
  numberOfARECNft:        number | undefined
  allARECInfo:            RECData[]
  allARECNftTokensID:     BigNumber[]
  totalRECAmountIssued:   BigNumber
  totalRECAmountPending:  BigNumber
} {
  const { account } = useActiveWeb3React()
  const arkreenRECIssuanceContract = useRECIssuanceContract(false)  
  const nftARECCount = useARECCount()
  
  // get all nft Token IDs
  const nftARECIndexes = []
  for (let i = 0; i < (nftARECCount ?? 0); i++) {
    nftARECIndexes.push([account??ZERO_ADDRESS, i])
  }
  const allARECNftTokensIDs = useSingleContractMultipleData(arkreenRECIssuanceContract, 'tokenOfOwnerByIndex', nftARECIndexes)

  // get all AREC nft Token Infos
  let allARECNftTokensIDList = []
  for (let i = 0; i < (allARECNftTokensIDs.length ?? 0); i++) {
    if(allARECNftTokensIDs[i]?.valid && !allARECNftTokensIDs[i]?.loading && !allARECNftTokensIDs[i]?.error)
      allARECNftTokensIDList.push([allARECNftTokensIDs[i].result?.[0]?? BigNumber.from(0)])
  }

  const allARECNftInfos = useSingleContractMultipleData(arkreenRECIssuanceContract, 'getRECData', allARECNftTokensIDList)

  let nftARECInfoList: RECData[] = []
  let allARECNftTokensID: BigNumber[] = []
  for (let i = 0; i < (allARECNftInfos.length ?? 0); i++) {
    if(allARECNftInfos[i]?.valid && !allARECNftInfos[i]?.loading && !allARECNftInfos[i]?.error) {
      nftARECInfoList.push(allARECNftInfos[i].result?.[0] as RECData)
      allARECNftTokensID.push(allARECNftTokensIDs[i].result?.[0]?? BigNumber.from(0))
    }
  }

  let totalRECAmountIssued: BigNumber = BigNumber.from(0)
  let totalRECAmountPending: BigNumber = BigNumber.from(0)
  let cancelledAREC: number = 0
  let rejectedAREC: number = 0

 
  for (let i = 0; i < (nftARECInfoList ? nftARECInfoList.length: 0); i++) {
    if((nftARECInfoList[i] as RECData)?.status === REC_STATUS.Cancelled) {
      cancelledAREC += 1
    } else if((nftARECInfoList[i] as RECData)?.status === REC_STATUS.Rejected) {
      rejectedAREC += 1
    } else if((nftARECInfoList[i] as RECData)?.status === REC_STATUS.Certified) {
      totalRECAmountIssued = totalRECAmountIssued.add((nftARECInfoList[i] as RECData)?.amountREC)
    } else {
      totalRECAmountPending = totalRECAmountPending.add((nftARECInfoList[i] as RECData)?.amountREC)
    }
  }

  return {  numberOfARECNft:      (nftARECCount !== undefined) ? nftARECCount- cancelledAREC - rejectedAREC : undefined,
            allARECInfo:          nftARECInfoList.filter((recData: RECData) => (recData.status !== REC_STATUS.Cancelled)),
            allARECNftTokensID:   allARECNftTokensID.filter((_,index) => (nftARECInfoList[index].status !== REC_STATUS.Cancelled)),
            totalRECAmountIssued,
            totalRECAmountPending,
  }
}

export function useGetPendingARECList(): {
  arecTotalSupply:        number | undefined
  numberOfARECNft:        number
  allARECInfo:            RECData[]
  allARECNftTokensID:     BigNumber[]
} {

  const arkreenRECIssuanceContract = useRECIssuanceContract(false)  
  const arecTotalSupply = useARECTotalSupply()
  const ARECConfirmedCount = useGetARECConfirmCounter()
  const setARECConfirmCounter = useSetARECConfirmCounter()

  // get all nft Token IDs
  const nftARECIndexes = []
  let allARECNftTokensID: BigNumber[] = []
  for (let i = ARECConfirmedCount; i < (arecTotalSupply ?? 0); i++) {
    nftARECIndexes.push([i+1])
    allARECNftTokensID.push(BigNumber.from(i+1))
  }

  const allARECNftInfos = useSingleContractMultipleData(arkreenRECIssuanceContract, 'getRECData', nftARECIndexes)

  let nftARECInfoList = []
  let pendingFound = false
  for (let i = 0; i < (nftARECIndexes.length ?? 0); i++) {
    if(allARECNftInfos[i]?.valid && !allARECNftInfos[i]?.loading) {
      nftARECInfoList.push(allARECNftInfos[i].result?.[0])

      if(!pendingFound){
        if((nftARECInfoList[i] as RECData)?.status === REC_STATUS.Certified) {
          setARECConfirmCounter(nftARECIndexes[i][0])

        } else if ( (nftARECInfoList[i] as RECData)?.status === REC_STATUS.Pending ||
                    (nftARECInfoList[i] as RECData)?.status === REC_STATUS.Rejected ) {
          pendingFound = true
        }
      }
    }
  }

  return {  arecTotalSupply,
            numberOfARECNft:      (arecTotalSupply? (arecTotalSupply - ARECConfirmedCount): 0),
            allARECInfo:          nftARECInfoList,
            allARECNftTokensID
  }
}

export function useGetMinerNFTList(miners?: string[]): {
  minersNFTID:    BigNumber[] | undefined
  minersNFTInfo:  MinerNFT[] | undefined
} {

  const arkreenMinerContract = useArkreenMinerContract(false)  

  const allMiner = []
  for (let i = 0; i < (miners?.length ?? 0); i++) {
    allMiner.push([miners?.[i]])
  }  
  
  const allMinersNFTID = useSingleContractMultipleData(arkreenMinerContract, 'AllMinersToken', allMiner)

  let minersNFTID: BigNumber[] = []
  for (let i = 0; i < (allMinersNFTID?.length ?? 0); i++) {
    if(allMinersNFTID[i]?.valid && !allMinersNFTID[i]?.loading  && !allMinersNFTID[i]?.error) {
      minersNFTID.push(allMinersNFTID[i].result?.[0])
    }
  }

  const allMinersNFTInfo = useSingleContractMultipleData(arkreenMinerContract, 'GetMinerInfo', allMiner)

  const minersNFTInfo: MinerNFT[] = []
  for (let i = 0; i < (allMinersNFTInfo?.length ?? 0); i++) {
    if(allMinersNFTInfo[i]?.valid && !allMinersNFTInfo[i]?.loading  && !allMinersNFTInfo[i]?.error) {
      const minerInfo: MinerNFT = {owner: allMinersNFTInfo[i].result?.[0], ...allMinersNFTInfo[i].result?.[1]}
      minersNFTInfo.push(minerInfo)
    }
  }
  
//  console.log("miners HHHHHHHHHHHHHHHHH",  miners, minersNFTID, minersNFTInfo)
  return {minersNFTID, minersNFTInfo}
}

export function useGetActionList(): {
  allOffsetActionsID:     BigNumber[] | undefined
  allOffsetActions:       OffsetAction[]
  allUnclaimedActionsIDs: BigNumber[] | undefined
  totalUnclaimedAmount:   BigNumber
  allUnclaimedActions:    OffsetAction[]
  allClaimedActionsIDs:   BigNumber[]
  totalClaimedAmount:     BigNumber
  AllLiquidizedARECCount: number | undefined
} {

  const arkreenRetirementContract = useArkreenRetirementContract(false)  
  const ARECTokenAddress = useARECTokenAddress()
  const AllLiquidizedARECCount = useARECCount(ARECTokenAddress)

  const allOffsetActionsID = useAllActionIds()

  let offsetActionsIDLIst: BigNumber[][] = []
  for (let i = 0; i < (allOffsetActionsID?.length ?? 0); i++) {
    offsetActionsIDLIst.push([allOffsetActionsID?.[i] ?? BigNumber.from(0)])
  }

  const allUserOffsetActions = useSingleContractMultipleData(arkreenRetirementContract, 'getOffsetActions', offsetActionsIDLIst)

  let allOffsetActions: OffsetAction[] = []
  for (let i = 0; i < (allUserOffsetActions?.length ?? 0); i++) {
    if(allUserOffsetActions[i]?.valid && !allUserOffsetActions[i]?.loading  && !allUserOffsetActions[i]?.error) {
      allOffsetActions.push(allUserOffsetActions[i].result?.[0])
    }
  }

  const { totalUnclaimedAmount, totalClaimedAmount, allUnclaimedActionsIDs, 
          allClaimedActionsIDs, allUnclaimedActions } = useMemo(()=> {
    let totalUnclaimedAmount: BigNumber = BigNumber.from(0)
    let totalClaimedAmount: BigNumber = BigNumber.from(0)
    let allUnclaimedActionsIDs: BigNumber[] | undefined = undefined
    let allClaimedActionsIDs: BigNumber[] = [] 
    let allUnclaimedActions: OffsetAction[] = []
  
    if( !(!allOffsetActionsID || !allOffsetActions || !offsetActionsIDLIst)) {
      allUnclaimedActionsIDs = []
      for (let i = 0; i <allOffsetActions.length; i++) {
//      if(allOffsetActions[i] === undefined) break
        if((allOffsetActions[i] as OffsetAction).bClaimed === false) {
//          if (isUndefined(allUnclaimedActionsIDs)) {
//            allUnclaimedActionsIDs = []
//          }
          allUnclaimedActionsIDs.push(offsetActionsIDLIst[i][0])
          totalUnclaimedAmount = totalUnclaimedAmount.add((allOffsetActions[i] as OffsetAction).amount)
          allUnclaimedActions.push(allOffsetActions[i])
        } else {
          allClaimedActionsIDs.push(offsetActionsIDLIst[i][0])
          totalClaimedAmount = totalClaimedAmount.add((allOffsetActions[i] as OffsetAction).amount)
        }
      }
    }
    return {  totalUnclaimedAmount, totalClaimedAmount, allUnclaimedActionsIDs, allClaimedActionsIDs,
               allUnclaimedActions }
    },[allOffsetActionsID, allOffsetActions, offsetActionsIDLIst])

  return {  allOffsetActionsID,
            allOffsetActions,
            allUnclaimedActionsIDs,
            totalUnclaimedAmount,
            allClaimedActionsIDs,
            totalClaimedAmount,
            allUnclaimedActions,
            AllLiquidizedARECCount
        }
}

export function useGetARECTBalance():  BigNumber | undefined {
  const { account } = useActiveWeb3React()
  const ARECTokenContract = useArkreenRECTokenContract(false)  
  const res = useSingleCallResult(ARECTokenContract, 'balanceOf', [account??ZERO_ADDRESS])
  if (account && res.result && !res.loading) {
    return (res.result[0])
  }
  return undefined
}

export function useGetARECTotalOffset():  BigNumber | undefined {
  const ARECTokenContract = useArkreenRECTokenContract(false)  
  const res = useSingleCallResult(ARECTokenContract, 'totalOffset')
  if (res.result && !res.loading) {
    return (res.result[0])
  }
  return undefined
}

export function useGetARECTotalLiquidized():  BigNumber | undefined {
  const ARECTokenContract = useArkreenRECTokenContract(false)  
  const res = useSingleCallResult(ARECTokenContract, 'totalLiquidized')
  if (res.result && !res.loading) {
    return (res.result[0])
  }
  return undefined
}

  export interface ARECAmount {
    ARECID:     BigNumber
    amountREC:  BigNumber
  }

  export interface LiquidizedARECInfo {
    numAREC:    BigNumber
    amountAREC:  ARECAmount[]
  }

export function useGetARECInfo(count: number): LiquidizedARECInfo | undefined {
  const ARECTokenContract = useArkreenRECTokenContract(false)  
  const res = useSingleCallResult(ARECTokenContract, 'getARECInfo', [count])
  if (res.result && !res.loading) {
    return ((res.result as unknown) as LiquidizedARECInfo)
  }
  return undefined
}

export function useRatioFeeToSolidify(): BigNumber | undefined {
  const ARECTokenContract = useArkreenRECTokenContract(false)  
  const res = useSingleCallResult(ARECTokenContract, 'ratioFeeToSolidify', undefined, NEVER_RELOAD)
  if (res.result && !res.loading) {
    return res.result[0] as BigNumber
  }
  return undefined
}

export function useGetARECTotalSupply():  BigNumber | undefined {
  const ARECTokenContract = useArkreenRECTokenContract(false)  
  const res = useSingleCallResult(ARECTokenContract, 'totalSupply')
  if (res.result && !res.loading) {
    return (res.result[0])
  }
  return undefined
}

export function useOverallARECInfo(): {
  AllARECCount:                 number | undefined
  AllRedeemedARECCount:         number | undefined
  AllLiquidizedARECCount:       number | undefined
  AllREAIssued:                 BigNumber | undefined
  AllREARedeemed:               BigNumber | undefined
  AllREALiquidized:             BigNumber | undefined
  allARECRetirementTotalSupply:  number | undefined
  AllARECRetirementTotalAmount:  BigNumber | undefined
  ARECTotalSupply:              BigNumber | undefined
  ARECTotalOffset:              BigNumber | undefined
} {
  
  const AllARECCount = useARECTotalSupply()
  const arkreenRetirementAddress = useArkreenRetirementAddress()
  const AllRedeemedARECCount = useARECCount(arkreenRetirementAddress)
  const ARECTokenAddress = useARECTokenAddress()
  const AllLiquidizedARECCount = useARECCount(ARECTokenAddress)

  const AllREAIssued = useAllREAIssued()
  const AllREARedeemed = useAllREARedeemed()
  const AllREALiquidized = useAllREALiquidized()

  const allARECRetirementTotalSupply = useARECRetirementTotalSupply()
  const AllARECRetirementTotalAmount = useARECRetirementTotalAmount()

  const ARECTotalSupply =  useGetARECTotalSupply()
  const ARECTotalOffset =  useGetARECTotalOffset()

  return {
        AllARECCount,
        AllRedeemedARECCount,
        AllLiquidizedARECCount,
        AllREAIssued,
        AllREARedeemed,
        AllREALiquidized,     
        allARECRetirementTotalSupply,
        AllARECRetirementTotalAmount,
        ARECTotalSupply,
        ARECTotalOffset
    }
}

export function useUserBadgeInfo(): {
  badgeCount:             number | undefined
  userARECBadgeIDList:     BigNumber[] | undefined
  userARECBadgeInfoList:   BadgeInfo[] | undefined

  } {
    const { account } = useActiveWeb3React()
    const ARECBadgeContract = useArkreenRetirementContract(false)      
    const badgeCount =  useBadgeCount(account??undefined)

    // get all badge token IDs
    const ARECBadgeIndexes = []
    for (let i = 0; i < (badgeCount ?? 0); i++) {
      ARECBadgeIndexes.push([account??ZERO_ADDRESS, i])
    }
    const allARECBadgeIDs = useSingleContractMultipleData(ARECBadgeContract, 'tokenOfOwnerByIndex', ARECBadgeIndexes)

    // get all AREC badge token Infos
    let allARECBadgeIDList = []
    for (let i = 0; i < (allARECBadgeIDs.length ?? 0); i++) {
      if(allARECBadgeIDs[i]?.valid && !allARECBadgeIDs[i]?.loading && !allARECBadgeIDs[i]?.error)
      allARECBadgeIDList.push([allARECBadgeIDs[i].result?.[0]?? BigNumber.from(0)])
    }

    const allARECBadgeInfos = useSingleContractMultipleData(ARECBadgeContract, 'getCertificate', allARECBadgeIDList)

    const userARECBadgeInfoList = []
    const userARECBadgeIDList: BigNumber[] =  []
    for (let i = 0; i < (allARECBadgeIDList.length ?? 0); i++) {
      if(allARECBadgeInfos[i]?.valid && !allARECBadgeInfos[i]?.loading && !allARECBadgeInfos[i]?.error)
      userARECBadgeInfoList.push(allARECBadgeInfos[i].result?.[0] as BadgeInfo)
      userARECBadgeIDList.push(allARECBadgeIDList[i][0])
    }
   
    return {badgeCount, userARECBadgeIDList, userARECBadgeInfoList}   
  }


export const AREC_URL_MATIC_TEST  = "https://101.35.163.23:9802/v1"        // 3001  9802
export const AREC_URL_MATIC       = "https://testapi.arkreen.com/v1"         

export function useARECURL() {
  const { chainId } = useActiveWeb3React()

  return useMemo(()=>{  
    return (chainId && (chainId === ChainId.MATIC)) ? AREC_URL_MATIC : AREC_URL_MATIC_TEST
  },[chainId])
}

export const MINER_URL_MATIC_TEST  = "https://101.35.163.23:9801/buy"        // 3001  9802
export const MINER_URL_MATIC       = "https://testconsole.arkreen.com/buy"         

export function useBuyMinerURL() {
  const { chainId } = useActiveWeb3React()

  return useMemo(()=>{  
    return (chainId && (chainId === ChainId.MATIC)) ? MINER_URL_MATIC : MINER_URL_MATIC_TEST
  },[chainId])
}



export function useFetchARECInfo({simulationMode, owner, endDate}:{
    simulationMode: boolean
    owner?:    string
    endDate?:  string
  }): {
    errorAREC:  string
    dataAREC?:  REData[],
    setDataAREC: (dataAREC: REData[] | undefined)=>void
  } {
    const [dataAREC, setDataAREC] = useState<REData[] | undefined>(undefined)
    const [errorAREC, setErrorAREC] = useState('')
    const AREC_URL = useARECURL()

//    console.log('simulationMode, owner, endDate', simulationMode, owner, endDate)
 
    useEffect(() => {

      const fetchData = async () => {
        setErrorAREC('')

        const parameter = {
          jsonrpc: "2.0",
          id: "1",
          method: "rec_getOwnerRecData",
          params: (endDate === undefined) ? {owner: owner} 
                  : { owner: owner, 
                      endDate: DateTime.fromFormat(endDate, "yyyy-MM-dd").toFormat("yyyyMMdd") 
                    }
        }

        try {
          // Default options are marked with *
          const response = await fetch(AREC_URL, {
            mode: 'cors',                                   // no-cors, *cors, same-origin
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(parameter) 
          });
          const arecJson = await response.json()
//         console.log('AAAAAAAAAAAA rec_getOwnerRecData', parameter, arecJson, DateTime.now().toISOTime() )
          if(arecJson.error) {
            console.log('Error in rec_getOwnerRecData', parameter, arecJson, DateTime.now().toISOTime() )
          }

          if (arecJson.error) {
            setErrorAREC(arecJson.error.message)
          } else if (arecJson.result) {
            setDataAREC(arecJson.result)
          } else{
            setErrorAREC("Unknown Error!")
          }
        } catch (error) {
          console.log('Error in rec_getOwnerRecData:', error, parameter, DateTime.now().toISOTime())
          setErrorAREC("Network Error!")
        }
      }
      if(simulationMode) return
      if(!owner) {
        setErrorAREC("No Wallet")
      } else {
        fetchData()
      }
    }, [simulationMode, owner, endDate, AREC_URL])
  
    return { errorAREC, dataAREC, setDataAREC }
  }

  export function useIssueOwnerRec(simulationMode: boolean, issueRequest: RequestIssueOwnerRec): {
    errorArecCID:  string
    arecCID?:   RecCID
  } {
    const [arecCID, setArecCID] = useState<RecCID | undefined>(undefined)
    const [errorArecCID, setErrorAREC] = useState('')
    const [deadline, setDeadline] = useState(0)
    const AREC_URL = useARECURL()
 
    useEffect(() => {

      const fetchData = async () => {
        setErrorAREC('')

        const timeout = 30000
        const controller = new AbortController()
        let idTimer = setTimeout(() => controller.abort(), timeout)

        const parameter = {
          jsonrpc: "2.0",
          id: "1",
          method: "rec_issueOwnerRec",
          params: issueRequest,
        }
        try {
          const response = await fetch(AREC_URL, {
            signal: controller.signal,
            mode: 'cors',                                   // no-cors, *cors, same-origin
            method: 'POST',
            headers:  {'Content-Type': 'application/json'},
            body: JSON.stringify(parameter) 
          });
          const arecJson = await response.json()

          if(arecJson.error) {
            console.log('Error in rec_issueOwnerRec', issueRequest, arecJson, DateTime.now().toISOTime() )
          }

          setArecCID(arecJson.result)

        } catch (error) {
          console.log('Error in rec_issueOwnerRec:', error, parameter, DateTime.now().toISOTime())
          setErrorAREC("Network Error!")
        }
        clearTimeout(idTimer)
      }

      if(simulationMode) return
      if(!issueRequest.owner) {
        setErrorAREC("No Wallet")
      } else if(!issueRequest.signatureApproval) {
        setErrorAREC("No Signature")
      } else {
        if( (deadline ===0) || (deadline !== issueRequest?.deadlineApproval) ) {
          setDeadline( issueRequest?.deadlineApproval ?? 0 )
          fetchData()
        }
      }
    }, [simulationMode, issueRequest, deadline, AREC_URL])
  
    return { errorArecCID, arecCID }
  }

  export function useMinerListByOwner(simulationMode: boolean, owner?: string): {
    errorMiner:  string
    minerList?:  MinerInfo[]
  } {
    const [minerList, setMinerList] = useState<MinerInfo[]>([])
    const [errorMiner, setErrorMiner] = useState('')
    const AREC_URL = useARECURL()
 
    useEffect(() => {

      const fetchData = async () => {
        setErrorMiner('')

        const parameter = {
          jsonrpc: "2.0",
          id: "1",
          method: "net_getMinerListByOwner",
          params: {
            "address":owner,
            "offset":1,
            "limit":20
          },
        }
        try {
          const response = await fetch(AREC_URL, {
            mode:   'cors',                                   // no-cors, *cors, same-origin
            method: 'POST',
            headers:  {'Content-Type': 'application/json'},
            body: JSON.stringify(parameter) 
          });
          const minerJson = await response.json()
//          console.log('Json data PPPPPPPPPPPPPPPP', owner, minerJson, DateTime.now().toISOTime() )

          if (minerJson.error) {
            setErrorMiner(minerJson.error.message)
          } else if (minerJson.result === undefined) {
            setErrorMiner("Unknown Error!")
          }
          const miner_list = minerJson.result.map((miner:any) => { 
            const miner_info:MinerInfo =  { address: miner.address, type: miner.minerType, 
                                            maker: miner.maker, power: miner.power,
                                            REOutput: miner.totalEnergyGeneration
                                          }
            return miner_info
          })

          setMinerList(miner_list)
        } catch (error) {
          console.log('Error in net_getMinerListByOwner: ', error, parameter, DateTime.now().toISOTime() )
          setErrorMiner("Network Error!")
        }
      }

      if(simulationMode) return
      if(!owner) {
        setErrorMiner("No Wallet")
      }   
      fetchData()
    }, [simulationMode, owner, AREC_URL])
  
    return { errorMiner, minerList }
  }


  
