import React, { useContext, useState, useCallback, useMemo } from 'react'
import { CurrencyAmount, Fraction, JSBI } from '@feswap/sdk'
import { MessageCircle as HelpCircle } from 'react-feather'      // MessageCircle  Coffee
import { Text } from 'rebass'
import styled, { ThemeContext } from 'styled-components'
import { ButtonError, ButtonLight } from '../../components/Button'
import { AutoColumn } from '../../components/Column'
import CurrencyInputPanel from '../../components/CurrencyInputPanel'
import { RowBetween } from '../../components/Row'
import { MouseoverTooltip } from '../../components/Tooltip'
import { BottomGrouping, Wrapper } from '../../components/swap/styleds'
import PageHeader from '../../components/PageHeader'
import {StyledPageCard} from '../../components/earn/styled'
import { useTransactionAdder } from '../../state/transactions/hooks'
import { useArkreenRECTokenContract, useARECTokenAddress } from '../../hooks/useContract'
import { useCurrency } from '../../hooks/Tokens'
import { tryParseAmount } from '../../state/swap/hooks'
import { BigNumber } from 'ethers'
import { calculateGasMargin, isAddress } from '../../utils'
import { TransactionResponse } from '@ethersproject/providers'
import { useGetActionList, RECRequest } from '../../state/issuance/hooks'
import { useCurrencyBalances } from '../../state/wallet/hooks'
import Loader from '../../components/Loader'
import { useActiveWeb3React } from '../../hooks'
import { useWalletModalToggle } from '../../state/application/hooks'
import { Container } from '../../components/CurrencyInputPanel'
import { GetCertificateInfo, ErrorPromptModal } from '../../components/ARecIssuance'
import { ZERO_ADDRESS } from '../../constants'
import { useMedia } from 'react-use'

import TransactionConfirmationModal, { ConfirmationModalContentTitle } from '../../components/TransactionConfirmationModal'
import AppBody from '../AppBody'
import QuestionHelper from '../../components/QuestionHelper'
import { OffsetSolidyButton, M } from '../../components/ARecIssuance'

  function RetirementHelpInfo( ) {
    return (<>
              <Text> This is to offset your carbon footprint by burning some ART tokens. 
                After the ART are burned, one offset action will be created 
                and recorded on the blockchain. Optionally you could mint an AREC 
                retirement badge at the same transaction </Text>
              <Text> <M>1.</M> Connect your wallet on Polygon. </Text>
              <Text> <M>2.</M> Input how many ART tokens to offset. </Text>
              <Text> <M>3.A</M> Either click <M>Offset ART Token</M> if you just want to offset the AREC 
                                tokens as a climate action, then check and sign your AREC offset transaction.</Text>
              <Text> <M>3.B</M> Or click <M>Offset and Mint Badge</M> if you want to offset
                          the ART tokens and also mint a retirement badge, then check and sign your 
                          AREC offset transaction.</Text>
              <Text> <M>4.</M> Waiting your AREC offset transaction to be confirmed on the blockchain.</Text>
              <Text> <M>Reminding:</M> If you offset your ART tokens as a retirement action, you could 
                          mint AREC retirement badge freely at any later time.</Text>
            </>
          )
  }

  const HelpForOffset1 = <>After the ART tokens are offset, one offset action will be created and 
                        recorded on blockchain. You could mint an AREC retirement NFT badge with these 
                        offset actions at anytime later.</>

  const HelpForOffset2 = <>After the ART tokens are offset, one offset action will be created and 
                        recorded on blockchain, and an AREC retirement NFT badge will be minted with 
                        this offset action at the same transaction.</>                      

  const ButtonRow = styled.div`
    display: grid;
    width: 100%;
    height: 20px
    grid-template-columns: 20px 1fr 20px;
    column-gap: 6px;
    align-items: start;
    justify-content: space-between;
  `  

export default function Offset() {

  const { account, chainId } = useActiveWeb3React()
  const theme = useContext(ThemeContext)
  const below540 = useMedia('(max-width: 540px)')

  // toggle wallet when disconnected
  const toggleWalletModal = useWalletModalToggle()

  const { allOffsetActionsID,
          allUnclaimedActionsIDs,
          totalUnclaimedAmount,
      } = useGetActionList()
  
  const ARECTokenAddress = useARECTokenAddress()
  const currencyARECT = useCurrency(ARECTokenAddress)
  const balanceARECT = useCurrencyBalances(account ?? undefined, [currencyARECT??undefined])[0]
  const [userInput, setUserInput] = useState<string>('')

  const amountInput : CurrencyAmount | undefined = useMemo(() => {
      if( userInput === '') return undefined
      return tryParseAmount(userInput, currencyARECT ?? undefined)
    },
    [currencyARECT, userInput]
  )

  const totalUnclaimedAmountString = (new Fraction(totalUnclaimedAmount.toString(), JSBI.BigInt(1000000))).toFixed(3)

  const amountInputString = useMemo(()=>{
    if(!amountInput) return ''
    return amountInput.toSignificant(4)
  },[amountInput])

  const contractARECT = useArkreenRECTokenContract(true)
  const atMaxAmountInput = Boolean(balanceARECT && amountInput?.equalTo(balanceARECT))

  const handleMaxInput = useCallback(() => {
    balanceARECT && setUserInput(balanceARECT.toExact())
  }, [balanceARECT, setUserInput])

  const ifMoreInput = useMemo(()=>{
    if( !amountInput  || !balanceARECT) return false
    if(amountInput.greaterThan(balanceARECT)) return true
    return false
  },[balanceARECT, amountInput])

  const [{ showConfirm, txnToConfirm, attemptingTxn, errorMessage, txHash }, setARECTxnState] = useState<{
    showConfirm: boolean
    txnToConfirm: RECRequest | undefined
    attemptingTxn: boolean
    errorMessage: string | undefined
    txHash: string | undefined
  }>({
    showConfirm: false,
    txnToConfirm: undefined,
    attemptingTxn: false,
    errorMessage: undefined,
    txHash: undefined
  })

  const addTransaction = useTransactionAdder()

  async function handleClearErrorMessage() {
    setARECTxnState({ attemptingTxn, txnToConfirm, showConfirm, errorMessage: undefined, txHash })
  }

  const [nameCertOwner, setNameCertOwner] = useState<string> ('')
  const [beneficiary, setBeneficiary] = useState<string> (ZERO_ADDRESS)
  const [nameBeneficiary, setNameBeneficiary] = useState<string> ('')
  const [memoCertificate, setMemoCertificate] = useState<string> ('')

  const errorAddress = useMemo(()=>{
    const address = isAddress(beneficiary)
    const error = Boolean(!address && (beneficiary!==ZERO_ADDRESS) && (beneficiary!==''))
    return error
  },[beneficiary])
  
  const onChangeBeneficiary = useCallback( (beneficiary) => {
    setBeneficiary(beneficiary)
    }, [setBeneficiary] 
  )

  const handleConfirmDismiss = useCallback(() => {
    setARECTxnState({ attemptingTxn, txnToConfirm, showConfirm: false, errorMessage, txHash })
  }, [attemptingTxn, txnToConfirm, errorMessage, txHash])

  const [isWaitingWallet, setIsWaitingWallet] = useState<boolean>(false)

  async function handleOffsetAndMintCertificate() {
    if((!contractARECT) || (!amountInput) || amountInput?.equalTo('0') ) return
    const offsetValue = BigNumber.from(amountInput.raw.toString())

    setARECTxnState({ attemptingTxn: true, txnToConfirm, showConfirm, errorMessage: undefined, txHash: undefined })
    await contractARECT.estimateGas['offsetAndMintCertificate'](  
                          (beneficiary==='')? ZERO_ADDRESS :beneficiary, 
                          nameCertOwner, nameBeneficiary, memoCertificate,
                          offsetValue)  
        .then(async(estimatedGasLimit) => {
        await contractARECT.offsetAndMintCertificate(
                  (beneficiary==='')? ZERO_ADDRESS :beneficiary, 
                  nameCertOwner, nameBeneficiary, memoCertificate, 
                  offsetValue, { gasLimit: calculateGasMargin(estimatedGasLimit) })
        .then((response: TransactionResponse) => {
          addTransaction(response, {
            summary: `Offset ART token and mint retirement badge: ${amountInputString} ART`
          })
          setARECTxnState({ attemptingTxn: false, txnToConfirm, showConfirm, errorMessage: undefined, txHash: response.hash })
        })
        .catch((error: any) => {
            // if the user rejected the tx, pass this along
            if (error?.code === 4001) {
                throw new Error(`Offset and mint retirement badge: You denied transaction signature.`)
            } else {
              // otherwise, the error was unexpected and we need to convey that
              throw new Error(`Offset and mint retirement badge: ${error.message}`)
            }
        })
      })
      .catch((error: any) => {
        console.log("Error of Offset and Mint Badge tx:", error)
        const dataMsg = error?.data?.message ? ' Details: ' + error.data.message : ''
        setARECTxnState({attemptingTxn: false, txnToConfirm, showConfirm, errorMessage: error.message + dataMsg, txHash: undefined })
      })
  }
  
  async function handleCommitOffset() {
 
    if((!contractARECT) || (!amountInput) || amountInput?.equalTo('0') ) return
    const offsetValue = BigNumber.from(amountInput.raw.toString())

    setIsWaitingWallet(true)
    setARECTxnState({ attemptingTxn: true, txnToConfirm, showConfirm, errorMessage: undefined, txHash: undefined })
    await contractARECT.estimateGas['commitOffset'](offsetValue)  
        .then(async(estimatedGasLimit) => {
        await contractARECT.commitOffset(offsetValue, { gasLimit: calculateGasMargin(estimatedGasLimit) })
        .then((response: TransactionResponse) => {
          setIsWaitingWallet(false)
          addTransaction(response, {
            summary: `Offset ART token: ${amountInputString} ART`
          })
          setARECTxnState({ attemptingTxn: false, txnToConfirm, showConfirm, errorMessage: undefined, txHash: response.hash })
        })
        .catch((error: any) => {
            setIsWaitingWallet(false)
            // if the user rejected the tx, pass this along
            if (error?.code === 4001) {
                throw new Error(`Offset ART Token: You denied transaction signature.`)
            } else {
              // otherwise, the error was unexpected and we need to convey that
              throw new Error(`Offset ART Token: ${error.message}`)
            }
        })
      })
      .catch((error: any) => {
        setIsWaitingWallet(false)
        console.log("Error of Offset ART Token tx:", error)
        const dataMsg = error?.data?.message ? ' Details: ' + error.data.message : ''
        setARECTxnState({attemptingTxn: false, txnToConfirm, showConfirm, errorMessage: error.message + dataMsg, txHash: undefined })
      })
  }

  const attemptString = `You are burning and offsetting: ${amountInputString} ART, and minting a retirement badge NFT
                        for your carbon footprint offset.`

  function modalHeader() {
    return (
      <AutoColumn gap={'md'} style={{ marginTop: '20px' }}>
        <Container style={{boxShadow:"inset 0px 0px 8px #00913A", margin:'0rem 0rem'}}>
          <AutoColumn gap="4px" style={{padding: "0.75rem 1rem 0.75rem 1rem"}}>
              <RowBetween align="center" height='20px'>
                <Text fontWeight={500} fontSize={14} color={theme.text2}> Offset Amount of ART Token: </Text>
                <Text fontWeight={700} fontSize={14} color={theme.primary1}> {amountInput?.toSignificant(4)} ART</Text>
              </RowBetween>
          </AutoColumn>
        </Container>  
        <GetCertificateInfo certOwner = {nameCertOwner} beneficiary={beneficiary} 
                          nameBeneficiary={nameBeneficiary} memoCertificate={memoCertificate}
                          setCertOwner ={setNameCertOwner}  setBeneficiary = {onChangeBeneficiary} 
                          setNameBeneficiary = {setNameBeneficiary} setMemoCertificate ={setMemoCertificate} />
      </AutoColumn>
    )
  }

  function modalBottom() {
    return (
      <RowBetween>
        <ButtonError
          disabled={errorAddress}
          onClick={() => handleOffsetAndMintCertificate() }
          id="liquidize-button"
        >
          <Text fontSize={20} fontWeight={500}>
            Offset and Mint Badge
          </Text>
        </ButtonError>
    </RowBetween>
    )
  }

//  <AppBody style={{marginTop: below540 ?'30px': '60px'}} >

  return (
    <>
    <OffsetSolidyButton path={'Offset'} />
    <AppBody >
      <StyledPageCard bgColor={'red'}>
        <PageHeader header={'ART Offset'}>
          { chainId && ( <QuestionHelper bkgOff={true} text={'ART Offset'} info={<RetirementHelpInfo/>} /> ) } 
        </PageHeader>
        <Wrapper id="issuance-page">
          <ErrorPromptModal isOpen={!!errorMessage} errString={errorMessage} onDismiss={handleClearErrorMessage} />
          <TransactionConfirmationModal
            isOpen={showConfirm && !errorMessage}
            onDismiss={handleConfirmDismiss}
            attemptingTxn={attemptingTxn}
            hash={txHash ? txHash : ''}
            content={() => (
              <ConfirmationModalContentTitle
                title={'You will offset'}
                onDismiss={handleConfirmDismiss}
                topContent={modalHeader}
                bottomContent={modalBottom}
              />
            )}
            pendingText={attemptString}
            pendingTitle={'Offset and Mint Badge'}
            submittedTitle={'Offset and Mint Badge Submitted'}
          />

          <AutoColumn gap={'md'}>
            <Container style={{boxShadow:"inset 0px 0px 8px #00913A", margin:'0rem 0rem'}}>
              <AutoColumn gap="4px" style={{padding: "0.75rem 1rem 0.75rem 1rem"}}>
                  <RowBetween align="center" height='20px'>
                    <Text fontWeight={500} fontSize={14} color={theme.text2}> ART Token Amount: </Text>
                    { (balanceARECT === undefined) ? (
                        <Loader  />
                      ) : (
                        <Text fontWeight={700} fontSize={14} color={theme.primaryText1}> {balanceARECT.toSignificant(4)} ART</Text>
                      )
                    }
                  </RowBetween>
                  { (allOffsetActionsID !== undefined) && (
                    <RowBetween align="center" height='20px'>
                      <Text fontWeight={500} fontSize={14} color={theme.text2}> Number of Climate Actions: </Text>
                      <Text fontWeight={700} fontSize={14} color={theme.text2}> 
                        {allUnclaimedActionsIDs?.length.toString()} Actions
                      </Text>
                    </RowBetween>
                  )}
                  { (allOffsetActionsID !== undefined) && (
                    <RowBetween align="center" height='20px'>
                      <Text fontWeight={500} fontSize={14} color={theme.text2}> RE Amount of Climate Actions: </Text>
                      <Text fontWeight={700} fontSize={14} color={theme.text2}> {totalUnclaimedAmountString} KWH</Text>
                    </RowBetween>
                  )}
              </AutoColumn>
            </Container>  
            <CurrencyInputPanel
              label={'ART Amount to Offset:'}
              value={userInput}
              showMaxButton={!atMaxAmountInput}
              currency={currencyARECT}
              onUserInput={setUserInput}
              onMax={handleMaxInput}
              disableCurrencySelect = {true}
              id="sponsor-currency-input"
              customBalanceText = 'Balance: '
            />
          </AutoColumn>
          <BottomGrouping>
            {!account ? (
                <ButtonLight onClick={toggleWalletModal}>Connect Wallet</ButtonLight>
              ) : (userInput === '') ? (
                <ButtonError disabled={true} error={false}>
                  <Text fontSize={20} fontWeight={500}>  
                    Input Offset Amount
                  </Text>
                </ButtonError>
              ) : ((balanceARECT === undefined) || (balanceARECT.equalTo('0') )) ? (
                <ButtonError disabled={true} error={false}>
                  <Text fontSize={20} fontWeight={500}>  
                    No AREC Token to Offset
                  </Text>
                </ButtonError>
              ) : (
                <div>
                  <RowBetween marginTop="10px">
                    <ButtonError
                      disabled={isWaitingWallet || ifMoreInput || !amountInput}
                      onClick={() => handleCommitOffset() }
                      id="redeem-button"
                    >
                      <ButtonRow>
                        <div />
                        <Text fontSize={20} fontWeight={500}>
                          { !amountInput ? 'Input Offset Amount': ifMoreInput ? 'Offset Too More':  `Offset ART`}
                        </Text>
                        { below540 ? <div/> :
                          <MouseoverTooltip info ={HelpForOffset1} >
                            <HelpCircle size="20" color={theme.text5} style={{ marginLeft: '8px' }} />
                          </MouseoverTooltip>
                        }
                      </ButtonRow>
                    </ButtonError>
                  </RowBetween>

                  { amountInput && !ifMoreInput &&
                    <RowBetween marginTop="10px">
                      <ButtonError
                        disabled={isWaitingWallet || ifMoreInput}
                        onClick={() => {    
                          setARECTxnState({ attemptingTxn: false, 
                                            txnToConfirm, 
                                            showConfirm: true,
                                            errorMessage: undefined, 
                                            txHash: undefined })
                        }  
                      }
                        id="redeem-button"
                      >
                        <ButtonRow>
                          <div />
                          <Text fontSize={20} fontWeight={500}>
                            Offset and Mint Badge
                          </Text>
                          { below540 ? <div/> :
                            <MouseoverTooltip info ={HelpForOffset2} >
                              <HelpCircle size="20" color={theme.text5} style={{ marginLeft: '8px' }} />
                            </MouseoverTooltip>
                          }
                        </ButtonRow>
                      </ButtonError>
                    </RowBetween>
                  }
                </div>
              )
            }
          </BottomGrouping>
        </Wrapper>
      </StyledPageCard>
    </AppBody>
    </>
  )
}