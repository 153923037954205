import React, { Suspense } from 'react'
import { Route, Switch } from 'react-router-dom'
import styled from 'styled-components'
import GoogleAnalyticsReporter from '../components/analytics/GoogleAnalyticsReporter'
//import AddressClaimModal from '../components/claim/AddressClaimModal'
import Header from '../components/Header'
import Polling from '../components/Header/Polling'
//import URLWarning from '../components/Header/URLWarning'
import Popups from '../components/Popups'
//import SideNav from '../components/SideNav'
//import { useMedia } from 'react-use'
import Web3ReactManager from '../components/Web3ReactManager'
//import { ApplicationModal } from '../state/application/actions'
//import { useModalOpen, useToggleModal } from '../state/application/hooks'
import DarkModeQueryParamReader from '../theme/DarkModeQueryParamReader'

import Overview from './Overview'
import Profile from './Profile'
import GreenBTC from './GreenBTC'

import Test from './Test'

import Swap from './Swap'
import Issuance from './Issuance'
import Redeem from './Redeem'
import Liquidize from './Liquidize'

import MintCertificate from './Certificate'
import Offset from './Offset'
import Solidify from './Solidify'



import { RedirectPathToOverviewOnly, RedirectToSwap } from './Swap/redirects'
import Proof from './Proof'
//import { OpenClaimAddressModalAndRedirectToSwap, RedirectPathToSwapOnly, RedirectToSwap } from './Swap/redirects'

const AppWrapper = styled.div`
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  overflow-x: auto;
`

const HeaderWrapper = styled.div`
  ${({ theme }) => theme.flexRowNoWrap}
  width: 100%;
  justify-content: space-between;
`

const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 15px;
  align-items: center;
  flex: 1;
  overflow-y: auto;
  overflow-x: auto;
  z-index: 10;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 20px 16px 16px 16px;
  `};

  z-index: 1;
`

const Marginer = styled.div`
  margin-top: 5rem;
`

// <Route exact strict path="/sponsor" component={Sponsor} />
//{ !below1080 && (<SideNav />) }
//<URLWarning />

export default function App() {
//  const below1080 = useMedia('(max-width: 1080px)')
  return (
    <Suspense fallback={null}>
      <Route component={GoogleAnalyticsReporter} />
      <Route component={DarkModeQueryParamReader} />
      <AppWrapper>
        <HeaderWrapper>
          <Header />
        </HeaderWrapper>
        <BodyWrapper>
          <Popups />
          <Polling />
          <Web3ReactManager>
            <Switch>
              <Route exact strict path="/" component={GreenBTC} />                    
              <Route exact strict path="/Overview" component={Overview} />              
              <Route exact strict path="/Profile" component={Profile} />              
              <Route exact strict path="/swap" component={Swap} />
              <Route exact strict path="/swap/:outputCurrency" component={RedirectToSwap} />
              <Route exact strict path="/Issuance" component={Issuance} />
              <Route exact strict path="/Liquidize" component={Liquidize} />
              <Route exact strict path="/Redeem" component={Redeem} />   
              <Route exact strict path="/Offset" component={Offset} />  
              <Route exact strict path="/Solidify" component={Solidify} />  
              <Route exact strict path="/mintCert" component={MintCertificate} />  
              <Route exact strict path="/Test" component={Test} />   
              <Route exact strict path="/proof" component={Proof} />           
              <Route component={RedirectPathToOverviewOnly} />
            </Switch>
          </Web3ReactManager>
          <Marginer />
        </BodyWrapper>
      </AppWrapper>
    </Suspense>
  )
}
